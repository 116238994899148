/*
 * @Author: jld
 * @Date: 2023-03-13 11:24:11
 * @LastEditTime: 2023-07-14 11:47:01
 * @LastEditors: 王端慧 wangduanhui@pansoft.com
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\types\microConfig.ts
 */
export interface IMicroAppModel {
  name: string;
  title: string;
  id: string; // 子应用编号
  url: string;
  alive?: boolean;
  sync: boolean;
  preLoadApp: boolean;
  exec?: boolean;
  degrade?: boolean;
  attrs?: {};
  dispatchParentWindow?: boolean;
  expinfo: Record<string, any>;
  degradeAttrs?: {};
}
export interface IMicroAppModelType {
  name: string;
  title: string;
  id: string; // 子应用编号
  url: string;
  alive: string;
  sync: string;
  preLoadApp: string;
  preExec: string;
  degrade: string;
  attrs: {};
  dispatchParentWindow: string;
  expinfo: Record<string, any>;
  degradeAttrs: {};
}
// microAppLoading枚举类型
export enum microAppLoadingType {
  NONE = 'none',
  WUJIE = 'wujie',
  CUSTOM = 'custom',
}
