/*
 * @Author: jld
 * @Date: 2023-03-10 08:59:09
 * @LastEditTime: 2023-12-25 11:10:59
 * @LastEditors: LHY
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\locale\zhHK.ts
 */
export default {
  projectName: '普聯ERP門戶中心',
  // 登录
  scanCodeLogin: '掃碼登錄',
  OpenCodeLogIn: '打開普聯APP掃描二維碼登錄',
  DownloadPanSoftAPP: '下載普聯APP',
  target: '為眾多領域提供深入的特定方案',
  learnMore: '點擊詳細了解普聯ERP產品',
  viewDetails: '查看詳情',

  accountLogin: '賬號登錄',
  quickLogin: '快捷登錄',
  forgetPassword: '忘記密碼',
  login: '登錄',
  loginPrompt: '登錄視為您已同意第三方賬號綁定協議、服務條款、隱私政策',

  completeSecurityVerification: '請完成安全驗證',
  changeOne: '換一張',
  completePuzzle: '拖動滑塊完成拼圖',
  UnderLoad: '加載中...',
  getVerificationCode: '獲取驗證碼',
  afterSecond: '後重發',
  loginInfoTips: '用戶名/員工編號/手機號/郵箱',
  userNameTips: '請輸入賬戶名',
  passWordTips: '請輸入密碼',
  quickLoginLoginInfoTips: '請輸入手機號',
  quickLoginUserNameTips: '請輸入正確的手機號',
  quickLoginPassWordTips: '請輸入手機驗證碼',
  // 抽屉菜单相关
  myCollection: '我的收藏',
  allFunctions: '所有功能',
  recentVisit: '最近訪問',
  recentDocument: '最近單據',
  return: '返回',
  // 设置
  accountSetting: '賬號設置',
  systemSetting: '系統設置',
  exitAccount: '退出賬號',
  overallStyleSetting: '整體風格',
  ThemeColor: '主題色',
  systemLanguageSetting: '系統語言',
  defaultMenuSetting: '默認菜單',
  customizeHomePageKanBan: '自定義首頁看板',

  // 账号设置
  baseInformation: '基礎信息',
  userGroupInformation: '用戶組信息',
  role: '所屬角色',
  securitySet: '安全設置',

  // 清楚缓存
  clearCache: '清除緩存',
  clearingCacheSucceeded: '清除緩存成功',
  sureClearingCache: '確定清理緩存?',
  clearTip: '清除erp前端緩存,不影響系統正常使用',
  determine: '確定',
  cancel: '取消',
  // 自定义模板按钮
  newAdd: '新增',
  edit: '編輯',
  delete: '刪除',
  finish: '完成',
  save: '保存',
  selectTemplate: '請選擇預置模板布局',
  dashboardTips: '您可以拖動小部件進行添加、移動等自定義操作',
  enterKanBanName: '請輸入看板名稱',
  selectTemplateTips: '您可以選擇預置模版布局',
  dragWidget: '或自行拖動小部件進行添加、移動等自定義操作',
  searchTips: '在此輸入搜索內容',
  notFound: '沒有找到相關內容',
  stayTuned: '敬請期待',
  recentSearch: '最近搜索',
  guessSearch: '猜你想搜',
  clearHistory: '是否清空歷史記錄?',
  close: '關閉',
  historyCleared: '已清空歷史記錄',
  recentAccess: '最近訪問功能',
  enterKeywords: '請輸入關鍵詞',
  detailedSearchResults: '詳細搜索結果',
  detailedSearch: '詳細搜索',
  closeLeft: '關閉左側',
  closeRight: '關閉右側',
  closeOther: '關閉其他',
  closeAll: '關閉所有',
  pinTab:'固定頁簽',
  unPinTab:'解除固定頁簽',

  homePage: '首頁',
  selectSave: '自定義看板至少含有一個部件，請選擇後保存',
  // 忘记密码
  enterAccountNumber: '輸入賬號',
  resetPassword: '重置密碼',
  passwordsDifferent: '兩者密碼不一致,請重新輸入',
  enterSMSCode: '請輸入短信驗證碼',
  enterVerification: '請輸入驗證碼',
  confirmPassword: '請確認新密碼',
  resetSuccessfully: '重置成功！',
  nextStep: '下一步',
  returnLogin: '秒自動返回登錄',
  // 登录
  aboutUs: '關於我們',
  contactUs: '聯系我們',
  newsUpdate: '新聞動態',
  softwareProduct: '軟件產品',
  cloudService: '雲服務',
  domainSolution: '領域解決方案',
  typicalCase: '典型案例',
  serviceSupport: '服務支持',
  allRightsReserved: '版權所有 © 2001- 2023 普聯軟件股份有限公司 · 魯ICP備13012779號-5',

  // 个人设置
  personageTitle: '基本資訊',
  personageName: '姓名',
  headSculpture: '頭像',
  userName: '用戶名',
  employeeNumber: '員工編號',
  personageCertificateStatus: '認證狀態',
  personageSbmit: '提交認證',
  personageAccountID: '帳號ID',
  personageReimbursementLevel: '報帳級別',
  personageHRNumber: 'HR編號',
  personageRegistrationDate: '註冊時間',
  personageAddressCompany: '地區公司',
  personageAddressCompanyOther: '地區公司2',
  personageDepartment: '部門',
  personageMail: '郵箱',
  personageMailRules1: '無效的郵箱格式!',
  personageMailRules2: '請輸入您的郵箱!',
  personagePhone: '手機號',
  personagePhoneRules1: '手機號碼格式錯誤',
  personagePhoneRules2: '請輸入您的聯繫電話!',
  personageFax: '傳真號碼',
  personageFaxRules1: '傳真格式錯誤',
  personageFaxRules2: '請輸入您的傳真號碼!',
  personageSave: '保存',
  personageSaveSuccess: '保存成功',
  theOldPasswordIsTheSameAsTheNewPassword: '新密码与旧密码相同',
  theConfirmationIsDifferentFromTheNewPassword: '确认密码与新密码不同',
  personageUploadingFormat: '只能上傳JPG/PNG檔案!',
  personageUploadingSize: '影像必須小於2MB!',
  // 扩展信息
  extendedInformation: '擴展信息',
  sex: '性别',
  birthday: '生日',
  idType: '證件類型',
  idNumber: '證件號碼',
  nativePlace: '籍貫',
  address: '通訊地址',

  personageSettings: '帳號設定',
  personagePasswordSetting: '密碼設定',
  personageMessageSetting: '消息設定',
  personageOriginalPassword: '原密碼',
  personageNewsPassword: '新密碼',
  personagePleaseEnter: '請輸入',
  personageAffirm: '確認新密碼',
  personagePrompt: '是否跳轉到登入頁面',
  // 顶部导航更多
  TopMore: '更多',
  // 顶部导航全部
  TopAll: '全部',
  // 没有找到相关内容
  NoFound: '沒有找到相關內容',
  // 大事件组件
  MajorEventsTitle: '大事件',
  MajorEventsChange: '換一批',
  // 待办任务
  ToDoTasks: '待辦任務',
  // 最近使用
  RecentlyUsed: '最近使用',
  // 快捷应用
  QuickApplication: '快捷應用',
  QuickApplicationTitle: '添加快捷應用',
  SearchAppName: '搜索應用名稱',
  DragAndDropToSort: '拖拽進行排序',
  functions: '功能',
  noFunction: '暫無功能',
  // 通知提醒
  Notification: '通知提醒',
  // 全部已读
  AllRead: '全部已讀',
  allChoose: '全選',
  noChoose: '不選',
  noRead: '未讀',
  read: '已讀',
  mark: '標記爲',
  markUnread: '標記已讀',
  markRead: '標記未讀',
  // 帮助文档
  bills: '單據',
  message: '消息',
  searchKeywords: '搜索關鍵詞',
  HelpDocuments: '幫助文檔',
  HelpNovices: '新手幫助',
  HelpPractices: '最佳實踐',

  // 待办任务表格标题
  TableNo: '序號',
  TableState: '狀態',
  TableTaskNo: '任務編號',
  TableTaskName: '任務名稱',
  TableLastTime: '最晚完成時間',
  TablePerson: '發起人',
  TableOperate: '操作',

  mechanismSwitching: '机搆切换',
  messageCenter: '消息中心',
  workNotice: '工作通知',

  // 安全管理
  modalWarning: '警告',
  changePassword: '修改密碼',
  modalCancel: '取消',
  safetyManagement: '安全管理',
  passwordSetting: '密碼設置',
  passwordExpired: '密碼過期',
  lockedAndInvalid: '用戶鎖定和失效',
  forceChange: '管理員重置用戶密碼後，強制用戶更改密碼',
  cannotSame: '新舊密碼不能相同',
  specialCharacter: '密碼必須包含特殊字符',
  inclusionDigit: '密碼必須包含數字',
  mixedCase: '密碼必須包含混合大小寫',
  includeCapitalLetters: '密碼必須包含大寫字母',
  includeLowercaseLetters: '密碼必須包含小寫字母',
  includeUserName: '密碼不能包含用戶名',
  repeatingCharacter: '密碼不能有重複字符',
  minimumLength: '最短密碼長度',
  maximumLength: '最長密碼長度',
  expirationTime: '密碼過期時間',
  promptChange: '在到期前，以下天數內登錄時提示用戶登更改密碼',
  expiredForcedChange: '密碼過期後強制用戶更改密碼',
  userLock: '限定時間內未登錄用戶鎖定',
  userFailure: '限定時間內未登錄用戶失效',
  reachNumberLocking: '限定登錄失敗次數，達到次數鎖定',
  lockTime: '登錄失敗鎖定時間',
  passwordHaveExpired: '您的密碼已到期，需要修改密碼後使用',
  remainingDaysDue: `您的密碼還有 {restDays} 天到期，請及時更改密碼。`,
  containIllegalChar: '密碼中不能包含 ',
  exceptionTips: '異常提示',
  lastLoginInfo: '上次登錄信息',
  time: '時間',
  IP: 'IP地址',
  loginAddress: '地點',

  // 主题设置
  customThemeSettings: '自定義主題設置',
  themeNameRequire:'主題名稱不能為空',

  query:'查詢',
  resetting:'重置',
  processSubmission:'流程提交',
  processReturn:'流程退回',
  processReset:'流程重置',
  processRetrieval:'流程取回',
  myDocuments:'我的單據',
  schedule:'待辦',
  handled:'已辦',
  completed:'已辦結',
};
