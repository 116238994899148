/*
 * @Author: ZLL
 * @Date: 2023-02-06 20:31:10
 * @LastEditors: jld
 * @LastEditTime: 2023-03-10 15:41:22
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\components\FunctionSearch\index.tsx
 * @Description: 功能弹窗搜索组件
 */

import styles from './index.module.less';
import React, { useState } from 'react';
import { Input } from 'antd';
import IconFont from 'src/components/IconFont';
import type { InputProps } from 'antd/es/input';
import { useIntl } from 'react-intl';
interface FunctionSearchModel extends InputProps {
  stylesProps?: React.CSSProperties;
  inputRef?: any;
}
const FunctionSearch: React.FC<FunctionSearchModel> = (props) => {
  const { stylesProps, inputRef, ...otherProps } = props;

  const intl = useIntl();
  return (
    <div className={styles.searchInputBox} style={stylesProps}>
      <Input
        placeholder={intl.formatMessage({ id: 'enterKeywords' })}
        bordered={false}
        style={{ width: '100%', height: '40px' }}
        allowClear={true}
        prefix={<IconFont type="icon-daohanglan-sousuo" className={styles.searchIcon} />}
        suffix={<IconFont type="icon-yuyin" className={styles.voiceIcon} />}
        ref={inputRef}
        {...otherProps}
      />
    </div>
  );
};

export default FunctionSearch;
