/*
 * @Author: yar
 * @Date: 2023-02-06 20:08:55
 * @LastEditTime: 2023-08-17 17:27:34
 * @LastEditors: jld
 * @Description: 全局搜索
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\GlobalSearch\components\SearchPanel\index.tsx
 */
import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';
import SelectContain from '../SearchComponents';
import SearchList from '../SearchList';
import indexedDBkey from './utils/constant';
import { get, set } from 'idb-keyval';
import useErpNavigate from 'src/hooks/useNavigate';
import { ListModel, getGuessData } from './request';
import { getSearchMenu } from 'src/api';
import { MenuItem as IMenuItem } from 'src/types';
import { documentData } from 'src/constant/helpFileInfo';

const IndexPage: React.FC<any> = inject('TagsViewStore')(
  observer((props) => {
    let navigate = useErpNavigate();
    const [dataSource, setDataSource] = useState<any>([]);
    const [guessData, setGuessData] = useState<any>([]);
    const [selectValue, setSelectValue] = useState<string[]>([]); // 选中后的值
    const searchRef = useRef<any>(null);
    const [open, setOpen] = useState<boolean>(true);
    const openRef = useRef(true);
    const indexDBRef = useRef<any[]>([]);
    const [funcOptionData, setFuncOptionData] = useState<any[]>([]); // 存放搜索历史记录功能的数据
    const [optionData, setOptionData] = useState<any[]>([]);
    const [visiblePanel, setVisiblePanel] = useState<string>('history'); // 'recommend' | 'history'
    const [heightPx, setHeightPx] = useState<any>({ height: '373px' });

    // 推荐数据key
    const indexedDBRecommendDataKey = `${indexedDBkey.hisId}-recommend-data`;
    const menuFunction = useRef<IMenuItem[]>();
    const getMenuLeafFunction = async (keyword: string) => {
      let result = await getSearchMenu({ keyword: keyword, highlightFontColor: 'var(--ant-primary-color)' });
      return result;
    };
    // 模拟数据
    async function searchRequest(keyWord: string) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          let endFunDoc: IMenuItem[] = [];
          menuFunction.current = await getMenuLeafFunction(keyWord);
          menuFunction.current?.forEach((item: IMenuItem) => {
            endFunDoc.push(item);
          });
          const guessSearchData = await getGuessData(menuFunction.current, keyWord);
          let helpDoc = keyWord === '' ? [] : documentData.filter((item) => item.name.indexOf(keyWord) > -1);
          const dataSource = Array.from(Array(1), (item, index) => ({
            key: `${keyWord}${index + 1}`,
            code: `${keyWord}${index + 1}`,
            name: `${keyWord}${index + 1}`,
            children: {
              fuc: endFunDoc.filter((item) => item.isDisplay as any === 'true'),
              help: helpDoc,
              bill: [],
              message: [],
            },
          }));
          resolve({ dataSource, guessSearchData });
        }, 1000);
      });
    }
    // 数组去重
    const filterDBRecommendData = (dBArr: any) => {
      let filterArr: any[] = [];
      dBArr.forEach((item: any) => {
        if (!filterArr.includes(item)) {
          filterArr.push(item);
        }
      });
      return filterArr;
    };
    /**
     * 数组对象去重方法
     * @param arr
     * @returns
     */
    const deWeight = (arr: any) => {
      let obj: any = {};
      return arr.reduce((item: any, next: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-constant-binary-expression
        obj[next.key] ? '' : (obj[next.key] = true && item.push(next));
        return item;
      }, []);
    };

    const loadData = async () => {
      let dataList: any = [];
      if (searchRef.current?.lastSearchValue && searchRef.current?.lastSearchValue !== '') {
        indexDBRef.current = [searchRef.current?.lastSearchValue];
        get(`${indexedDBRecommendDataKey}`).then(async (res) => {
          if (res?.length) {
            dataList = filterDBRecommendData([...indexDBRef.current, ...res]);
            set(`${indexedDBRecommendDataKey}`, dataList);
          } else {
            dataList = [searchRef.current?.lastSearchValue];
            set(`${indexedDBRecommendDataKey}`, dataList);
          }
          setVisiblePanel('recommend');
          setHeightPx({ height: '614px' });
          setOptionData(dataList);
        });
      } else {
        get(`${indexedDBRecommendDataKey}`).then(async (res) => {
          if (res?.length) {
            dataList = filterDBRecommendData(res);
            set(`${indexedDBRecommendDataKey}`, dataList);
            setOptionData(dataList);
          }
        });
        setVisiblePanel('history');
      }
    };

    useEffect(() => {
      loadData();
    }, [searchRef.current?.lastSearchValue]);

    /*     const loadFuncData = (data?: any) => {
      debugger;
      let funcDataList: any = [];
      if (data) {
        indexDBFuncRef.current = [data];
        get(`${STORAGE_KEY.RECENT_USE}`).then(async (res) => {
          if (res?.length) {
            funcDataList = deWeight([...indexDBFuncRef.current, ...res]);
            set(`${STORAGE_KEY.RECENT_USE}`, funcDataList);
          } else {
            funcDataList = [data];
            set(`${STORAGE_KEY.RECENT_USE}`, funcDataList);
          }
          setFuncOptionData(funcDataList);
        });
      } else {
        debugger;
        get(`${STORAGE_KEY.RECENT_USE}`).then(async (res) => {
          if (res?.length) {
            funcDataList = deWeight([...indexDBFuncRef.current, ...res]);
            set(`${STORAGE_KEY.RECENT_USE}`, funcDataList);
            setFuncOptionData(funcDataList);
          }
        });
      }
    }; */
    /*     useEffect(() => {
      loadFuncData();
    }, []); */

    const listener = (e: any) => {
      if (e.code === 'Backspace') {
        // 回退操作
        let str = searchRef.current?.lastSearchValue?.toString();
        if (str.length <= 1 || str === '') {
          setVisiblePanel('history');
          setHeightPx({ height: '373px' });
        }
      }
    };
    useEffect(() => {
      document.addEventListener('keyup', listener);
      return () => {
        document.removeEventListener('keyup', listener);
      };
    }, []);

    const onSelect = (select: string[], selectItem: any[]) => {
      // console.log('调用----', '(选中的key值)', select, '(选中的对象)', selectItem);
    };
    const onSelected = (selected: any[], selectedItem: any[]) => {
      searchRef.current.handleSelect(selected, selectedItem);
    };
    const onChanged = (changed: any, type: string) => {
      searchRef.current.handleChange(changed);
      /*      if (type === 'fuc') {
        loadFuncData(changed);
      } */
    };
    /**
     * 向历史及面板传递的方法(设置options)
     * @param data
     */
    const setOptionsData = (data: Object[]) => {
      searchRef.current.setData(data);
    };
    /**
     * 向历史及面板传递的方法(提交选中项)
     * @param selected
     * @param selectedItem
     */
    const nominateSetSelected = (selected: any[], selectedItem: any[]) => {
      // 目前两个参数是一个数,后期真实接口会带过来相关信息
      setSelectValue(selected);
      onSelected(selected, selectedItem);
    };
    /**
     * 点击详细搜索进入详情搜索页
     */
    const clickToDetailPage = (key?: string, allInfo?: any) => {
      let keyWords = searchRef.current?.searchValue;
      // navigate(`/globalSearchFuc`, {}, { tagTitle: `搜索关键词:${keyWords}`, keyWords, tabKey: key, reloadTag: true });
      navigate('/searchDetails', { state: { search: keyWords, tabKey: key } })
    };

    const handleClear = () => {
      if (!openRef.current) {
        setVisiblePanel('history');
        setHeightPx({ height: '373px' });
      }
    };

    useEffect(() => {
      setVisiblePanel(!selectValue || selectValue.length === 0 ? 'history' : 'recommend');
    }, [selectValue]);
    return (
      <div className={styles.search_container}>
        <SelectContain
          fetchOptions={searchRequest}
          setSourceData={setDataSource}
          onRef={searchRef}
          onDropdownVisibleChange={(open: boolean) => {
            setOpen(open);
            openRef.current = open;
          }}
          onSelect={onSelect}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
          onClear={handleClear}
          setGuessData={setGuessData}
          searchPanel={
            <SearchList
              dataSource={dataSource}
              guessData={guessData}
              onSelect={onSelected}
              selectValue={selectValue}
              onChange={onChanged}
              keyWord={searchRef.current?.lastSearchValue}
              nominateSetSelected={nominateSetSelected}
              historyWordData={optionData}
              setOptionsData={setOptionsData}
              toDetailPage={clickToDetailPage}
              visiblePanel={visiblePanel}
              style={heightPx}
              historyFuncData={funcOptionData}
              searchValue={searchRef.current?.searchValue}
            />
          }
        />
      </div>
    );
  }),
);

export default IndexPage;
