/*
 * @Author: ZLL
 * @Date: 2023-02-07 10:34:00
 * @LastEditors: ZY
 * @LastEditTime: 2023-02-27 20:57:16
 * @FilePath: /erp-finance-biz-portal-react-start/src/components/IconFont/index.tsx
 * @Description: IconFont
 */

import { createFromIconfontCN } from '@ant-design/icons';
import { URL_CONSTANT } from 'src/constant';

const IconFont = createFromIconfontCN({
  scriptUrl: [URL_CONSTANT.ICON_FONT, require('./portal.js')],
});

export default IconFont;
