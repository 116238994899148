/*
 * @Author: ZLL
 * @Date: 2023-02-06 20:31:10
 * @LastEditors: ZLL
 * @LastEditTime: 2023-02-08 11:03:51
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\DashBoard\components\FunctionDrawer\components\FunctionMenu\index.tsx
 * @Description: 左侧菜单组件
 */

import styles from './index.module.less';
import React from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd/es/menu';

const FunctionMenu: React.FC<MenuProps> = (props) => {
  return <Menu className={styles.functionMenuBox} {...props} />;
};

export default FunctionMenu;
