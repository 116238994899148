/*
 * @Author: LHY
 * @Date: 2023-09-19 17:37:08
 * @LastEditTime: 2024-01-04 21:54:47
 * @LastEditors: YL
 * @Description:
 * @FilePath: /portal-react-start/src/api/accountInfo.ts
 */
import request from '../utils/request';
import {
  IUpdateUserInfoParams,
  IUserInfo,
  IUserInfoParams,
  IUpdateUserPassword,
  LoginUserInfo,
} from './types/accountInfo';

// 获取用户信息
export const getUserInfo = (data: IUserInfoParams) => {
  return request<IUserInfo>({
    url: '/uc-core/user/select',
    method: 'post',
    data,
  });
};
// 修改用户信息
export const updateUserInfo = (data: IUpdateUserInfoParams) => {
  return request({
    url: '/uc-core/user/save',
    method: 'post',
    data,
  });
};

// 修改用户密码
export const updateUserPassword = (data: IUpdateUserPassword) => {
  return request({
    url: '/erp-iam/erp/security/password/update',
    method: 'post',
    data,
  });
};

// 获取用户信息
export const getLoginUserInfo = () => {
  return request<LoginUserInfo>({
    url: '/token/getLoginUserInfo',
    method: 'post',
    data: null,
  });
};
