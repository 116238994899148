/*
 * @Author: atwLee
 * @Date: 2023-05-24 14:23:36
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @LastEditTime: 2024-03-18 17:54:56
 * @Description:
 * @FilePath: /portal-react-start/src/layout/components/TagsView/components/Taglist/WuJieContainer.tsx
 */
import WujieReact from 'wujie-react';
import { NotifyChildApplicationsType } from './type';
import styles from './index.module.less';
import { useEffect, useState, useRef } from 'react';
import { microAppLoadingType } from 'src/api/types/microConfig';
import Loading from 'src/components/Loading';
import { EventTargetPlugin } from 'wujie-polyfill';
import stores from 'src/stores';
import { ITagProps } from '@/stores/components/TagsViewStore';
import type { WuJieAppsType } from '@/hooks/useWJAppNames';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { MESSAGE_KEY } from 'src/constant';
import AppState from 'src/stores/components/AppState';
import { themeModelList, compatibleOspThemeModeList, themeTokenModel } from 'src/pages/CustomThemeStyle/model';
import { getAllCSSVariableNames, getElementCSSVariables, loadStyleString } from 'src/utils';
const { destroyApp, bus } = WujieReact;

interface WuJieComProps {
  curAppName?: string;
  menuItem?: ITagProps;
  apps: WuJieAppsType[];
  collectMenuFixed?: boolean;
}

export default (props: WuJieComProps) => {
  const { curAppName, menuItem, apps, collectMenuFixed } = props;
  const currentTabKey = `${curAppName?.toUpperCase()}_TAB_KEY`; // 当前通信的key
  // 自定义loading是否加载
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const location = useLocation();
  // 菜单加载状态延时对象
  const menuLoadingStatusTimeOutRef = useRef<any>(undefined);

  // 菜单切换时，给子应用通信（保活模式，name相同则复用一个子应用实例，改变url无效，必须采用通信的方式告知路由变化）
  const handleWuJieEmit = (path: string) => {
    console.log(`WUJIE-ACTION-给==${currentTabKey}==应用通信，action是open，path是----${path}`);
    WujieReact.bus.$emit(currentTabKey, {
      action: 'open',
      path,
    } satisfies NotifyChildApplicationsType);
  };

  const currentApp = apps.find((i) => i.name === curAppName);
  const { url, expinfo } = currentApp?.props ?? {};

  useEffect(() => {
    if (!curAppName || !menuItem) return;
    let microAppPath: string = menuItem?.config?.url?.replace(`${curAppName}`, url ?? '');

    if (microAppPath && url) {
      // 拼接菜单名字
      if (microAppPath.search(/caption/gi) === -1 && menuItem?.name && curAppName === 'web') {
        if (microAppPath.indexOf('?') !== -1) {
          microAppPath += `&caption=${menuItem?.name}`;
        } else {
          microAppPath += `?caption=${menuItem?.name}`;
        }
      }

      // 如果是预执行，并且没有初始化完成，那么就需要销毁重新加载
      console.log('菜单切换，看看预执行的应用的状态--', stores.AppState.execAppLoadedState);
      if (stores.AppState.execAppLoadedState[curAppName] === false) {
        console.log('预执行没有结束，需要销毁并重新加载');
        destroyApp(curAppName);
        stores.AppState.setExecAppLoadedState(curAppName, true);
      } else {
        handleWuJieEmit(microAppPath.replace(`${url}`, '').replace('#/', ''));
      }
    }
  }, [menuItem?.id, curAppName]);

  const LoginInfo = {
    IsLogin: stores.UserStore.isLogin,
    UserName: stores.UserStore.userName,
    UserCaption: stores.UserStore.userCaption,
  };

  useEffect(() => {
    bus.$on(MESSAGE_KEY.PAGE_LOADED, () => {
      setCustomLoading(false);
      stores.AppState.setMenuLoadingStatus(false);
    });
  }, []);
  useEffect(() => {
    if (customLoading) {
      stores.AppState.setMenuLoadingStatus(true);
      if (menuLoadingStatusTimeOutRef.current) {
        clearTimeout(menuLoadingStatusTimeOutRef.current);
      }
      menuLoadingStatusTimeOutRef.current = setTimeout(() => {
        clearTimeout(menuLoadingStatusTimeOutRef.current);
        // 获取当前MenuLoadingStatus
        if (stores.AppState.menuLoadingStatus) {
          stores.AppState.setMenuLoadingStatus(false);
        }
      }, 5000);
    }
  }, [customLoading]);

  const lifecycles = {
    beforeLoad: (appWindow: Window) => {
      console.log(`${appWindow.__WUJIE.id} beforeLoad 生命周期`);
      const { microLoading } = expinfo ?? {};
      if (microLoading === microAppLoadingType.CUSTOM) {
        stores.AppState.setMenuLoadingStatus(true);
        setCustomLoading(true);
      }
    },
    beforeMount: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} beforeMount 生命周期`),
    afterMount: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} afterMount 生命周期`),
    beforeUnmount: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} beforeUnmount 生命周期`),
    afterUnmount: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} afterUnmount 生命周期`),
    activated: (appWindow: Window) => {
      console.log(`${appWindow.__WUJIE.id} activated 生命周期`);
      interface themeTokenProp {
        [key: string]: string | number;
      }
      let cssVars = getAllCSSVariableNames();
      let cssVarsObj = getElementCSSVariables(cssVars, document.documentElement);
      const themeToken: themeTokenProp = {
        ...cssVarsObj,
        ...AppState.themeToken,
      };
      const el = appWindow.document.documentElement;
      let cssText = '';
      Object.entries(themeToken).forEach(([token, value]) => {
        const cssVar = themeModelList.find((i) => i.key === token)?.CSSVariable ?? token;
        const cssValue = typeof value === 'number' ? `${value}px` : value;
        if (cssValue && cssValue !== 'none') {
          el.style.setProperty(cssVar, cssValue);
          cssText += `${cssVar}:${cssValue};`;

          // 是否存在兼容OSP
          const compatibleOspToken = compatibleOspThemeModeList.filter((filterItem: themeTokenModel) => {
            return filterItem.key === token;
          });
          if (compatibleOspToken.length > 0) {
            el.style.setProperty(`${compatibleOspToken[0].CSSVariable}`, cssValue);
            cssText += `${compatibleOspToken[0].CSSVariable}:${cssValue};`;
          }
        }
      });
      loadStyleString(
        el,
        `:host{${cssText}}}`,
      );
    },
    deactivated: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} deactivated 生命周期`),
    loadError: (url: string, e: Error) => console.log(`${url} 加载失败`, e),
  };
  // feat: 增加当前菜单变量定义
  window.__CURRENT_MENU__ = menuItem;

  return (
    <div
      className={classNames([
        styles.portalTagsWujieContainer,
        {
          [styles.portalTagsWujieContainerHidden]: !location.pathname.includes('microApp'),
        },
        {
          [styles.portalTagsWujieContainerLayout]: collectMenuFixed,
        },
      ])}
    >
      <Loading spinning={customLoading}>
        {apps.map((i) => {
          const { name, props } = i;
          const { microAppPath, alive, sync, attrs, degrade } = props ?? {};
          console.log('microAppPath', microAppPath);
          const itemTabKey = `${i.name.toUpperCase()}_TAB_KEY`;
          return (
            <div
              key={name}
              className={styles.portalTagsWujie}
              style={name === curAppName ? { display: 'block' } : { display: 'none' }}
            >
              <WujieReact
                name={name}
                width="100%"
                height="100%"
                loading={
                  expinfo?.microLoading === microAppLoadingType.WUJIE ? null : (document.createElement('span') as any)
                }
                url={microAppPath}
                alive={true}
                sync={sync}
                attrs={attrs}
                degrade={degrade}
                props={{
                  operateTabKey: itemTabKey,
                  LoginInfo: LoginInfo,
                  tabParams: menuItem?.tabParams,
                  isInnerTab: true,
                  stores: stores,
                  lang: stores.InternationalizationStore.lang,
                }}
                {...lifecycles}
                plugins={[EventTargetPlugin()]}
              />
            </div>
          );
        })}
      </Loading>
    </div>
  );
};
