/*
 * @Author: sjt
 * @Date: 2023年2月13日14:02:49
 * @LastEditTime: 2024-03-19 20:46:19
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @Description:自定义模板吧编辑页
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\CustomDashboard\edit.tsx
 */
import React, { ReactNode, useEffect, useState } from 'react';
import { Layout, Button, Select, Input, Collapse, Dropdown, message } from 'antd';
import type { MenuProps } from 'antd';
import RGL, { WidthProvider, Layout as Layouts } from 'react-grid-layout';
import GridItem from './components/gridItem';
import IconFont from '../../components/IconFont';
import DashboardIconFont from '../../components/DashboardIconFont';
import empty_img from '../../asset/dashboard/dashboard_empty.png';
import { SizeMe } from 'react-sizeme';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styles from './index.module.less';
import { useNavigate, useParams } from 'react-router';
import { inject, observer } from 'mobx-react';
import { IChildrenComponent, IComponentGroup, IDashboard } from 'src/api/types/dashboard';
import { getDashboard, getGroupList, getTemplateList, saveDashboard, updateDashboard } from 'src/api';
import { URL_CONSTANT } from 'src/constant';
import { FormattedMessage, useIntl } from 'react-intl';

interface IGlyph {
  icon_id: string;
  name: string;
  font_class: string;
  unicode: string;
  unicode_decimal: number;
}

interface IconItems {
  key: string;
  icon: ReactNode;
}

const { Header, Content, Sider } = Layout;
const { Option } = Select;
const { Panel } = Collapse;
const ReactGridLayout = WidthProvider(RGL);
let iconItems: IconItems[] = [];
const Custom: React.FC<{ [key: string]: any }> = inject('DashboardStore')(
  observer((props) => {
    const {
      DashboardStore: { dashboardList, setDashboardList },
    } = props;
    const navigate = useNavigate();

    let { id } = useParams();

    const defaultProps = {
      className: 'layout',
      cols: 10,
      rowHeight: 10,
    };

    // 看板名称
    const [editorName, setEditorName] = useState<string>('');

    // 左侧组件列表
    const [list, setList] = useState<IComponentGroup[] | any>();

    // 布局
    const [layout, setLayout] = useState<Array<Layouts>>([]);

    // 图标
    const [icon, setIcon] = useState<ReactNode | any>(<IconFont type="icon-jiashicang" />);

    // 预置模板
    const [templateList, setTemplateList] = useState<IDashboard[] | any>();

    const intl = useIntl();
    // 获取看板数据，存stores
    const getDashboardOperate = (isSaveUpdate?: boolean) => {
      getDashboard().then((res) => {
        // 最后一个是新增的id
        id = id === 'new' ? res[res.length - 1].id : id;
        setDashboardList(res);
        if (isSaveUpdate) navigate('/CustomDashboard', { state: { id: id } });
      });
    };

    const getIconItems = async () => {
      const jsonResponse = await fetch(URL_CONSTANT.DASHBOARD_ICON_FONT_JSON);
      const jsonData = await jsonResponse.json();
      iconItems = jsonData.glyphs.map((item: IGlyph, index: string) => {
        return {
          key: index,
          icon: <DashboardIconFont type={jsonData.css_prefix_text + item.font_class} />,
        };
      });
    };

    useEffect(() => {
      if (dashboardList.length === 0) {
        getDashboardOperate();
      }
      getIconItems();
    }, []);
    useEffect(() => {
      getGroupList().then((res) => {
        setList(res);
      });
      if (id && id !== 'new') {
        let editorObj: IDashboard = dashboardList?.find((item: IDashboard) => item.id === id);
        setEditorName(editorObj?.name);
        if (editorObj) onSelectTemplate('', editorObj);
      } else {
        getTemplateList().then((res) => {
          setTemplateList(res);
        });
      }
    }, [dashboardList]);

    // 修改看板名称
    const onEditorName = (e: any) => {
      setEditorName(e.target.value);
    };

    // 选择模板
    const onSelectTemplate = (id: string, dashboardItem?: IDashboard) => {
      let newArr: Layouts[] = [];
      const templateItem: IDashboard = dashboardItem ?? templateList.find((item: IDashboard) => item.id === id);
      templateItem?.children.forEach((item: IChildrenComponent) => {
        newArr.push({
          i: item.id,
          x: item.x!,
          y: item.y!, // puts it at the bottom
          w: item.width,
          h: item.height,
        });
      });
      setLayout(newArr);
    };

    // 添加组件到右边看板
    const addComponent = (item: IChildrenComponent) => {
      setLayout([
        ...layout,
        {
          i: item.id,
          x: 0,
          y: Infinity, // puts it at the bottom
          w: item.width,
          h: item.height,
        },
      ]);
    };

    // 布局改变
    const onLayoutChange = (layout: Layouts[]) => {
      setLayout(layout);
    };

    // 取消
    const onCancel = () => {
      id = '';
      navigate('/CustomDashboard', { state: { id: id } });
    };

    // 保存
    const onSave = () => {
      if (editorName === '') {
        message.error({
          content: intl.formatMessage({ id: 'enterKanBanName' }),
        });
        return;
      }
      if (layout.length === 0) {
        message.error({
          content: intl.formatMessage({ id: 'selectSave' }),
        });
        return;
      }

      let dashboardParams = {
        boardName: editorName,
        boardId: id,
        boardIcon: icon?.props?.type,
        portletContent: layout.map((_layout: Layouts) => {
          return {
            id: _layout.i,
            width: _layout.w,
            x: _layout.x,
            height: _layout.h,
            y: _layout.y,
          };
        }),
      };

      if (id === 'new') {
        saveDashboard(dashboardParams).then((res) => {
          if (res) {
            getDashboardOperate(true);
          }
        });
      } else {
        updateDashboard(dashboardParams).then((res) => {
          if (res) {
            getDashboardOperate(true);
          }
        });
      }
    };

    /**
     * 根据布局渲染dom
     */
    const generateDOM = (el: Layouts) => {
      const item = list?.map((elementItem: IComponentGroup) => {
        return elementItem?.components?.filter((child: IChildrenComponent) => child.id === el.i)[0];
      });

      return (
        <div key={el.i} data-grid={el}>
          <GridItem deleteItem={onDeleteItem} item={item[0]} type="edit" />
        </div>
      );
    };

    // 从右侧看板删除部件
    const onDeleteItem = (item: IChildrenComponent) => {
      setLayout(layout.filter((com) => com.i !== item.id));
    };

    // 选择图标
    const onIconSelect: MenuProps['onClick'] = ({ key }) => {
      const selectIcon: any = iconItems.filter((item) => String(item?.key) === key)[0];
      if (selectIcon) {
        setIcon(selectIcon.icon);
      }
    };
    return (
      <Layout className={styles.customEditLayout}>
        <Header className={styles.customEditHeader}>
          <div className={styles.headLeft}>
            <Dropdown menu={{ items: iconItems, onClick: onIconSelect }}>
              <div className={styles.iconSelect}>
                {icon}
                <IconFont className={styles.iconDown} type="icon-yeqianlan-xiala" />
              </div>
            </Dropdown>
            <Input
              placeholder={intl.formatMessage({ id: 'enterKanBanName' })}
              className={styles.title}
              value={editorName}
              onChange={(e) => onEditorName(e)}
              showCount
              maxLength={8}
            />
          </div>
          <h3> {intl.formatMessage({ id: 'dashboardTips' })}</h3>
          <div className={styles.headRight}>
            <Button type="primary" onClick={onSave}>
              <FormattedMessage id="save" />
            </Button>
            <Button onClick={onCancel}>
              <FormattedMessage id="cancel" />{' '}
            </Button>
          </div>
        </Header>
        <Layout>
          <Sider width={240} className={styles.customEditSider}>
            {id === 'new' ? (
              <div className={styles.customEditSiderSelect}>
                <Select
                  placeholder={intl.formatMessage({ id: 'selectTemplate' })}
                  onChange={(id: string) => onSelectTemplate(id)}
                  popupClassName={styles.selectStyles}
                >
                  {templateList?.map((item: IDashboard) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            ) : null}
            <Collapse defaultActiveKey={['1']} expandIconPosition="end" className={styles.siderCollapse} ghost={true}>
              {list?.map((item: IComponentGroup) => {
                return (
                  <Panel header={item.groupName} key={item.groupId}>
                    <ul>
                      {item?.components?.map((itemItem, i) => {
                        let temp = layout.find((com) => com.i === itemItem.id);
                        itemItem.selected = temp ? true : false;
                        return (
                          <li
                            className={itemItem.selected ? styles.selected : styles.unselect}
                            key={i}
                            onClick={() => {
                              if (!itemItem?.selected) {
                                addComponent(itemItem);
                              } else {
                                setLayout(layout.filter((com) => com.i !== itemItem.id));
                              }
                            }}
                          >
                            <span>{itemItem.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </Panel>
                );
              })}
            </Collapse>
          </Sider>

          <Content>
            <div className={styles.customEditContent}>
              {list && layout && layout.length > 0 ? (
                <SizeMe noPlaceholder>
                  {({ size }) => (
                    <ReactGridLayout
                      {...defaultProps}
                      layout={layout}
                      width={size.width as number}
                      onLayoutChange={(layout: Layouts[]) => onLayoutChange(layout)}
                      compactType="vertical"
                      margin={[10, 10]}
                      draggableCancel=".delete"
                    >
                      {layout?.map((el, i) => generateDOM(el))}
                    </ReactGridLayout>
                  )}
                </SizeMe>
              ) : (
                <div className={styles.contentEmpty}>
                  <div className={styles.contentEmptyWapper}>
                    <img src={empty_img} alt="" />
                    <p>
                      <FormattedMessage id="selectTemplateTips" />
                      <br />
                      <FormattedMessage id="dragWidget" />
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }),
);

export default Custom;
