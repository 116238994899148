/*
 * @Author: yar
 * @Date: 2023-02-08 20:24:57
 * @LastEditTime: 2023-03-17 14:20:20
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\Navigation\index.tsx
 */
import React, { useEffect, useRef, useState } from 'react';
import { INavigationProps } from './type';
import IconFont from 'src/components/IconFont';
import { getStyle } from 'src/utils';
import styles from './index.module.less';
import { inject, observer } from 'mobx-react';
import { Dropdown, MenuProps } from 'antd';
import { isEmpty } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
// 国际化
import { FormattedMessage } from 'react-intl';
const IndexPage: React.FC<INavigationProps> = inject(
  'AppState',
  'DrawerStore',
)(
  observer((props) => {
    const navigation = useNavigate();
    const {
      AppState: { topMenuList, headerNavActive, setHeaderNavActive, themStyle },
      DrawerStore: { setDrawerOpen, drawerOpenStatus },
    } = props;
    const [navList, setNavList] = useState<any>([]);
    const [currShowList, setCurrShowList] = useState<any>([]);
    const [moreList, setMoreList] = useState([]);
    const navListRef = useRef<any>(null);
    useEffect(() => {
      setNavList(topMenuList);
    }, [topMenuList]);

    // 观察导航宽度
    useEffect(() => {
      const targetNode = navListRef?.current;
      // 新建observer对象
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          let target: any = entry.target as HTMLElement;
          // 导航总宽度
          let navW = target.offsetWidth;
          let overStartIndex: any = null;
          // 更多的宽度
          let moreWidth = 71;
          // menuItem的总宽度
          let childWidth = 0;
          for (const childNodes of target.childNodes) {
            let childMarginRightWidth = Number(getStyle(childNodes, 'marginRight').replace('px', ''));
            childWidth += childNodes.offsetWidth + childMarginRightWidth;
          }
          // 【1】判断是否超过导航总宽度
          if (navW < childWidth) {
            // 总宽度 - 更多宽度 重新计算
            navW = navW - moreWidth;
            childWidth = 0;
            for (const [childIndex, childNodes] of target.childNodes.entries()) {
              let childMarginRightWidth = Number(getStyle(childNodes, 'marginRight').replace('px', ''));
              childWidth += childNodes.offsetWidth + childMarginRightWidth;
              if (childWidth > navW) {
                overStartIndex = childIndex;
                break;
              }
            }
            setCurrShowList(navList.slice(0, overStartIndex));
            setMoreList(navList.slice(overStartIndex, navList.length));
          } else {
            setCurrShowList(navList);
            setMoreList([]);
          }
        }
      });
      resizeObserver.observe(targetNode);

      return () => {
        // 可停止观察
        resizeObserver.disconnect();
      };
    }, [navList]);

    const items: MenuProps['items'] = moreList?.map((item: any) => {
      return {
        key: item.name,
        label: (
          <div
            className={`${styles.portalHeaderWrapNavMoreListItem} ${
              item.name === headerNavActive?.name ? styles.portalHeaderWrapNavActiveNav : ''
            }`}
            key={item.name}
            onClick={() => {
              setHeaderNavActive(item);
              navigation('/dashboard');
            }}
          >
            <div className={styles.portalHeaderWrapNavIcon}>
              <IconFont type={item.icon ?? ' '} />
            </div>
            <div className={styles.portalHeaderWrapNavText}>{item.name}</div>
          </div>
        ),
      };
    });
    return (
      <div
        className={
          themStyle === 'overallStyleBlackTab'
            ? `${styles.portalHeaderWrapNavArea}`
            : `${styles.portalHeaderWrapNavArea} ${styles.portalHeaderWrapNavAreaOtherThem}`
        }
      >
        <ul className={`${styles.portalHeaderWrapNavList} ${styles.portalHeaderWrapNavListHide}`} ref={navListRef}>
          {navList.map((item: any) => {
            return (
              <li
                className={`${styles.portalHeaderWrapNavListItem} ${
                  item.id === headerNavActive?.id ? styles.portalHeaderWrapNavActiveNav : ''
                }`}
                key={item.id + 'hide'}
                onClick={() => {
                  setHeaderNavActive(item);
                  navigation('/dashboard');
                }}
              >
                <div className={styles.portalHeaderWrapNavIcon}>
                  <IconFont type={item.icon ?? ' '} />
                </div>
                <div className={styles.portalHeaderWrapNavText}>{item.name}</div>
              </li>
            );
          })}
        </ul>

        <ul className={styles.portalHeaderWrapNavList}>
          {currShowList.map((item: any) => {
            return (
              <li
                className={`${styles.portalHeaderWrapNavListItem} ${
                  item.id === headerNavActive?.id ? styles.portalHeaderWrapNavActiveNav : ''
                }`}
                key={item.id}
                onClick={() => {
                  setHeaderNavActive(item);
                  if (drawerOpenStatus === 'open') {
                    setDrawerOpen(false);
                  }
                  navigation('/dashboard');
                }}
              >
                <div className={styles.portalHeaderWrapNavIcon}>
                  <IconFont
                    type={item.icon ?? ' '}
                    className={item.id === headerNavActive?.id ? styles.dropShadow : ''}
                  />
                </div>
                <div className={styles.portalHeaderWrapNavText}>{item.name}</div>
              </li>
            );
          })}

          {!isEmpty(moreList) && (
            <li className={`${styles.portalHeaderMoreNavList}`}>
              <Dropdown menu={{ items }} overlayClassName={styles.portalHeaderWrapNavMoreDropdown} placement="bottom">
                <div className={styles.portalHeaderWrapNavListItem}>
                  <div className={styles.portalHeaderWrapNavIcon}>
                    <IconFont type="icon-gengduo" />
                  </div>
                  <div className={styles.portalHeaderWrapNavText}>
                    <FormattedMessage id="TopMore" />
                  </div>
                </div>
              </Dropdown>
            </li>
          )}
        </ul>
      </div>
    );
  }),
);
export default IndexPage;
