import React, {useEffect, useMemo, useState, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import {Dropdown, MenuProps, Tabs, message, Modal} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {useLocation} from 'react-router-dom';
import {ITagItemProps, ITagsListProps, NotifyChildApplicationsType} from './type';
import IconFont from 'src/components/IconFont';
import classnames from 'classnames';
import styles from './index.module.less';
import pathOpenTab from 'src/utils/pathOpenTab';
import { getIframeContainEle } from 'src/utils/dom';
import { isSameOriginFun } from 'src/utils/window';
import useErpNavigate from 'src/hooks/useNavigate';
import {FormattedMessage, useIntl} from 'react-intl';
import WujieReact from 'wujie-react';
import WuJie from './WuJieContainer';
import useWuJieAppNames from 'src/hooks/useWJAppNames';
import TagContent from '../TagContent';
import {MenuItem} from '@/types';
import {CLOSE_TYPE} from 'src/constant/closeTab';
import {getPathType} from 'src/routes/layoutRouteMap';
import type {DragEndEvent, DragStartEvent} from '@dnd-kit/core';
import {DndContext, PointerSensor, useSensor} from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {ReactComponent as Pin} from '../../../../../asset/pin.svg'
import { LOAD_TYPE } from 'src/constant/menu';


interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
    'data-node-key': string;
    activeDragId?: string
}

const DraggableTabNode = ({className, ...props}: DraggableTabPaneProps) => {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
        id: props['data-node-key'],
        disabled: props['data-node-key'] === '/dashboard'
    });

    const style: React.CSSProperties = {
        ...props.style,
        ...(props['activeDragId'] === props['data-node-key'] ? {
            zIndex: 1
        } : null),
        transform: CSS.Transform.toString(transform && {...transform, scaleX: 1}),
        transition:'none'
    };

    return React.cloneElement(props.children as React.ReactElement, {
        ref: setNodeRef,
        style,
        ...attributes,
        ...listeners,
    });
};

const TagsList: React.FC<ITagsListProps> = inject(
    'AppState',
    'TagsViewStore',
    'UserStore',
    'DrawerStore',
    'RecentUseStore',
)(
    observer((props) => {
        const location = useLocation();
        const navigate = useErpNavigate();
        // 【1】props
        const {
            TagsViewStore: {tagList, deleteTag, getTagList, activePath, setActivePath, setTagList, updateTabPinStatus},
            DrawerStore: {collectMenuFixed},
            AppState: {leftMenuList, menuLoadingStatus},
        } = props;
        const intl = useIntl();

        // 【2】useState
        const [currentPath, setCurrentPath] = useState('');
        // 当前标签的index
        const [activeIndex, setActiveIndex] = useState(0);

        const [isModalOpen, setIsModalOpen] = useState(false);
        const currentCloseType = useRef<string>('');
        // 标签容器Ref
        const tagListContainer = useRef<any>();
        const [activeDragTabId, setActiveDragTabId] = useState<any>('');
        // 当前右键操作的页签
        const [curentContextStateTag, setCurentContextStateTag] = useState<any>()
        const curentContextTag = useRef<any>();
        curentContextTag.current = curentContextStateTag
        const tagsRightOperationContain = useRef<any>();
        // 右键菜单容器Ref
        const contextMenuContainer = useRef<any>();
        const [left, setLeft] = useState<number>(0);
        const [top, setTop] = useState<number>(0);
        const [menuVisible, setMenuVisible] = useState<boolean>(false);


        // 【3】useMemo
        // 跳转到上一个标签
        const prevTag = () => {
            if (tagList.length <= 1 || activeIndex === 0) {
                return;
            }
            setCurrentTag(activeIndex - 1);
        };
        // 跳转到下一个标签
        const nextTag = () => {
            if (tagList.length <= 1 || activeIndex === tagList.length - 1) {
                return;
            }
            setCurrentTag(activeIndex + 1);
        };
        // 设置当前标签
        const setCurrentTag = (activeIndex: number) => {
            setActiveIndex(activeIndex);
            let curTagActive = tagList[activeIndex];
            let curTagActivePath = curTagActive.path;
            navigate(curTagActivePath);
            setCurrentPath(curTagActivePath);
            setActivePath(curTagActivePath);
        };
        // 标签右侧更多下拉选项
        const items: MenuProps['items'] = [
            {
                key: 'tagsCloseLeft',
                label: (
                    <div
                        className={styles.tagsRightOperationWrap}
                        onClick={() => {
                            if (tagList.length === 1 || activeIndex <= 1) {
                                return;
                            }
                            currentCloseType.current = CLOSE_TYPE.LEFT;
                            isExitEditingFun();
                        }}
                    >
                        <div className={styles.tagsRightOperationCloseIcon}>
                            <IconFont type="icon-zuojiantou"/>
                        </div>
                        <div className={styles.tagsRightOperationCloseText}>
                            <FormattedMessage id="closeLeft"/>
                        </div>
                    </div>
                ),
                disabled: tagList.length === 1 || activeIndex <= 1,
            },
            {
                key: 'tagsCloseRight',
                label: (
                    <div
                        className={styles.tagsRightOperationWrap}
                        onClick={() => {
                            if (tagList.length === 1 || activeIndex === tagList.length - 1) {
                                return;
                            }
                            currentCloseType.current = CLOSE_TYPE.RIGHT;
                            isExitEditingFun();
                        }}
                    >
                        <div className={styles.tagsRightOperationCloseIcon}>
                            <IconFont type="icon-youjiantou"/>
                        </div>
                        <div className={styles.tagsRightOperationCloseText}>
                            <FormattedMessage id="closeRight"/>
                        </div>
                    </div>
                ),
                disabled: tagList.length === 1 || activeIndex === tagList.length - 1,
            },
            {
                key: 'tagsCloseOther',
                label: (
                    <div
                        className={styles.tagsRightOperationWrap}
                        onClick={() => {
                            if (
                                tagList.length === 1 ||
                                (tagList.length === 2 &&
                                    tagList.some((tagItem: ITagItemProps) => {
                                        return tagItem.path === activePath && activePath !== '/dashboard';
                                    }))
                            ) {
                                return;
                            }
                            currentCloseType.current = CLOSE_TYPE.OTHER;
                            isExitEditingFun();
                        }}
                    >
                        <div className={styles.tagsRightOperationCloseIcon}>
                            <IconFont type="icon-guanbi"/>
                        </div>
                        <div className={styles.tagsRightOperationCloseText}>
                            <FormattedMessage id="closeOther"/>
                        </div>
                    </div>
                ),
                disabled:
                    tagList.length === 1 ||
                    (tagList.length === 2 &&
                        tagList.some((tagItem: ITagItemProps) => {
                            return tagItem.path === activePath && activePath !== '/dashboard';
                        })),
            },

            {
                key: 'tagsCloseAll',
                label: (
                    <div
                        className={styles.tagsRightOperationWrap}
                        onClick={() => {
                            console.log('菜单是否为加载状态1', menuLoadingStatus)
                            if (tagList.length === 1) {
                                return;
                            }
                            currentCloseType.current = CLOSE_TYPE.ALL;
                            isExitEditingFun();
                        }}
                    >
                        <div className={styles.tagsRightOperationCloseIcon}>
                            <IconFont type="icon-a-guanbi2"/>
                        </div>
                        <div className={styles.tagsRightOperationCloseText}>
                            <FormattedMessage id="closeAll"/>
                        </div>
                    </div>
                ),
                disabled: tagList.length === 1,
            },
        ];
        // 右侧操作按钮区
        const operations = useMemo(() => {
            return (
                <div className={styles.tagsRightOperation} ref={tagsRightOperationContain}>
                    <div className={styles.tagsRightForwardAndBack}>
                        <div
                            onClick={prevTag}
                            className={`${styles.tagsRightBackIcon} ${styles.tagsRightIcon} ${
                                tagList.length <= 1 || activeIndex === 0 ? styles.tagRightOperationDisabled : ''
                            }`}
                        >
                            <IconFont type="icon-yeqianlan-xiangzuo"/>
                        </div>
                        <div
                            onClick={nextTag}
                            className={`${styles.tagsRightForwardIcon} ${styles.tagsRightIcon} ${
                                tagList.length <= 1 || activeIndex === tagList.length - 1 ? styles.tagRightOperationDisabled : ''
                            }`}
                        >
                            <IconFont type="icon-yeqianlan-xiangyou"/>
                        </div>
                    </div>
                    <div className={styles.tagsRightOperateMore}>
                        <Dropdown
                            overlayClassName={styles.tagsRightOperateDropDownMore}
                            menu={{items}}
                            disabled={tagList.length === 1}
                        >
                            <div
                                className={`${styles.tagsRightOperateMoreIcon} ${styles.tagsRightIcon} ${
                                    tagList.length <= 1 ? styles.tagRightOperationDisabled : ''
                                }`}
                            >
                                <IconFont type="icon-yeqianlan-xiala"/>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            );
        }, [activeIndex, tagList, menuLoadingStatus]);

        // 【4】useEffect
        useEffect(() => {
            if (location.pathname === '/') {
                navigate('/dashboard');
            }
        }, [leftMenuList]);

        useEffect(() => {
            const {search, pathname} = location;
            const pathType = getPathType(currentPath);
            // 去掉menuList请求限制，因为对其做了监听，改为在pathOpenTab方法里面进行限制
            // if (leftMenuList.length !== 0 || (leftMenuList.length === 0 && pathType !== 'menu')) {
            // 根据路由匹配去添加Tag
            pathOpenTab({
                tabCurrentPath: currentPath,
                setCurrentPath,
                setActivePath,
                locationPathname: pathname,
                locationSearch: search,
            });
            if (pathname.startsWith('/dashboard')) {
                setActiveIndex(0)
            }
            // }
        }, [location, leftMenuList]);

        // 设置当前tagIndex
        useEffect(() => {
            setActiveIndex(
                tagList.findIndex((tagItem: ITagItemProps) => {
                    return tagItem.path === location.pathname;
                }),
            );
        }, [tagList]);

        // 【5】useCallback
        /**
         * 标签change
         * @param path
         */
        const handleChnage = (path: string) => {
            if (location.pathname !== path) {
                navigate(path);
                setCurrentPath(path);
                setActivePath(path);
                setActiveIndex(
                    tagList.findIndex((tagItem: ITagItemProps) => {
                        return tagItem.path === path;
                    }),
                );
            }
        };

        /**
         * 标签删除或增加操作
         * @param targetKey
         * @param action
         */
        const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
            if (action !== 'add') {
                handleCloseCurrentTags(targetKey as string);
            }
        };
        /**
         * 关闭当前标签页
         * @param path
         */
        const handleCloseCurrentTags = (path: string) => {
            handleCloseAndRedirect(path, true);
        };

        /**
         * 关闭所有标签页
         */
        const handleCloseAllTags = () => {
            tagList.forEach((tag: ITagItemProps) => {
                const {path} = tag;
                if (path !== '/dashboard') {
                    handleCloseAndRedirect(path, false);
                    deleteTag(path);
                }
            });
            navigate('/dashboard');
        };

        /**
         * 关闭左侧标签页
         */
        const handleCloseLeftTags = () => {
            const index = getTagList.findIndex((tag: ITagItemProps) => tag.path === activePath); // 当前路由的索引
            // 删除左侧菜单
            for (let i = Number(index) - 1; i > 0; i--) {
                const closePath = getTagList[i].path;
                handleCloseAndRedirect(closePath, false);
                deleteTag(closePath);
            }
            navigate(activePath);
        };

        /**
         * 关闭右侧标签页
         */
        const handleCloseRightTags = () => {
            const index = getTagList.findIndex((tag: ITagItemProps) => tag.path === activePath); // 当前路由的索引
            // 删除右侧菜单
            for (let i = Number(index) + 1; i < getTagList.length; i++) {
                const closePath = getTagList[i].path;
                handleCloseAndRedirect(closePath, false);
                deleteTag(closePath);
            }
            navigate(activePath);
        };

        /**
         * 关闭其它标签页
         */
        const handleCloseOtherTags = () => {
            tagList.forEach((tag: ITagItemProps) => {
                const {path} = tag;
                if (path !== '/dashboard' && path !== activePath) {
                    handleCloseAndRedirect(path, false);
                    deleteTag(path);
                }
            });
            navigate(activePath);
        };

        // 关闭无界tag
        const handleCloseTag = (menu: MenuItem) => {
            if (menu.config?.loadType === 'microApp') {
                const currentTabKey = menu.config.url.split('/')[0];
                let path = menu.config.url.slice(currentTabKey.length).replace('#/', '');
                if (path && path.search(/caption/gi) === -1 && menu?.name && currentTabKey === 'web') {
                    if (path.indexOf('?') !== -1) {
                        path += `&caption=${menu?.name}`
                    } else {
                        path += `?caption=${menu?.name}`
                    }
                }
                console.log(
                    `WUJIE-ACTION-给==${currentTabKey.toUpperCase()}_TAB_KEY==应用通信，action是close，path是----${path}`,
                );
                WujieReact.bus.$emit(`${currentTabKey.toUpperCase()}_TAB_KEY`, {
                    action: 'close',
                    path,
                } satisfies NotifyChildApplicationsType
            )
                ;
            }
        };

        /**
         * 清除tag并跳转
         * @param {*} path
         * @param {*} redirect  是否重定向
         */
        const handleCloseAndRedirect = (path: string, redirect: boolean) => {
            if (menuLoadingStatus) {
                return
            }
            const win = window as any;
            let beforeClosed = true;
            const currentPath = location.pathname;
            const currentTag = getTagList.find((tag: ITagItemProps) => tag.path === path); // 当前Tag
            if (currentTag.config?.loadType === LOAD_TYPE.Micro_APP) {
                const currentTabKey = currentTag.config.url.split('/')[0];
                if(currentTabKey.search(/:/gi) === -1 && win?.document.querySelector(`iframe[name=${currentTabKey}]`)?.contentWindow?.beforeCloseWuJieTag){
                    let tagPath = currentTag?.config?.url.replace(`${currentTabKey}`, '').replace('#/', '');
                    // 拼接菜单名字
                    if (tagPath?.search(/caption/gi) === -1 && currentTag?.name && currentTabKey === 'web') {
                        if (tagPath.indexOf('?') !== -1) {
                            tagPath += `&caption=${currentTag?.name}`;
                        } else {
                            tagPath += `?caption=${currentTag?.name}`;
                        }
                    }
                    beforeClosed = win?.document.querySelector(`iframe[name=${currentTabKey}]`)?.contentWindow?.beforeCloseWuJieTag({
                        ...currentTag,
                        path:tagPath
                    })
                }
            }else if(currentTag.config?.loadType === LOAD_TYPE.IFRAME && isSameOriginFun(window.location.origin,currentTag.config.url)){
                let iframeEl = getIframeContainEle(currentTag.config.url);
                if(iframeEl?.contentWindow?.beforeCloseWuJieTag){
                    beforeClosed = iframeEl?.contentWindow?.beforeCloseWuJieTag(currentTag)
                }
            }
            if(!beforeClosed){
                return
            }
            const {length} = tagList;
            if (redirect) {
                // 如果关闭的是当前页，跳转到最后一个tag
                if (currentTag.isEditing !== true) {
                    if (path === currentPath) {
                        const nextPath = tagList[length - 1].path;
                        navigate(nextPath);
                    }
                    // 如果关闭的是最后的tag ,且当前显示的也是最后的tag对应的页面，才做路由跳转
                    if (path === tagList[length - 1].path && currentPath === tagList[length - 1].path) {
                        // 因为cutTaglist在最后执行，所以跳转到上一个tags的对应的路由，应该-2
                        if (length - 2 > 0) {
                            const nextPath = tagList[length - 2].path;
                            navigate(nextPath);
                        } else if (length === 2) {
                            const nextPath = tagList[0].path;
                            navigate(nextPath);
                        }
                    }
                    if (!path) {
                        const nextPath = tagList[0].path;
                        navigate(nextPath);
                    }
                    deleteTag(path);
                }
                // Close Tag
                handleCloseTag(tagList.find((tag: any) => tag.path === path));
            }
        };

        const tagsWrapClassNames = classnames({
            [styles['portal-tags-wrap']]: true,
            [styles['portal-tags-wrap-fixed']]: collectMenuFixed,
        });

        // 判断是否存在未保存的tab页
        const isExitEditingFun = () => {
            if (menuLoadingStatus) {
                return
            }
            // 关闭所有
            let index: any;
            const currentPathIndex = getTagList.findIndex((tag: ITagItemProps) => tag.path === activePath); // 当前路由的索引
            switch (currentCloseType.current) {
                case CLOSE_TYPE.ALL: // 关闭全部
                    index = getTagList.findIndex((tag: ITagItemProps) => tag?.isEditing === true);
                    if (index < 0) {
                        handleCloseAllTags();
                    }
                    break;
                case CLOSE_TYPE.LEFT: // 关闭左边
                    index = getTagList.findIndex(
                        (tag: ITagItemProps, currentIndex: number) => tag?.isEditing && currentPathIndex > currentIndex,
                    );
                    if (index < 0) {
                        handleCloseLeftTags();
                    }
                    break;
                case CLOSE_TYPE.RIGHT: // 关闭右边
                    index = getTagList.findIndex(
                        (tag: ITagItemProps, currentIndex: number) => tag?.isEditing && currentPathIndex < currentIndex,
                    );
                    if (index < 0) {
                        handleCloseRightTags();
                    }

                    break;
                case CLOSE_TYPE.OTHER: // 关闭其他
                    index = tagList.findIndex((tag: ITagItemProps) => tag?.isEditing === true && tag.path !== activePath); // 当前路由的索引
                    if (index < 0) {
                        handleCloseOtherTags();
                    }
                    break;
            }
            if (index > 0) setIsModalOpen(true);
        };
        // 提示框点击确定后
        const handleOk = () => {
            setIsModalOpen(false);
            switch (currentCloseType.current) {
                case CLOSE_TYPE.ALL: // 关闭全部
                    handleCloseAllTags();
                    break;
                case CLOSE_TYPE.LEFT: // 关闭左边
                    handleCloseLeftTags();
                    break;
                case CLOSE_TYPE.RIGHT: // 关闭右边
                    handleCloseRightTags();
                    break;
                case CLOSE_TYPE.OTHER: // 关闭其他
                    handleCloseOtherTags();
                    break;
            }
        };
        const {curAppName, curMenu, apps} = useWuJieAppNames(getTagList, activePath);


        const sensor = useSensor(PointerSensor, {activationConstraint: {distance: 10}});

        const onDragEnd = ({active, over}: DragEndEvent) => {
            if (active.id !== over?.id && [active.id, over?.id].indexOf('/dashboard') === -1) {
                const activeIndex = tagList.findIndex((i: any) => i.path === active.id);
                const overIndex = tagList.findIndex((i: any) => i.path === over?.id);
                console.log('activeIndex', activeIndex, 'overIndex', overIndex, over)
                if (activeIndex > overIndex) {  // 移动到前面
                    if (tagList[overIndex]?.isPin) {
                        tagList[activeIndex].isPin = true;
                    }
                } else {  // 移动到后面
                    if (!tagList[overIndex]?.isPin) {
                        tagList[activeIndex].isPin = false;
                    }
                }
                setTagList(arrayMove(tagList, activeIndex, overIndex))
            }
        };

        const onDragStart = ({active}: DragStartEvent) => {
            setActiveDragTabId(active.id)
        }

        useEffect(() => {
            const handleClickOutside = (event: any) => {
                const isOutside = !(contextMenuContainer.current?.contains(event.target));
                if (isOutside && menuVisible) {
                    closeContextMenu();
                }
            };
            const componentUnmount = () => {
                document.body.removeEventListener('click', handleClickOutside);
            };
            document.body.addEventListener('click', handleClickOutside);
            return componentUnmount;
        }, [menuVisible, contextMenuContainer]);

        /**
         * 关闭右键菜单
         */
        const closeContextMenu = () => {
            setMenuVisible(false);
        };

        /**
         * 标签右键事件
         * @param tag
         * @param event
         */
        const openContextMenu = (tag: any, event: any) => {
            event.preventDefault();
            if(tag.path === '/dashboard'){
                return
            }
            // 设置当前右键操作的tag
            setCurentContextStateTag(tag)
            const {clientWidth: tagsRightClientWidth} = tagsRightOperationContain.current;
            const clickX = event.clientX;
            // 需减去头部的高度
            const clickY = event.clientY - 44; // 事件发生时鼠标的Y坐标
            const {clientWidth} = tagListContainer.current; // container width
            const maxLeft = clientWidth - tagsRightClientWidth; // left boundary


            // 当鼠标点击位置大于左侧边界时，说明鼠标点击的位置偏右，将菜单放在左边
            if (clickX > maxLeft) {
                setLeft(clickX - 15);
                setTop(clickY);
                setMenuVisible(true);
            } else {
                // 反之，当鼠标点击的位置偏左，将菜单放在右边
                setLeft(clickX);
                setTop(clickY);
                setMenuVisible(true);
            }
        }

        /**
         * 页签固定后，需要对页签重新排序
         */
        const tagPinSort = () => {
            const indexTag = getTagList.filter((filterItem: any) => {
                return filterItem.path === '/dashboard'
            })
            const isPinTagList = getTagList.filter((filterItem: any) => {
                return filterItem.isPin && filterItem.path !== '/dashboard'
            })
            const isUnPinTagList = getTagList.filter((filterItem: any) => {
                return !filterItem.isPin && filterItem.path !== '/dashboard'
            })
            let upDateTagList = [...indexTag,...isPinTagList, ...isUnPinTagList];
            setTagList(upDateTagList)
        }

        /**
         * 固定页签方法
         */
        const togglePinTab = () => {
            let isPin = curentContextStateTag?.isPin;
            let updateCurrentState = {
                ...curentContextStateTag,
                isPin: !isPin
            }
            setCurentContextStateTag(updateCurrentState)
            updateTabPinStatus(curentContextStateTag?.path, updateCurrentState.isPin);

            tagPinSort()

            setMenuVisible(false)
        }

        return (
            <>
                <div className={tagsWrapClassNames} ref={tagListContainer}>
                    <Tabs
                        type="editable-card"
                        hideAdd
                        onChange={handleChnage}
                        tabBarExtraContent={operations}
                        activeKey={location.pathname === activePath ? activePath : currentPath}
                        animated={false}
                        items={getTagList.map((tag: ITagItemProps) => {
                            return {
                                label: (
                                    <div
                                        className={`${styles['portal-tags-title']} portal-tags-title ${tag.path === '/dashboard' ? 'portal-tags-title-index' : ''}`}
                                        onContextMenu={(e) => openContextMenu(tag, e)} onClick={(event) => {
                                        const isOutside = !(contextMenuContainer.current?.contains(event.target));
                                        if (isOutside && menuVisible) {
                                            closeContextMenu();
                                        }
                                    }}>
                                        {tag.path === '/dashboard' ? (
                                            <div className={styles.tagsDashboard}>
                                                <IconFont type="icon-yeqianlan-shouye"/>
                                                <span className={styles.tagsDashboardText}>首页</span>
                                            </div>
                                        ) : (
                                            tag.name
                                        )}
                                        {tag?.isEditing && (
                                            <div
                                                className={`${styles['portal-tags-title-mark']} ${
                                                    tag.path === currentPath && styles['portal-tags-title-mark-active']
                                                }`}
                                            />
                                        )}
                                        {
                                            tag.path !== '/dashboard' && !tag?.isPin && (
                                                <button onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleCloseCurrentTags(tag.path as string)
                                                }} type="button" aria-label="remove"
                                                        className={`ant-tabs-tab-remove ${styles.closeIconWrap}`}>
                                                    <CloseOutlined/>
                                                </button>
                                            )
                                        }
                                        <div onClick={(event) => {
                                            event.stopPropagation();
                                            updateTabPinStatus(tag.path, false)
                                            tagPinSort()
                                        }} className={`${styles.portalPin} ${tag?.isPin ? styles.portalPinShow : ''}`}
                                             title={intl.formatMessage({id: 'unPinTab'})}>
                                            <Pin/>
                                        </div>
                                    </div>
                                ),
                                key: tag.path,
                                children: tag.config?.loadType === 'microApp' ? null : <TagContent tag={tag}/>, // 无界的话用下边的div
                                forceRender: true,
                                closable: false,
                            };
                        })}
                        onEdit={onEdit}
                        renderTabBar={(tabBarProps, DefaultTabBar) => (
                            <DndContext sensors={[sensor]} onDragEnd={onDragEnd} onDragStart={onDragStart}>
                                <SortableContext items={getTagList.map((i: any) => i.path)}
                                                 strategy={horizontalListSortingStrategy}>
                                    <DefaultTabBar {...tabBarProps}>
                                        {(node) => {
                                            return (
                                                <DraggableTabNode {...node.props} key={node.key}
                                                                  activeDragId={activeDragTabId}>
                                                    {node}
                                                </DraggableTabNode>
                                            )
                                        }}
                                    </DefaultTabBar>
                                </SortableContext>
                            </DndContext>
                        )}
                    />
                    {/* {curAppName && curMenu && curMenu?.config?.loadType === 'microApp' && (
          <WuJie curAppName={curAppName} menuItem={curMenu} apps={apps} />
        )} */}
                    <WuJie curAppName={curAppName} menuItem={curMenu} apps={apps} collectMenuFixed={collectMenuFixed}/>
                    <Modal
                        title="提示"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={() => {
                            setIsModalOpen(false);
                        }}
                        bodyStyle={{height: 60}}
                    >
                        <p>确定关闭未保存菜单？</p>
                    </Modal>
                </div>
                {menuVisible ? (
                    <ul className={styles.portalTagsAppConTextMenu} style={{left: `${left}px`, top: `${top}px`}}
                        ref={contextMenuContainer}>
                        <li onClick={togglePinTab}>
                            <FormattedMessage id={curentContextTag?.current?.isPin ? 'unPinTab' : 'pinTab'}/>
                        </li>
                    </ul>
                ) : null}
            </>
        );
    }),
);

export default TagsList;
