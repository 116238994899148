/*
 * @Author: yar
 * @Date: 2023-02-07 15:06:54
 * @LastEditTime: 2023-08-17 16:04:35
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\GlobalSearch\components\SearchList\index.tsx
 */

import React, { useState } from 'react';
// import { Resizable } from 'react-resizable';
// import 'react-resizable/css/styles.css';
import styles from './index.module.less';
import SearchHistory from '../SearchHistory';
import SearchClassify from '../SearchcCassify';
const IndexPage: React.FC<{
  dataSource: any[];
  onSelect: Function; // 点击行时触发的方法(获取到当前所有已选中的行数据)
  selectValue: string[]; // 当前选中的数据（key值)
  onChange: Function; // 点击行时触发的方法(获取到当前点击的行数据)
  keyWord?: string; // 高亮的关键词
  style?: any; // 自定义样式
  nominateSetSelected: Function; // 历史推荐数据 触发
  historyWordData: string[]; // 关键词
  setOptionsData: Function;
  toDetailPage: Function;
  visiblePanel: string;
  historyFuncData: any[];
  guessData: string[]; // 猜你想搜数据
  searchValue: string;
}> = (props) => {
  const {
    dataSource,
    onSelect,
    selectValue,
    onChange,
    keyWord,
    style,
    nominateSetSelected,
    historyWordData,
    setOptionsData,
    toDetailPage,
    visiblePanel,
    historyFuncData,
    guessData,
    searchValue,
  } = props;

  const [selectedValue, setSelectedValue] = useState<string[]>(historyWordData);
  const [selectKeyValue, setSelectKeyValue] = useState<string[]>(selectValue); // 存储当前选中项的唯一标识值

  /**
   * 点击详细搜索进入详情搜索页
   */
  const goToDetailPage = (key?: string, allInfo?: any) => {
    toDetailPage(key, allInfo);
  };
  const renderMenuList = () => {
    return (
      <div className={styles.searchListBox}>
        {/* 1. 搜索关键词部分 */}
        <div className={styles.historyPanel}>
          <SearchHistory
            historyData={historyWordData}
            highLightWords={keyWord}
            onSelected={nominateSetSelected}
            setOptionsData={setOptionsData}
            showHisOrReal={visiblePanel}
            dataSource={dataSource}
            guessData={guessData}
          />
        </div>
        {/* 2. 实时监听搜索列表_按功能分类 */}
        <div className={styles.classifyPanel}>
          <SearchClassify
            classifyData={dataSource}
            selectValue={[searchValue]}
            onChange={onChange}
            onClick={goToDetailPage}
            visiblePanel={visiblePanel}
            historyFuncData={historyFuncData}
          />
        </div>
      </div>
    );
  };

  return <div className={styles.searchContainer}>{dataSource && renderMenuList()}</div>;
};
export default IndexPage;
