import { action, computed, observable } from 'mobx';

export interface ITagProps {
  name: string;
  path: string;
  [key: string]: any;
}

class TagsViewStore {
  @observable public tagList: ITagProps[] = [
    {
      name: '首页',
      path: '/dashboard',
    },
  ];
  /**
   * 当前选中的tab => 路由path
   */
  @observable public activePath = '/dashboard';

  // 当前tab栏是否显示
  @observable public tabVisible = true;

  @computed
  public get getTagList() {
    return this.tagList;
  }
  @action.bound
  public setTagList(result: ITagProps[]) {
    this.tagList = result;
  }
  @action.bound
  public addTag(tag: ITagProps) {
    if (this.tagList.length === 0 && tag.path !== '/dashboard') {
      const obj = {
        name: '首页',
        path: '/dashboard',
      };
      this.tagList = [obj];
    }

    if (tag?.path) {
      // 检测path是否是以 / 开头，如不是添加 /
      let reg = /^\//gi;
      if (!reg.test(tag.path)) {
        tag.path = `/` + tag.path;
      }
    }
    const newArr = this.tagList.filter((item) => item.path === tag.path);
    if (newArr.length === 0) {
      this.tagList = [...this.tagList, tag];
    } else if (tag.reloadFlag) {
      const index = this.tagList.findIndex((item) => item.path === tag.path);
      this.tagList[index] = tag;
    }
  }

  @action.bound
  public deleteTag(tag: string) {
    this.tagList = [...this.tagList.filter((item) => item.path !== tag)];
  }

  @action.bound
  public emptyTagList() {
    this.tagList = [];
  }

  @action.bound
  public closeOtherTag(tag: ITagProps) {
    this.tagList = [...this.tagList.filter((item) => item.path === '/dashboard' || item.path === tag.path)];
  }

  @action.bound
  public closeRightTag(index: number) {
    this.tagList = [...this.tagList.filter((item, i) => i <= index)];
  }

  @action.bound
  public setActivePath(value: string) {
    this.activePath = value;
  }

  // 获取activePath
  @action.bound
  public getActivePath() {
    return this.activePath;
  }

  // 修改tab页标题
  @action.bound
  public updateCurrentTabTitle(newTitle: string) {
    const tagList = this.getTagList;
    tagList.forEach((tagItem, index) => {
      const item = { ...tagItem };
      if (tagItem.path === this.getActivePath()) {
        item.title = newTitle;
      }
      tagList[index] = item;
    });
  }

  // 设置tab是否显示
  @action.bound
  public setTabVisible(flag: boolean) {
    this.tabVisible = flag;
  }
  // 依据config中的url删除tag
  @action.bound
  public deleteTagByUrl(tagUrl: string) {
    this.tagList = [...this.tagList.filter((item) => item?.config?.url !== tagUrl)];
  }
  // 修改tab页编辑状态
  @action.bound
  public updateCurrentTabEditing(isEditing: boolean) {
    const tagList = this.getTagList;
    tagList.forEach((tagItem, index) => {
      const item = { ...tagItem };
      if (tagItem.path === this.getActivePath()) {
        item.isEditing = isEditing;
      }
      tagList[index] = item;
    });
  }


  // 修改tab isPin
  @action.bound
  public updateTabPinStatus(path:any,isPin: boolean) {
    const tagList = this.getTagList;
    tagList.forEach((tagItem, index) => {
      const item = { ...tagItem };
      if (tagItem.path === path) {
        item.isPin = isPin;
      }
      tagList[index] = item;
    });
  }
}

const tagsViewStore = new TagsViewStore();
export default tagsViewStore;
