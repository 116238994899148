/*
 * @Author: ZY
 * @Date: 2023-02-21 18:52:47
 * @LastEditors: ZY
 * @LastEditTime: 2023-07-03 12:18:03
 * @FilePath: /erp-finance-biz-portal-react-start/src/utils/storage.ts
 * @Description: 全局本地存储配置文件
 */
import { clear, get, set, values } from 'idb-keyval';
import Cookies from 'js-cookie';
import { MenuItem } from '../types';

/**
 * @description: localStorage 相关方法
 */
export const setItem = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const getItem = (key: string) => {
  return localStorage.getItem(key);
};

export const clearItem = () => {
  return localStorage.clear();
};

/**
 * @description: indexDB 相关方法
 */
export const idbSetItem = (key: string, value: MenuItem[] | any) => {
  return set(key, value);
};

export const idbGetItem = (key: string) => {
  return get(key);
};

export const idbValues = async () => {
  return await values();
};

export const idbClear = () => {
  return clear();
};

/**
 * @description: cookies 相关方法
 */

export const setCookies = (key: string, value: string) => {
  return Cookies.set(key, value);
};

export const getCookies = (key: string) => {
  return Cookies.get(key);
};

export const clearCookiesAll = () => {
  let cookies = Cookies.get();
  for (let cookie in cookies) {
    if (Object.hasOwn(cookies, cookie)) {
      // 删除一级域名cookies
      Cookies.remove(cookie, { domain: '.' + window.location.hostname.split('.').slice(-2).join('.') });
      // 删除当前域名cookies
      Cookies.remove(cookie);
    }
  }
};
