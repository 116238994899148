/*
 * @Author: jld
 * @Date: 2023-02-24 14:35:33
 * @LastEditTime: 2023-03-07 15:21:34
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\stores\components\RecentUseStore.tsx
 */

import { MenuItem } from 'src/types';
import { action, computed, observable } from 'mobx';
import { idbSetItem, getCookies } from 'src/utils/storage';
import { STORAGE_KEY, Cookie_KEY } from 'src/constant/storageKey';

class RecentUseStore {
  // 一级抽屉是否展开
  @observable public recentUseList: MenuItem[] = [];

  @computed
  public get getRecentUseList() {
    return this.recentUseList;
  }
  @action.bound
  public addRecentUse(recentUseItem: MenuItem) {
    if (!this.recentUseList?.length) {
      this.recentUseList = [];
    }
    const index = this.recentUseList.findIndex((item) => item.id === recentUseItem.id);
    if (index === -1) {
      this.recentUseList = [recentUseItem, ...this.recentUseList];
    } else {
      // 位置前移
      this.recentUseList = [...this.recentUseList.filter((item) => item.id !== recentUseItem.id)];
      this.recentUseList = [recentUseItem, ...this.recentUseList];
    }
    const recentUseListTemp = this.recentUseList?.map((item: MenuItem) => {
      return item;
    });
    idbSetItem(`${STORAGE_KEY.RECENT_USE}-${getCookies(Cookie_KEY.USER_NAME)}`, recentUseListTemp);
  }

  @action.bound
  public setRecentUseList(state: MenuItem[]) {
    this.recentUseList = state;
  }
}

const recentUseStore = new RecentUseStore();
export default recentUseStore;
