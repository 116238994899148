/*
 * @Author: yar
 * @Date: 2023-02-08 20:24:57
 * @LastEditTime: 2024-03-20 13:55:26
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\UserInfo\index.tsx
 */
import React, {useEffect, useState, useRef} from 'react';
import {Drawer, Select, RefSelectProps, Popconfirm, message, Popover} from 'antd';
import {IUserInfoProps} from '../UserInfo/type';
import {inject, observer} from 'mobx-react';
import userAvator from 'src/asset/user_photo.png';
import styles from './index.module.less';
import {NavigateFunction} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import PuiIconFont from 'src/components/PuiIconFont';
import {MenuItem} from '@/types';
import {FormattedMessage, useIntl} from 'react-intl';
import stores from 'src/stores';
import {IOrganizations} from '@/api/types/settings';
import {getFunctionOption} from './viewModal';
import {loginOut} from 'src/utils/user';
import classNames from 'classnames';
import SelectWrapper from 'src/components/SelectWrapper';
import {ISettingMenuItem} from './type';
import {accountSettingList, systemSettingList} from './dataModel';
import {getUsableThemeSettingInfo} from 'src/api/theme';
import {getSystemSettings, updateSystemSettings} from 'src/api';
import {changeThemeToken} from 'src/utils/theme';
import InternationalizationStore from 'src/stores/components/InternationalizationStore';
import {setItem} from 'src/utils';
import {STORAGE_KEY, MESSAGE_KEY} from 'src/constant';
import WujieReact from 'wujie-react';
import {sendMessageUtil} from 'src/components/routerContainer/IFrame/postMessage';
// 清除indexDB
import {idbClear} from 'src/utils/storage';
import clear from 'src/asset/clear.png';
import {getBrowserAndValidGlobalTheme} from 'src/api/systemSetting';
import {SYSTEM_SETTING} from 'src/pages/SystemSetting/model';
import {InfoCircleOutlined} from '@ant-design/icons';
import {getIframeContainEle} from "src/utils/dom";

const {Option} = Select;

export interface UserAccountClickModle {
    props: any;
    item: MenuItem;
    navigate: NavigateFunction;
}

export interface IResponseDataType {
    configCode: string;
    configName: string;
    configValueCode: string;
}

const IndexPage: React.FC<IUserInfoProps> = inject(
    'UserStore',
    'RecentUseStore',
    'DrawerStore',
    'AppState',
    'InternationalizationStore',
    'ThemeStore',
)(
    observer((props) => {
        const {
            UserStore: {userCaption},
            RecentUseStore: {setRecentUseList},
            DrawerStore: {showFunDrawer, setShowFunDrawer, setShowFunDrawerFlag},
            AppState: {themeToken, setThemStyle, themStyle},
            InternationalizationStore: {lang, overallDefaultMenu,setLang,setDefaultMenu},
            ThemeStore: {setGlobalThemeId, setGlobalThemeEndTime, globalThemeId, grayTheme, setGrayTheme},
        } = props;
        // 国际化
        const intl = useIntl();
        let navigate = useNavigate();
        const [userSystemDrawerOpen, setUserSystemDrawerOpen] = useState<boolean>(false);
        // 机构
        const orgInfosRef = useRef<any>([]);
        const [orgOptions, setOrgOptions] = useState<IOrganizations[]>();
        const [currentInfo, setCurrentInfo] = useState<string>();
        // 系统设置
        const systemSettingListRef = useRef<any>(systemSettingList);

        // 主题
        const [usableThemeList, setUsableThemeList] = useState([]);
        const [themeColor, setThemeColor] = useState([]);
        const [themeValue, setThemeValue] = useState([]);

        const [validTheme, setValidTheme] = useState<any>({});
        const [defaultTheme, setDefaultTheme] = useState<any>({});
        const curGlobalThemeRef = useRef<any>({});
        curGlobalThemeRef.current = validTheme;

        const unitOpen = useRef<RefSelectProps>(null);
        const overallStyleOpen = useRef<RefSelectProps>(null);
        const themeOpen = useRef<RefSelectProps>(null);

        useEffect(() => {
            const getData = async () => {
                let optionTemp = await getFunctionOption(); // 当前页需要显示组织机构\
                orgInfosRef.current = optionTemp;
                let defaultData = '';
                let options: any = optionTemp.map((item: IOrganizations) => {
                    if (item.defaultFlag === '1') {
                        defaultData = item.orgCode;
                    }
                    return {
                        value: item.orgCode,
                        label: item.orgName,
                    };
                });
                setCurrentInfo(stores.UserStore.orgInfo?.orgCode ?? defaultData);
                setOrgOptions(options);
            };
            getData();
            initData();
        }, [globalThemeId]);

        useEffect(() => {
            if (themStyle) {
                systemSettingListRef.current[1].value = themStyle;
            }
            if (lang) {
                systemSettingListRef.current[2].value = lang;
            }
            if (overallDefaultMenu) {
                systemSettingListRef.current[3].value = overallDefaultMenu;
            }
        }, [themStyle, overallDefaultMenu, lang]);

        const getUsableThemeList = async () => {
            await getUsableThemeSettingInfo()
                .then((res: any) => {
                    if (res?.length > 0) {
                        setUsableThemeList(res);
                        const themeTokenList: any = [];
                        res.forEach((item: any) => {
                            if (item?.defaultState === '1') {
                                setDefaultTheme(item);
                            }
                            const {content} = item.infoJson;
                            let transformedObject = content.reduce((acc: any, item: any) => {
                                acc[item.key] = item.value;
                                return acc;
                            }, {});
                            // 合并高级配置
                            if (item?.additionalJson && Object.keys(item?.additionalJson).length > 0) {
                                // key 值不是以 -- 开头 加上--，保证是css 变量
                                let newadditionalJson: any = {};
                                Object.keys(item?.additionalJson).forEach((additionalJsonKey: any) => {
                                    let newKey = additionalJsonKey;
                                    let originValue = item?.additionalJson[additionalJsonKey];
                                    if (!additionalJsonKey.startsWith('--')) {
                                        newKey = `--${additionalJsonKey}`;
                                    }
                                    newadditionalJson[newKey] = originValue;
                                });
                                transformedObject = Object.assign(transformedObject, newadditionalJson);
                            }
                            themeTokenList.push({
                                key: item.themeId,
                                value: transformedObject.colorPrimary,
                                label: item.themeName,
                                themeToken: transformedObject,
                            });
                        });
                        systemSettingListRef.current[0].options = themeTokenList;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const initData = async () => {
            await getUsableThemeList();
            // await getBrowserAndValidGlobalThemeSetting();
            await getAllSystemSettings();
            // const curTheme = themeToken.colorPrimary;
            // setThemeColor(curTheme);
        };

        const getBrowserAndValidGlobalThemeSetting = async () => {
            const res: any = await getBrowserAndValidGlobalTheme();
            setValidTheme(res?.[SYSTEM_SETTING.GLOBAL_THEME_LIMIT]?.jsonValue);
            setGlobalThemeId(res?.[SYSTEM_SETTING.GLOBAL_THEME_LIMIT]?.jsonValue?.themeId);
            // setGlobalThemeEndTime(res?.[SYSTEM_SETTING.GLOBAL_THEME_LIMIT]?.jsonValue?.endTs);
        };

        const onClose = () => {
            setUserSystemDrawerOpen(false);
        };

        // 获取系统配置
        const getAllSystemSettings = async () => {
            await getBrowserAndValidGlobalThemeSetting();
            const res: any = await getSystemSettings();
            if (res?.length) {
                let filterThemeId = curGlobalThemeRef.current?.themeId;
                res.forEach((item: IResponseDataType) => {
                    switch (item.configCode) {
                        case 'subjectColor': {
                            const chooseTheme = systemSettingListRef.current[0].options.find(
                                (i: any) => i.key === (filterThemeId ?? item.configValueCode),
                            )
                            console.log('chooseTheme', chooseTheme)
                            const chooseThemeToken = chooseTheme?.['themeToken'] ?? {
                                colorPrimary: '',
                            }
                            if (chooseThemeToken?.colorPrimary) {
                                setThemeValue(chooseTheme.key)
                                setThemeColor(chooseThemeToken?.colorPrimary);
                                changeThemeToken(chooseThemeToken);
                            } else {
                                const curDefaultTheme: any = systemSettingListRef.current[0].options.filter(
                                    (i: any) => i.key === defaultTheme?.themeId,
                                );
                                setThemeValue(curDefaultTheme.key)
                                setThemeColor(curDefaultTheme?.value);
                                changeThemeToken(curDefaultTheme?.['themeToken']);
                            }
                            break;
                        }
                        case 'language': {
                           let findLangItem =  systemSettingListRef.current[2].options.find((findItem:any) => {
                                return findItem.key === item.configValueCode
                            })
                            const langValue = findLangItem?.value ?? 'zh_CN'
                            setLang(langValue);
                            WujieReact.bus.$emit(MESSAGE_KEY.LANG_CHANGE, langValue);
                            let iframeElArr = getIframeContainEle()
                            if (iframeElArr && iframeElArr?.length > 0) {
                                for (let i = 0; i < iframeElArr?.length; i++) {
                                    sendMessageUtil(iframeElArr[i], {
                                        type: MESSAGE_KEY.LANG_CHANGE,
                                        data: langValue,
                                    });
                                }
                            }
                            break;
                        }
                        case 'menuOption':{
                            setDefaultMenu(item.configValueCode);
                            const showAllMenu:boolean = item.configValueCode === 'menuOptionMenu'
                            setShowFunDrawer(showAllMenu);
                            setShowFunDrawerFlag(showAllMenu);
                            break;
                        }
                        case 'overallStyle':{
                            setThemStyle(item.configValueCode);
                            break;
                        }
                        default:
                            break;
                    }
                });
            }
        };

        const getSettingItem = (settingList: ISettingMenuItem[], pathType: string, pathName: string) => {
            const getSettingSelect = (item: ISettingMenuItem) => {
                if (item.key === 'ThemeColor') {
                    return (
                        <SelectWrapper
                            popupClassName="theme-dropdown"
                            className={styles.themeSelect}
                            style={{width: '160px'}}
                            value={themeValue}
                            disabled={validTheme ? true : false}
                            onChange={(value: any, option: any) => {
                                const curThemeInfo: any = usableThemeList.filter((item: any) => item.themeId === option.key);
                                if (curThemeInfo[0].themeType === '1') {
                                    setGrayTheme('1');
                                } else {
                                    setGrayTheme('0');
                                }
                                setThemeValue(value)
                                setThemeColor(option.colorValue);
                                changeThemeToken(option.themeToken);
                                updateSystemSettings({configCode: 'subjectColor', configValueCode: option.key});
                            }}
                            ref={themeOpen}
                        >
                            {item.options?.map((option) => {
                                return (
                                    <Option key={option.key} label={option.label} value={option.key}
                                            themeToken={option.themeToken} colorValue={option.value}>
                                        <div className={classNames(styles.themeOption, 'dropdown-theme-option')}>
                                            <div
                                                className={classNames(styles.color, 'dropdown-color')}
                                                style={{backgroundColor: option.value}}
                                            />
                                            <div className={styles.themeOptionLabel} title={option.label}>
                                                {option.label}
                                            </div>
                                        </div>
                                    </Option>
                                );
                            })}
                        </SelectWrapper>
                    );
                } else {
                    return (
                        <SelectWrapper
                            defaultValue={item?.value}
                            ref={overallStyleOpen}
                            className={styles.themeSelect}
                            onChange={(value: any, option: any) => {
                                if (item.key === 'overallStyleSetting') {
                                    setThemStyle(value);
                                    updateSystemSettings({configCode: 'overallStyle', configValueCode: value});
                                } else if (item.key === 'systemLanguageSetting') {
                                    InternationalizationStore.setLang(value);
                                    setItem(STORAGE_KEY.LANG, value);
                                    WujieReact.bus.$emit(MESSAGE_KEY.LANG_CHANGE, value);
                                    let iframeElArr = getIframeContainEle()
                                    if (iframeElArr && iframeElArr?.length > 0) {
                                        for (let i = 0; i < iframeElArr?.length; i++) {
                                            sendMessageUtil(iframeElArr[i], {
                                                type: MESSAGE_KEY.LANG_CHANGE,
                                                data: value,
                                            });
                                        }
                                    }
                                    updateSystemSettings({configCode: 'language', configValueCode: option.key});
                                } else if (item.key === 'defaultMenuSetting') {
                                    InternationalizationStore.setDefaultMenu(value);
                                    updateSystemSettings({configCode: 'menuOption', configValueCode: value});
                                    if (
                                        (showFunDrawer && value === 'menuOptionMenu') ||
                                        (!showFunDrawer && value === 'menuOptionRecently')
                                    ) {
                                        return;
                                    }
                                    setShowFunDrawer(!showFunDrawer);
                                    setShowFunDrawerFlag(!showFunDrawer);
                                }
                            }}
                        >
                            {item.options?.map((option) => {
                                return (
                                    <Option key={option.key} label={option.label} value={option.value}>
                                        <div className={styles.label}>{option.label}</div>
                                    </Option>
                                );
                            })}
                        </SelectWrapper>
                    );
                }
            };
            return (
                <ul className={styles.settingItemBox}>
                    {settingList.map((item) => {
                        return item.key === 'clearCache' ? (
                            <Popconfirm
                                title={intl.formatMessage({id: 'sureClearingCache'})}
                                description={intl.formatMessage({id: 'clearTip'})}
                                onConfirm={confirm}
                                icon={null}
                                placement="topLeft"
                                okText={intl.formatMessage({id: 'determine'})}
                                cancelText={intl.formatMessage({id: 'cancel'})}
                                rootClassName={styles['portal-header-setting-modify-clear']}
                            >
                                <li key={item.key} className={styles.settingItem}>
                                    <div className={classNames(styles.leftBox)}>
                                        <img src={clear} alt="" className={styles['clear-img']}/>
                                        <span className={styles.settingName}
                                              title={intl.formatMessage({id: item.name})}>
                      <FormattedMessage id={item.name}/>
                    </span>
                                    </div>
                                </li>
                            </Popconfirm>
                        ) : (
                            <li key={item.key} className={styles.settingItem}>
                                <div
                                    className={classNames({[styles.themeSettingBox]: item.key === 'systemTheme'}, styles.leftBox)}
                                    onClick={() => {
                                        if (item.path) {
                                            navigate(item.path);
                                            onClose();
                                        }
                                    }}
                                >
                                    <PuiIconFont type={item.icon} className={styles.settingIcon}/>
                                    <span className={styles.settingName} title={intl.formatMessage({id: item.name})}>
                    <FormattedMessage id={item.name}/>
                  </span>
                                    {item.key === 'ThemeColor' && validTheme && (
                                        <Popover
                                            overlayStyle={{width: '200px'}}
                                            placement="top"
                                            content={`因管理员开启全局主题设置，主题暂时不可调整，有效期至${validTheme?.endTs?.slice(0, 10)}`}
                                        >
                                            <InfoCircleOutlined className={styles['warning-img']}/>
                                        </Popover>
                                    )}
                                </div>
                                {item.select && getSettingSelect(item)}
                            </li>
                        );
                    })}
                </ul>
            );
        };

        // 成功massage提示
        const [messageApi] = message.useMessage();
        // 清除浏览器缓存
        const confirm = () => {
            idbClear().then(() => {
                setRecentUseList([]);
                messageApi.open({
                    type: 'success',
                    content: intl.formatMessage({id: 'clearingCacheSucceeded'}),
                    className: styles.portalHeaderSettingMessage,
                    style: {
                        marginTop: '60vh',
                        marginRight: '120px',
                    },
                });
            });
        };

        // 保存
        const onSave = async (key: string) => {
            // 调用保存接口currentInf
            if (key) {
                let currentOrgInfo = orgInfosRef.current.find((item: IOrganizations) => {
                    return item.orgCode === key;
                });
                if (currentOrgInfo) {
                    setItem(STORAGE_KEY.ORG_INFO, JSON.stringify(currentOrgInfo));
                    stores.UserStore.setOrgInfo(currentOrgInfo);
                    WujieReact.bus.$emit(MESSAGE_KEY.CHANGE_ORGINFO, {
                        orgInfo: currentOrgInfo,
                    });
                    message.success('保存成功');
                }
            } else {
                message.error('保存失败');
            }
        };

        return (
            <div className={styles.portalHeaderWrapOperateUserInfo}>
                <div className={styles.portalHeaderWrapOperateUser} onClick={(e) => setUserSystemDrawerOpen(true)}>
                    <div className={styles.portalHeaderWrapOperateUserAvator}>
                        <div className={styles.portalUserHeardPic}>{userCaption?.substr(-2)}</div>
                        {/* <img src={userAvator} alt=""/> */}
                    </div>
                    <div className={styles.portalUserContainer}>
                        <div className={styles.portalUserBlock}>
                            <div className={styles.portalUserName}>{userCaption}</div>
                            <div className={styles.portalUserDropdownIcon}>
                                <PuiIconFont type="icon-daohanglan-xiala"/>
                            </div>
                        </div>
                    </div>
                </div>
                <Drawer
                    width="3.5rem"
                    open={userSystemDrawerOpen}
                    onClose={onClose}
                    closable={false}
                    className={styles.userDrawer}
                    style={{
                        top: '44px',
                    }}
                >
                    <div className={styles.userDrawerWrapper}>
                        <div className={styles.fixedBox}>
                            <div className={styles.logOut}>
                                <div className={styles.logOutClick} onClick={loginOut}>
                                    <PuiIconFont type="icon-tuichudenglu" style={{marginRight: '0.04rem'}}/>
                                    <FormattedMessage id="exitAccount"/>
                                </div>
                            </div>
                            <div className={styles.userBox}>
                                <div className={styles.imgBox}>
                                    <div className={styles.portalUserHeardPic}>{userCaption?.substr(-2)}</div>
                                    {/* <img src={userAvator} alt=""/> */}
                                </div>
                                <div className={styles.PuiIconFont}>
                                    <div className={styles.name}>{userCaption}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.scrollBox}>
                            <div className={classNames(styles.unitBox)}>
                                <div className={styles.unit}>
                                    <PuiIconFont type="icon-zuzhijigou"/>
                                    <SelectWrapper
                                        value={currentInfo}
                                        options={orgOptions}
                                        ref={unitOpen}
                                        onChange={(key) => {
                                            setCurrentInfo(key);
                                            onSave(key);
                                        }}
                                    />
                                </div>
                            </div>
                            {/* 账号设置 */}
                            <div
                                className={classNames(styles.userSettingBox, styles.settingBox, styles.whiteBox, styles.themeColor)}>
                                <div className={styles.title}>
                                    <FormattedMessage id="accountSetting"/>
                                </div>
                                {getSettingItem(accountSettingList, '/userAccount', intl.formatMessage({id: 'accountSetting'}))}
                            </div>
                            {/* 系统设置 */}
                            <div
                                className={classNames(styles.userSettingBox, styles.settingBox, styles.whiteBox, styles.themeColor)}>
                                <div className={styles.title}>
                                    <FormattedMessage id="systemSetting"/>
                                </div>
                                {getSettingItem(systemSettingListRef.current, '', '')}
                            </div>
                            {/* 自定义首页看板 */}
                            <div
                                className={classNames(styles.userSettingBox, styles.settingBox, styles.whiteBox, styles.settingCustom)}
                                onClick={() => {
                                    navigate('/customDashboard');
                                }}
                            >
                                <div className={styles['custom-icon']}>
                                    <PuiIconFont type="icon-ic_yjml_peizhiguanli"/>
                                </div>
                                <span>
                  <FormattedMessage id="customizeHomePageKanBan"/>
                </span>
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }),
);
export default IndexPage;
