/*
 * @Author: jld
 * @Date: 2023-02-15 16:08:15
 * @LastEditTime: 2023-03-13 20:28:28
 * @LastEditors: ZY
 * @Description:
 * @FilePath: /erp-finance-biz-portal-react-start/src/components/routerContainer/modal/WindowModal/index.tsx
 */
import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import ReactComp from '../../ReactComp/index';
import IFrame from '../../IFrame/index';
import MicroApp from '../../Wujie/index';
import FormWindow from '../../../formwindow/index';
import { LOAD_TYPE } from 'src/constant/menu';
import { uuid } from 'src/utils/uuid';
import { MenuItem } from 'src/types';

function ModalForm(props: { menuItem: MenuItem; prevProps: any }) {
  const { menuItem, prevProps } = props;
  const [visible, setVisible] = useState(true);

  const hideModal = () => {
    const closeWindow = menuItem?.config.closeWindowFnc;
    closeWindow?.call(menuItem?.config.closeParams);
    setVisible(false);
  };

  const getContent = () => {
    const type = menuItem?.config?.loadType;
    switch (type) {
      case LOAD_TYPE.Micro_APP:
        return <MicroApp menuItem={menuItem} />;
      case LOAD_TYPE.REACT_CODE:
        return <ReactComp menuItem={menuItem} />;
      case LOAD_TYPE.IFRAME:
        return <IFrame menuItem={menuItem} />;
    }
  };

  return (
    <div>
      {visible ? (
        <FormWindow
          title={menuItem.name}
          visible={visible}
          maskClosable={false}
          width={
            parseInt(menuItem?.config?.windowWidth, 10) ||
            (menuItem.config.placement === 'bottom' ? prevProps.AppState.contentWidth : 1100)
          }
          onCancel={hideModal}
          onOk={hideModal}
          footer={null}
          height={
            menuItem?.config?.windowHeight ||
            (menuItem.config.placement === 'bottom' ? prevProps.AppState.contentHeight : 600)
          }
          theme={menuItem.config.theme}
          bodyNoPadding={menuItem.config.bodyNoPadding}
          placement={menuItem.config.placement}
          showFullscreen={menuItem.config.showFullscreen}
        >
          {getContent()}
        </FormWindow>
      ) : null}
    </div>
  );
}

class WindowClass {
  public guid: string;
  public constructor() {
    this.guid = '';
  }
  public open = (item: MenuItem, props: any) => {
    this.guid = uuid();
    const div = document.createElement('div');
    div.setAttribute('id', this.guid);
    document.body.appendChild(div);
    ReactDOM.render(React.createElement(ModalForm, { menuItem: item, prevProps: props }), div);
  };
}
const WindowModal = new WindowClass();
export default WindowModal;
