/*
 * @Author: yar
 * @Date: 2022-10-26 11:03:00
 * @LastEditTime: 2023-03-10 09:31:52
 * @LastEditors: jld
 * @Description: 标题栏+面包屑
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\UserLogin\components\TabsCard\index.tsx
 */
import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './index.module.less';

const HeaderTitle: React.FC<{
  nameArr: any[];
  defaultCode: string; // 默认选中
  extraRightContent?: any; // 底部内容
  onClickFuc: (item: string) => void;
}> = (props) => {
  const { nameArr, defaultCode, extraRightContent, onClickFuc } = props;

  return (
    <div className={styles.tabsBox}>
      <div className={styles.top}>
        {nameArr?.length &&
          nameArr.map((item: any) => {
            return (
              <div
                className={`${styles.tabItem} ${defaultCode === item.code && styles.active}`}
                key={item.code}
                onClick={() => {
                  onClickFuc(item.code);
                }}
              >
                <FormattedMessage id={item.id} />
              </div>
            );
          })}
      </div>
      <div className={styles.bottom}>{extraRightContent ?? ''}</div>
    </div>
  );
};

export default HeaderTitle;
