/*
 * @Author: jld
 * @Date: 2023-08-24 13:44:45
 * @LastEditTime: 2023-12-25 17:19:26
 * @LastEditors: lmy
 * @Description:
 * @FilePath: \portal-react-start\src\api\download.ts
 */

import request from 'src/utils/request';
import { IDeleteDownLoad, IDownLoad, IDownLoadReturnList } from './types/download';

// 查询任务列表
export const downLoadCenterList = (data: { keyword?: string }) => {
  return request<IDownLoadReturnList[]>({
    url: '/portal/downLoadCenter/list',
    method: 'post',
    data,
  });
};

// 文件下载
export const downLoad = (data: IDownLoad) => {
  return request({
    url: '/portal/downLoadCenter/downLoad',
    method: 'post',
    data,
    responseType: 'blob',
  });
};

// 任务删除
export const downLoadDelete = (data: IDeleteDownLoad) => {
  return request({
    url: '/portal/downLoadCenter/delete',
    method: 'post',
    data,
  });
};

// 任务重试
export const downLoadRetry = (data: IDownLoad) => {
  return request({
    url: '/portal/downLoadCenter/retry',
    method: 'post',
    data,
  });
};
