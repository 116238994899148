/*
 * @Author: ww
 * @Date: 2024-02-26 11:26:08
 * @LastEditTime: 2024-02-26 11:26:08
 * @LastEditors: ww
 * @Description: 主题设置
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\ThemeSetting\index.tsx
 */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';
import { Button, message } from 'antd';
import { getBrowserAndValidGlobalTheme } from 'src/api/systemSetting';
import { APP_SETTINGS_LOW_VERSION_BROWSER, SYSTEM_SETTING } from './model';

const IndexPage: React.FC<any> = inject(
  'UserStore',
  'AppState',
)(
  observer((props) => {
    const {
      AppState: { themeToken, setThemeToken },
    } = props;

    const [browserInfo, setBrowserInfo] = useState<any>();

    const getBrowserConfig = async () => {
      const result = await getBrowserAndValidGlobalTheme();
      setBrowserInfo(result[SYSTEM_SETTING.BROWSER_DOWN_PATH].jsonValue);
    };

    useEffect(() => {
      getBrowserConfig();
    }, []);

    return (
      <div className={styles.lowBrowserContainer}>
        <div className={styles.lowBrowserLeft}>
          <div className={styles.rocketImage} />
        </div>
        <div className={styles.lowBrowserRight}>
          <div className={styles.browserContainer}>
            <div className={styles.browserTitle}>您的Internet Explorer浏览器太旧啦！</div>
            <div className={styles.browserSecondTitle}>为了获得最佳体验,我们建议您使用以下推荐浏览器访问：</div>
            <div className={styles.browserList}>
              {APP_SETTINGS_LOW_VERSION_BROWSER.LOW_VERSION_BROWSER_LIST.map((item: any) => (
                <div className={styles.browserItem} key={item.browserId}>
                  <img src={item.icon} alt={item.browserId} className={styles.browserIcon} />
                  <div className={styles.browserItemTitle}>{`${item.browserName}浏览器>${item.version}`}</div>
                  <Button
                    className={styles.browserItemBtn}
                    onClick={() => {
                      if (browserInfo[item.browserId]) {
                        window.open(browserInfo[item.browserId]);
                      } else {
                        message.error('请联系管理员维护下载地址');
                      }
                    }}
                  >
                    下载
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }),
);

export default IndexPage;
