/*
 * @Author: ZLL
 * @Date: 2023-02-15 17:41:06
 * @LastEditors: lmy
 * @LastEditTime: 2023-08-02 10:45:59
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\routerContainer\IFrame\index.tsx
 */
import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.less';
import Loading from 'src/components/Loading';
import { MenuItem as IMenuItem } from '../../../types';
import { addEventListener, sendMessage } from './postMessage';
import { MESSAGE_KEY } from 'src/constant/messageKey';
import {changeThemeToken} from "src/utils/theme";
import stores from 'src/stores';

const Iframe: React.FC<{ menuItem: IMenuItem }> = (props) => {
  let { AppState } = stores;
  const { menuItem } = props;
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(menuItem.config.url);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setLoading(true);
    getUrl(menuItem);
  }, [menuItem]);

  const getUrl = (menuItem: IMenuItem) => {
    if (menuItem?.config) {
      setUrl(menuItem.config.url);
      setLoading(false);
    }
  };

  addEventListener((messageKey) => {
    if (messageKey === MESSAGE_KEY.PAGE_LOADED) {
      setLoading(false);
    }
  });

  return (
    <div className={styles.iframeContainer} style={{ height: '100%' }}>
      <Loading spinning={loading}>
        <iframe
          className={`${styles.iframe} iframePortalContainer`}
          src={url}
          id='iframePortalContainer'
          ref={iframeRef}
          onLoad={() => {
            sendMessage(iframeRef.current);
            changeThemeToken(AppState.themeToken)
          }}
        />
      </Loading>
    </div>
  );
};
export default Iframe;
