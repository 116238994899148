/*
 * @Author: CZJ
 * @Date: 2023-02-15 20:25:08
 * @LastEditors: jld
 * @LastEditTime: 2023-03-27 11:32:55
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\microApp.ts
 * @Description: 公告列表
 */
import request from '../utils/request';

// 获取公告列表接口
export const selAnnouncementByNO = (data: { pageSize: string; ascOrder: string; descOrder: string }) => {
  return request<any[]>({
    url: `/ys-core/Announcement/selAnnouncementByNO?pageSize=${data.pageSize}&ascOrder=${data.ascOrder}&descOrder=${data.descOrder}`,
    method: 'get',
  });
};
