/*
 * @Author: yar
 * @Date: 2023-02-07 11:13:19
 * @LastEditTime: 2023-03-10 15:28:06
 * @LastEditors: jld
 * @Description: 历史访问记录面板
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\GlobalSearch\components\SearchHistory\index.tsx
 */
import React, { useEffect, useState } from 'react';
import { message, Popconfirm } from 'antd';
import styles from './index.module.less';
import IconFont from 'src/components/IconFont';
import { get, del, clear } from 'idb-keyval';
import indexedDBkey from '../SearchPanel/utils/constant';
import BoxEllipsisTooltip from 'src/components/BoxEllipsis';
import { divide } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';
const IndexPage: React.FC<{
  historyData?: any[]; // 历史数据
  dataSource: any[]; // 接口数据源
  highLightWords?: string; // 需要高亮的关键词
  style?: any; // 自定义样式
  onSelected: Function;
  setOptionsData: Function;
  showHisOrReal: string; // 展示历史关键词还是实时输入词
  guessData: string[];
}> = (props) => {
  const { historyData, dataSource, highLightWords, style, onSelected, setOptionsData, showHisOrReal, guessData } =
    props;

  const indexedDBRecommendDataKey = `${indexedDBkey.hisId}-recommend-data`;
  const [showHistoryData, setShowHistoryData] = useState<string[]>([]);
  const intl = useIntl();
  useEffect(() => {
    if (showHisOrReal === 'history') {
      if (historyData) {
        setShowHistoryData([...historyData]);
        setOptionsData([...historyData]);
      }
    } else {
      let realData: any = [];
      guessData?.length &&
        guessData.forEach((item: string) => {
          realData.push(item);
        });
      setShowHistoryData([...realData]);
      setOptionsData([...realData]);
    }
  }, [historyData, showHisOrReal]);
  /**
   * 清空历史记录
   * @param e
   */
  const confirm = (e: any) => {
    get(`${indexedDBRecommendDataKey}`).then(async (res) => {
      if (res?.length) {
        del(`${indexedDBRecommendDataKey}`)
          .then(() => {
            setShowHistoryData([]);
            // setOptionsData([]);
            message.success(intl.formatMessage({ id: 'historyCleared' }));
          })
          .catch((err) => console.log('It failed!', err));
      }
    });
  };
  const cancel = (e: any) => {
    // console.log(e);
  };
  return (
    <div className={styles.historyNominate} style={style}>
      <div className={styles.history}>
        <div className={styles.row}>
          <div className={styles.title}>
            {showHisOrReal === 'history'
              ? intl.formatMessage({ id: 'recentSearch' })
              : intl.formatMessage({ id: 'guessSearch' })}
          </div>
          <div className={styles.deleteBlock}>
            <Popconfirm
              getPopupContainer={(trigger: any) => trigger.parentNode}
              title={intl.formatMessage({ id: 'clearHistory' })}
              placement="left"
              onConfirm={confirm}
              onCancel={cancel}
              okText={intl.formatMessage({ id: 'determine' })}
              cancelText={intl.formatMessage({ id: 'close' })}
            >
              {showHistoryData?.length && showHisOrReal === 'history' ? (
                <IconFont type="icon-shanchu1" className={styles.arrowIcon} />
              ) : (
                ''
              )}
            </Popconfirm>
          </div>
        </div>
        <div className={styles.historyContent}>
          {showHistoryData?.length ? (
            showHistoryData.slice(0, 8).map((item: string, index: number) => (
              <div
                key={item}
                onClick={() => {
                  onSelected([item], [item]);
                }}
                className={`${styles.historyContentBox}`}
              >
                <BoxEllipsisTooltip text={item} />
              </div>
            ))
          ) : (
            <div className={styles.notFoundKeyWord}>
              <IconFont type="icon-wuneirong1" className={styles.notIcon} />
              <div className={styles.notFoundLabel}>
                <FormattedMessage id="notFound" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
