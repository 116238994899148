/*
 * @Author: ZY
 * @Date: 2023-02-20 11:19:37
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @LastEditTime: 2024-03-08 15:10:48
 * @FilePath: /portal-react-start/src/routes/layoutRouteMap.tsx
 * @Description: Layout里的路由都是由此文件加载
 */
import loadable from '@loadable/component';

const Dashboard: any = loadable(() => import('src/pages/DashBoard'));
const IFrame: any = loadable(() => import('src/components/routerContainer/IFrame/index'));
const ReactComp: any = loadable(() => import('src/components/routerContainer/ReactComp/index'));
const WujieApp: any = loadable(() => import('src/components/routerContainer/Wujie/index'));
const MyForm: any = loadable(() => import('src/pages/MyForm'));
const UserAccount: any = loadable(() => import('src/pages/UserAccount'));
const CustomThemeStyle: any = loadable(() => import('src/pages/CustomThemeStyle'));
const ThemeSetting: any = loadable(() => import('src/pages/ThemeSetting'));
const SystemSetting: any = loadable(() => import('src/pages/SystemSetting'));
const SystemConfig: any = loadable(() => import('src/pages/SystemConfig'));
const LowBrowser: any = loadable(() => import('src/pages/LowBrowser'));
const GlobalSearch: any = loadable(() => import('src/pages/GlobalSearch'));
const ApplicationConfig: any = loadable(() => import('src/pages/ApplicationConfig/index'));
const HelpStart: any = loadable(() => import('src/pages/HelpStart/index'));
const MenuMaintain: any = loadable(() => import('src/pages/MenuMaintain'));
const MessageList: any = loadable(() => import('src/pages/MessageList'));
const SearchDetails: any = loadable(() => import('src/layout/components/Header/components/SearchDetails'));
const DownLoadCenter: any = loadable(() => import('src/pages/DownLoadCenter/index'));

const layoutChildrenRoutes = [
  { name: '首页', path: '/dashboard', tabPath: '/dashboard', component: Dashboard },
  { name: '我的表单', path: '/myform', tabPath: '/myform', component: MyForm },
  { path: '/iframe/:id', tabPath: '/iframe/', component: IFrame },
  { path: '/component/:id', tabPath: '/component/', component: ReactComp },
  { path: '/microApp/:id', tabPath: '/microApp/', component: WujieApp },
  { name: '账号设置', path: '/userAccount', tabPath: '/userAccount', component: UserAccount },
  { name: '系统配置', path: '/systemConfig', tabPath: '/systemConfig', component: SystemConfig },
  { name: '自定义主题样式', path: '/customThemeStyle', tabPath: '/customThemeStyle', component: CustomThemeStyle },
  { name: '主题设置', path: '/themeSetting', tabPath: '/themeSetting', component: ThemeSetting },
  { name: '系统设置', path: '/systemSetting', tabPath: '/systemSetting', component: SystemSetting },
  { name: '浏览器版本低', path: '/lowBrowser', tabPath: '/lowBrowser', component: LowBrowser },
  { path: '/globalSearchFuc', tabPath: '/globalSearchFuc', component: GlobalSearch },
  { name: '微前端配置', path: '/applicationConfig', tabPath: '/applicationConfig', component: ApplicationConfig },
  { name: '帮助中心', path: '/helpStart', tabPath: '/helpStart', component: HelpStart },
  { name: '菜单维护', path: '/menuMaintain', tabPath: '/menuMaintain', component: MenuMaintain },
  { name: '消息中心', path: '/messageList', tabPath: '/messageList', component: MessageList },
  { name: '全局搜索', path: '/searchDetails', tabPath: '/searchDetails', component: SearchDetails },
  { name: '下载中心', path: '/downLoadCenter', tabPath: '/downLoadCenter', component: DownLoadCenter },
];

/**
 * @description: 判断一个路径是否是路由区路由，如果是，是菜单路由组件还是本地路由组件。
 * @param {string} path 路径
 * @return   none ： 没有找到  menu:菜单路由组件   local：本地组件
 */
export const getPathType = (path: string) => {
  const paths = layoutChildrenRoutes.map((item) => item.path);
  if (path.startsWith('/iframe') || path.startsWith('/component') || path.startsWith('/microApp')) {
    return 'menu';
  } else {
    if (paths.indexOf(path) === -1) {
      return 'none';
    } else {
      return 'local';
    }
  }
};

export default layoutChildrenRoutes;
