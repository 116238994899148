/*
 * @Author: jld
 * @Date: 2023-02-16 18:57:54
 * @LastEditTime: 2023-12-22 17:09:21
 * @LastEditors: lmy
 * @Description:
 * @FilePath: \portal-react-start\src\stores\components\DashboardStore.tsx
 */
import { IDashboard } from 'src/api/types/dashboard';
import { action, observable } from 'mobx';

class DashboardStore {
  @observable public dashboardList: IDashboard[] = [];
  @observable public taskTotalNumber = 0;

  @action.bound
  public setDashboardList(state: IDashboard[]) {
    this.dashboardList = state;
  }
  @action.bound
  public setTaskTotalNumber(num: number) {
    this.taskTotalNumber = num;
  }
}

const dashboardStore = new DashboardStore();
export default dashboardStore;
