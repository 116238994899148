/*
 * @Author: ZLL
 * @Date: 2023-02-09 11:15:14
 * @LastEditors: yxp
 * @LastEditTime: 2023-07-24 17:07:48
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\components\FunctionMenuNailing\index.tsx
 * @Description: 我的收藏菜单
 */

import React, { useState, useRef, useEffect } from 'react';
import FunctionMenu from '../FunctionMenu';
import { inject, observer } from 'mobx-react';
import type { MenuProps } from 'antd/es/menu';
import styles from './index.module.less';
import { Drawer, Tooltip } from 'antd';
import IconFont from 'src/components/IconFont';
import { useLocation, useNavigate } from 'react-router-dom';
import ExhibitionItem, { ItemType } from 'src/components/ExhibitionItem';
import { getMenuItemInMenuListByProperty } from 'src/utils/menu';
import { MenuItem as IMenuItem } from 'src/types';
import { FormattedMessage } from 'react-intl';
import stores from 'src/stores';
import { debounce, isEmpty } from 'lodash-es';
import { MESSAGE_KEY } from 'src/constant';
import WujieReact from 'wujie-react';
const { bus } = WujieReact;

const FunctionMenuNailing: React.FC<{
  allFunctionsBtnClick: () => void;
  collapse?: boolean;
  toTop: boolean;
  setToTop: Function;
  [key: string]: any;
}> = inject(
  'AppState',
  'TagsViewStore',
  'DrawerStore',
)(
  observer((props) => {
    const {
      allFunctionsBtnClick,
      collapse = false,
      toTop,
      setToTop,
      DrawerStore: { drawerOpen, setDrawerOpen },
      AppState: { collectionMenuList, headerNavActive },
    } = props;

    let navigate = useNavigate();
    const location = useLocation();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]); // 左侧菜单的数据
    const [activeMenuItem, setActiveMenuItem] = useState<string>('');
    type MenuItem = Required<MenuProps>['items'][number];
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [tooltipTitle, setTooltipTitle] = useState<string>();
    const [onMouseFlag, setOnMouseFlag] = useState<boolean>(false);

    useEffect(() => {
      if (collectionMenuList) {
        getMenuItemsData();
      }
    }, [collectionMenuList]);

    useEffect(() => {
      setOnMouseFlag(false);
      let currentMenuItem: IMenuItem = collectionMenuList.find((menu: IMenuItem) => {
        return location.pathname === '/' + menu.path;
      });
      setActiveMenuItem(currentMenuItem?.id);
    }, [drawerOpen]);

    // 顶部菜单当前活动项改变，关闭收藏浮框
    useEffect(() => {
      setOnMouseFlag(false);
    }, [headerNavActive]);

    /**
     * @description: 设置左侧菜单数据、默认选中的菜单
     * @return {*}
     */
    const getMenuItemsData = () => {
      const menuItems: MenuItem[] = [];
      collectionMenuList?.forEach((item: IMenuItem) => {
        menuItems.push({
          label: <ExhibitionItem type={ItemType.collectionMenu} item={item} />,
          key: item.id,
          icon: item.icon ? (
            <img src={`/portal/images/isLeafMenuIcon/${item.icon}.svg`} className={styles.iconSize} />
          ) : (
            <span className={styles.iconSize} />
          ),
        });
      });
      setMenuItems(menuItems);
      if (menuItems?.length > 0) {
        setActiveMenuItem(menuItems[0]?.key as string);
      }
    };

    const collapseStyle = {
      opacity: 0,
      transition: 'all 300ms cubic-bezier(0.645, 0.045, 0.355, 1)',
    };

    const notCollapseStyle = {
      opacity: 1,
      transition: 'all 300ms cubic-bezier(0.645, 0.045, 0.355, 1)',
    };

    /**
     * @description: 左侧菜单点击事件
     * @param {*} e
     * @return {*}
     */
    const onClick: MenuProps['onClick'] = (e) => {
      const item = getMenuItemInMenuListByProperty(collectionMenuList, 'id', e.key);
      setActiveMenuItem(e.key);
      setOnMouseFlag(false);
      if (item) {
        if (stores.DrawerStore.drawerOpenStatus === 'open') {
          stores.DrawerStore.setDrawerOpen(false);
        }
        navigate(item.path);
      }
    };
    useEffect(() => {
      setTooltipTitle(toTop ? '取消置顶' : '将“我的收藏”固定于界面左侧常驻显示');
    }, []);

    const onMouseEnter = () => {
      if (collapse && !onMouseFlag) {
        setOnMouseFlag(true);
      }
    };

    const onClose = () => {
      setOnMouseFlag(false);
    };
    const getDom = (inlineCollapsed: boolean) => {
      return (
        <>
          <div className={styles.allFunctions} onClick={() => allFunctionsBtnClick()}>
            <IconFont type="icon-gengduo" className={styles.allFunctionsIcon} />
            <span style={collapse ? { ...collapseStyle } : { ...notCollapseStyle }}>
              <FormattedMessage id="allFunctions" />
              <IconFont type="icon-yeqianlan-xiangyou" className={styles.allFunRight} />
            </span>
          </div>
          <div className={styles.line} />
          <div className={styles.myCollection}>
            <IconFont type="icon-wodeshoucang1" className={styles.myCollectionIcon} />
            <div className={styles.collectRight} style={collapse ? collapseStyle : notCollapseStyle}>
              <span className={styles.myCollectionTitle}>
                <FormattedMessage id="myCollection" />
              </span>
            </div>
          </div>
          <FunctionMenu
            onClick={onClick}
            style={
              toTop && !drawerOpen && !inlineCollapsed
                ? { height: 'calc(100% - 175px)', ...notCollapseStyle }
                : toTop && !drawerOpen && inlineCollapsed
                ? { height: 'calc(100% - 175px)', ...collapseStyle }
                : {}
            }
            defaultOpenKeys={['sub1']}
            inlineCollapsed={inlineCollapsed}
            mode="inline"
            items={menuItems}
            selectedKeys={[activeMenuItem]}
            onMouseEnter={onMouseEnter}
          />
          <div className={styles.nailPadding}>
            <div
              className={styles.nail}
              onMouseEnter={() => {
                // 未点击才可触发onMouseEnter
                setTooltipTitle(toTop ? '取消固定' : '固定导航');
                setTooltipOpen(true);
              }}
              onMouseLeave={() => {
                setTooltipOpen(false);
              }}
            >
              <Tooltip
                overlayClassName={styles.toTopTooltip}
                placement="right"
                title={tooltipTitle}
                color="rgba(0,0,0,0.65)"
                open={tooltipOpen}
              >
                <IconFont
                  type={toTop ? 'icon-gudingshoucang-xuanzhong' : 'icon-gudingshoucang-weidianji'}
                  className={styles.myTopIcon}
                  onClick={() => {
                    setTooltipOpen(false);
                    setToTop(!toTop);
                    bus.$emit(MESSAGE_KEY.FIXED_CHANGE, {
                      isFixed: !toTop,
                    });
                    if (!toTop) {
                      setDrawerOpen(false);
                    }
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </>
      );
    };
    return (
      <div className={styles.menuBox}>
        {/* {collapse && (
          <Drawer
            placement="left"
            open={onMouseFlag}
            forceRender={true}
            maskClosable={true}
            onClose={onClose}
            maskStyle={{ background: 'transparent' }}
            mask={true}
            getContainer={false}
            closable={false}
            className={styles.leftMenu}
            width={240}
          >
            {getDom(false)}
          </Drawer>
        )} */}

        {/* (!collapse || (collapse && !onMouseFlag)) &&  */ getDom(collapse)}
      </div>
    );
  }),
);

export default FunctionMenuNailing;
