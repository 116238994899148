/*
 * @Author: jld
 * @Date: 2023-02-06 20:25:56
 * @LastEditTime: 2023-03-01 09:53:44
 * @LastEditors: jld
 * @Description:自定义模板首页左侧菜单
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\CustomDashboard\components\DragDropMenu\index.tsx
 */
import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import styles from './index.module.less';
import IconFont from 'src/components/IconFont';

const DragDropMenuList: React.FC<{
  onClick: (item: any) => void; // 左侧菜单点击事件
  onDropFn: (changeMenuInfo: DataNode[]) => void; // 左侧菜单移动事件
  menuInfo: DataNode[];
  selectedKeys: string | number;
}> = (props) => {
  const { menuInfo, onClick, onDropFn, selectedKeys } = props;

  const [gData, setGData] = useState<DataNode[] | any>();

  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string | number>(selectedKeys);
  useEffect(() => {
    setDefaultSelectedKeys(selectedKeys);
    let _gData = menuInfo.map((item: any) => {
      let _icon = item?.icon?.props?.type ?? item.icon;
      item.icon = <IconFont type={_icon ?? ' '} />;
      return item;
    });
    setGData(_gData);
  }, [menuInfo, selectedKeys]);

  const onDrop: TreeProps['onDrop'] = (info) => {
    //  node，当前被drop对象 当前需要drop 的对象
    const dropKey = info.node.key; // 拖到哪里
    const dragKey = info.dragNode.key; // 拖动的对象
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
      }
    };
    const data = [...gData]; // 菜单数据
    let dragObj: DataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    let ar: DataNode[] = [];
    let i: number;
    loop(data, dropKey, (_item, index, arr) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      ar.splice(i!, 0, dragObj!);
    } else {
      ar.splice(i! + 1, 0, dragObj!);
    }
    onDropFn(data);
    setGData(data);
  };

  return (
    <div className={styles.content}>
      {defaultSelectedKeys && gData && (
        <Tree
          className="draggable-tree"
          showIcon
          draggable={true}
          blockNode
          onDrop={onDrop}
          treeData={gData}
          selectedKeys={[defaultSelectedKeys]}
          onSelect={onClick}
        />
      )}
    </div>
  );
};

export default DragDropMenuList;
