/*
 * @Author: ZLL
 * @Date: 2023-02-06 13:37:13
 * @LastEditors: jld
 * @LastEditTime: 2023-03-16 15:49:40
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\components\FunctionalTile\index.tsx
 * @Description: 所有功能-分级
 */
import React, { useEffect, useRef, useState } from 'react';
import { Anchor } from 'antd';
import styles from './index.module.less';
import { AllDataModel } from './mockData';
import ExhibitionItem, { ItemType } from 'src/components/ExhibitionItem';
import { MenuItem } from 'src/types';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { getMenuItemInMenuListByProperty, getMenuListAndCollectionList } from 'src/utils/menu';
import { useLocation } from 'react-router-dom';

const FunctionalTile: React.FC<{ [key: string]: any }> = inject(
  'AppState',
  'TagsViewStore',
  'UserStore',
  'DrawerStore',
)(
  observer((props) => {
    const {
      AppState: { leftMenuList },
      DrawerStore: { showFunDrawer, drawerOpen },
    } = props;
    const [listData, setListData] = useState<AllDataModel[]>([]); // 平铺的数据
    const [anchorData, setAnchorData] = useState<AnchorLinkItemProps[]>([]); // 锚点的数据
    const [manyAnchorClass, setManyAnchorClass] = useState(''); // 锚点数据多有滚动条的样式
    const leftListRef = useRef<HTMLDivElement>(null);
    const anchorRef = useRef<HTMLDivElement>(null);
    const intervalRef = useRef<any>();
    const [activeAnchor, setActiveAnchor] = useState('');
    const [activityKey, setActiveMenuKey] = useState<string>('');
    const location = useLocation();

    useEffect(() => {
      let data = [];
      for (let i = 0; i < 26; i++) {
        data.push({
          name: String.fromCharCode(65 + i),
          key: String.fromCharCode(65 + i),
          children: [],
        });
      }
      let filterData: AllDataModel[] = [];
      let anchorData: AnchorLinkItemProps[] = [];
      data.forEach((item) => {
        getInitialWordList(leftMenuList, item.key, item.children);
        if (item.children.length) {
          filterData.push(item);
          anchorData.push({
            key: item.key,
            href: `#${item.key}`,
            title: item.name,
          });
        }
      });
      setAnchorData(anchorData);
      setListData(filterData);

      // 有滚动条的时候样式居中会有问题，出现滚动条去掉了居中
      if (anchorRef.current && filterData.length * 24 > anchorRef.current.scrollHeight) {
        setManyAnchorClass(styles.manyAnchorClass);
      }
      leftListRef.current?.addEventListener('scroll', debounceAnchorScroll);
      return () => {
        clearInterval(intervalRef.current);
        removeEventListener('scroll', debounceAnchorScroll);
      };
    }, [leftMenuList]);

    useEffect(() => {
      let currentMenuItem = getMenuItemInMenuListByProperty(leftMenuList, 'path', location.pathname.slice(1));
      if (currentMenuItem) {
        setActiveMenuKey(currentMenuItem?.id);
      }
    }, [showFunDrawer, drawerOpen]);
    /**
     * @description: 监听控制右侧滚动条滑动
     * @return {*}
     */
    const anchorScroll = () => {
      let anchorBoxDom = document.getElementsByClassName('anchorBox')[0].getElementsByClassName('ant-anchor');
      // 如果左侧滚动的距离大于左侧的高度就让右侧锚点盒子滚动到最底部
      if (leftListRef.current && leftListRef.current?.scrollTop > leftListRef.current?.offsetHeight) {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
          if (leftListRef.current && anchorBoxDom[0].scrollTop < leftListRef.current?.scrollHeight) {
            anchorBoxDom[0].scrollTop = anchorBoxDom[0].scrollTop + 1;
          }
        }, 5);
      } else if (leftListRef.current && leftListRef.current?.scrollTop < leftListRef.current?.offsetHeight) {
        // 如果左侧滚动的距离小于左侧的高度就让右侧锚点盒子滚动到最上面
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
          if (leftListRef.current && anchorBoxDom[0].scrollTop !== 0) {
            anchorBoxDom[0].scrollTop = anchorBoxDom[0].scrollTop - 1;
          }
        }, 5);
      }
    };

    /**
     * @description: 防抖版anchorScroll
     * @return {*}
     */
    const debounceAnchorScroll = debounce(anchorScroll, 200);

    const getInitialWordList = (list: MenuItem[], initialWord: string, arr: MenuItem[]) => {
      list?.forEach((item: MenuItem) => {
        if (item.children && item.children.length > 0) {
          getInitialWordList(item.children, initialWord, arr);
        } else {
          if (item.initialWord === initialWord) {
            arr.push(item);
          }
        }
      });
    };
    const getCurrentAnchor = () => {
      return activeAnchor;
    };
    return (
      <div className={styles.functionalTileBox}>
        <div className={styles.leftListBox}>
          <div className={styles.leftList} ref={leftListRef}>
            {listData?.map((item: AllDataModel) => {
              return (
                <div className={styles.leftListItem} key={item.key} id={item.key}>
                  <div className={`${styles.topTitle} ${activeAnchor === `#${item.key}` ? styles.activeTitle : ''}`}>
                    {item.name}
                  </div>
                  <div className={styles.listItemContent}>
                    {item?.children?.map((childrenItem: MenuItem) => {
                      return (
                        <ExhibitionItem
                          item={childrenItem}
                          type={ItemType.tileItem}
                          key={childrenItem.id}
                          rightActiveMenuKey={activityKey}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={`${styles.rightAnchor} ${manyAnchorClass}`} ref={anchorRef}>
          <Anchor
            onClick={(e, link) => {
              e.preventDefault();
              setActiveAnchor(link.href);
            }}
            affix={false}
            showInkInFixed={true}
            getCurrentAnchor={getCurrentAnchor}
            getContainer={() => leftListRef?.current as HTMLDivElement}
            className="anchorBox"
            items={anchorData}
          />
        </div>
      </div>
    );
  }),
);
export default FunctionalTile;
