/*
 * @Author: jld
 * @Date: 2023-02-06 20:25:56
 * @LastEditTime: 2023-12-27 15:07:37
 * @LastEditors: YL
 * @Description:自定义模板首页右侧按钮组
 * @FilePath: /portal-react-start/src/pages/CustomDashboard/components/HeadButtonGroup/index.tsx
 */
import { Button } from 'antd';
import { DataNode } from 'antd/es/tree';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import styles from './index.module.less';
import { buttonArrayFun, buttonType } from './mockData';

export const HeadButtonGroup: React.FC<{
  deleteItem: () => void;
  finished: () => void;
  activeMenuKey: string | number;
  menuInfoNow: DataNode[] | any;
}> = (props) => {
  const { deleteItem, activeMenuKey, finished, menuInfoNow } = props;
  const [activeCode, setActiveCode] = useState<string>(); // 选中按钮code
  const navigate = useNavigate();
  const buttonArray = buttonArrayFun();
  const buttonClickFn = (buttonInfo: any) => {
    setActiveCode(buttonInfo.code);
    switch (buttonInfo.code) {
      case 'newAdd':
        navigate('/editDashboard/new');
        break;
      case 'edit':
        if (activeMenuKey) navigate(`/editDashboard/${activeMenuKey}`);
        break;
      case 'delete': // 删除
        deleteItem();
        break;
      case 'finish': // 完成
        finished();
        break;
    }
  };

  return (
    <div className={styles.buttonContainer}>
      {buttonArray.map((item: buttonType, index: number) => {
        return (
          <Button
            onClick={() => {
              buttonClickFn(item);
            }}
            type={item.code === 'newAdd' ? 'primary' : 'default'}
            className={styles.button}
            key={index}
            disabled={item.code === 'delete' && menuInfoNow?.length === 1 ? true : false}
          >
            {item.name}
          </Button>
        );
      })}
    </div>
  );
};
