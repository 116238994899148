import {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  ReactChild,
  useState,
} from 'react';
import { Modal } from 'antd';
import './index.less';
import { Rnd, Position } from 'react-rnd';
import lodash_isString from 'lodash/isString';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { uuid } from 'src/utils/uuid';

type EmptyType<T> = T | undefined | null;
type FormWindowSize = any;

export interface IFormWindow {
  title?: string;
  onOk?: () => void;
  onCancel?: () => void;
  okText?: EmptyType<string>;
  cancelText?: EmptyType<string>;
  width?: string | number;
  height?: string | number;
  wrapClassName?: EmptyType<string>;
  footer?: ReactNode;
  resizeWindow?: (height: string, width: string) => void;
  afterClose?: () => void;
  visible: boolean;
  disableResize?: boolean;
  theme?: string;
  bodyNoPadding?: EmptyType<FormWindowSize>;
  children?: ReactChild;
  placement?: string;
  showFullscreen?: boolean;
  displayType?: string;
  getContainer?: any;
}

const MIN_HEIGHT = 260;
const MIN_WIDTH = 500;

enum attrType {
  width,
  height,
}

// 父组件从ref.current中获取这些参数
const modalSizeParams = {
  header_height: 41, // header高度
  bodyPadding: {
    // body 上右下左内边距
    top: 24,
    right: 24,
    bottom: 24,
    left: 24,
  },
  footer_height: 57, // footer高度
};

const centredCompute = (value: EmptyType<FormWindowSize>, type: attrType): string => {
  if (!value) return `${value}`;
  if (lodash_isString(value) && value?.includes('%')) {
    const documentSize = type === attrType.width ? document.body.clientWidth : document.body.clientHeight;
    return `${Math.ceil((documentSize * parseInt(value, 10)) / 100)}`;
  }
  return `${value}`;
};

const FormWindow: ForwardRefRenderFunction<IFormWindow, any> = (props: IFormWindow, ref: any) => {
  const {
    title,
    onOk,
    onCancel,
    okText,
    cancelText,
    width,
    height,
    wrapClassName,
    visible,
    footer,
    resizeWindow,
    afterClose,
    disableResize = false, // 是否禁止拖拽大小 默认false
    theme, // cscec: 中建蓝
    bodyNoPadding = false, // ant-modal-body区域是否有padding，默认有padding
    placement, // 弹窗展示位置
    showFullscreen, // 是否展示全屏按钮
    displayType,
    getContainer,
    children,
  } = props;

  const rndRef: any = useRef(null);

  const modalRef: any = useRef(null);

  const id: string = uuid();

  // 是否放大状态
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const handleOK = () => {
    if (onOk) {
      onOk();
    }
  };

  useEffect(() => {
    if (!visible) {
      if (showFullscreen) setIsFullscreen(false);
      if (afterClose) {
        afterClose();
      }
    }
  }, [visible, showFullscreen, afterClose]);

  // 更新位置
  const updatePosition = (position: Position) => {
    rndRef.current.updatePosition(position);
  };

  useImperativeHandle(ref, () => ({
    ...modalSizeParams,
    updatePosition,
  }));

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  // 重新计算宽度，最大宽度等于屏幕宽度
  const computeWidth = (compWidth: EmptyType<FormWindowSize>) => {
    if (!compWidth) return null;
    const value = lodash_isString(compWidth) && compWidth?.includes('px') ? parseInt(compWidth, 10) : compWidth;
    return value > document.body.clientWidth ? `${document.body.clientWidth}px` : value;
  };
  // 重新计算高度，最大高度等于屏幕高度
  const computeHeight = (compHeight: EmptyType<FormWindowSize>) => {
    if (!compHeight) return null;
    let value = lodash_isString(compHeight) && compHeight?.includes('px') ? parseInt(compHeight, 10) : compHeight;
    return value > document.body.clientHeight ? `${document.body.clientHeight}px` : value;
  };

  // 点击放大
  const onFullscreenHandle = () => {
    if (!isFullscreen) {
      if (modalRef.current) {
        modalRef.current.requestFullscreen();
        setIsFullscreen(true);
      }
    }
  };

  // 点击取消放大
  const onFullscreenExitHandle = () => {
    document.exitFullscreen();
    setIsFullscreen(false);
  };

  // 全屏图标
  const getFullscreenDom = () => {
    if (showFullscreen) {
      if (isFullscreen) {
        return <FullscreenExitOutlined onClick={onFullscreenExitHandle} />;
      }
      return <FullscreenOutlined onClick={onFullscreenHandle} />;
    }
    return null;
  };

  // 获取是否显示header前面竖线标志
  const getHeaderVerticaLineFlag = () => {
    const win = window as any;
    // console.log(!(win.reactEnv?.OSPGlobelCompStyle?.styleConfig?.modal?.headerVerticaLine === false));
    return !(win.reactEnv?.OSPGlobelCompStyle?.styleConfig?.modal?.headerVerticaLine === false);
  };

  // 获取底部按钮确定和取消的左右位置
  const getFooterBtnPosition = () => {
    const win = window as any;
    return win.reactEnv?.OSPGlobelCompStyle?.styleConfig?.modal?.okBtnAtLeft === true;
  };
  const modalFn = (modal: any) => {
    return (
      <Rnd
        bounds="#root" // 边界元素
        ref={rndRef}
        default={{
          x: Math.floor(
            (document.body.clientWidth -
              32 -
              (parseFloat(centredCompute(computeWidth(width), attrType.width)) || MIN_WIDTH)) /
              2,
          ),
          y: Math.floor(
            (document.body.clientHeight -
              (parseFloat(centredCompute(computeHeight(height), attrType.height)) || MIN_HEIGHT)) /
              (placement === 'bottom' ? 1 : 2),
          ),
          width: computeWidth(width) || MIN_WIDTH,
          height: computeHeight(height) || MIN_HEIGHT,
        }}
        enableResizing={!disableResize}
        onResizeStop={(e, direction, refs) => {
          if (resizeWindow) resizeWindow(refs.style.height, refs.style.width);
        }}
        style={{
          overflow: 'hidden',
          boxShadow: '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d',
        }}
        dragHandleClassName={`drag${id} .ant-modal-header`}
        minHeight={computeHeight(height) || MIN_HEIGHT}
        minWidth={computeWidth(width) || MIN_WIDTH}
      >
        <div
          className={bodyNoPadding ? `noPadding drag${id}` : `drag${id}`}
          style={{ width: '100%', height: '100%' }}
          ref={modalRef}
        >
          {modal}
        </div>
      </Rnd>
    );
  };
  return visible ? (
    <Modal
      {...props}
      centered
      title={
        typeof title === 'string' ? (
          <div
            style={{
              width: '100%',
              cursor: 'move',
              color: 'inherit',
            }}
            className="customTitle"
          >
            {title}
            {getFullscreenDom()}
          </div>
        ) : (
          <div style={{ cursor: 'move', color: 'inherit' }} className="customTitle">
            {title}
            {getFullscreenDom()}
          </div>
        )
      }
      className={getFooterBtnPosition() ? 'okBtnAtLeftStyle' : ''}
      onOk={handleOK}
      onCancel={handleCancel}
      okText={okText || '确定'}
      cancelText={cancelText || '取消'}
      footer={footer}
      width="100%"
      wrapClassName={
        wrapClassName ||
        `FormWindow ${theme === 'cscec' ? 'CscecFormWindow' : ''} ${getHeaderVerticaLineFlag() ? '' : 'noHeaderLine'}`
      }
      visible
      keyboard
      maskClosable={false}
      getContainer={() => {
        return getContainer || document.body;
      }}
      modalRender={(node: ReactNode) => {
        return modalFn(node);
      }}
    >
      {children}
    </Modal>
  ) : null;
};
export default forwardRef(FormWindow);
