/*
 * @Author: ZY
 * @Date: 2023-02-21 19:06:48
 * @LastEditors: YL
 * @LastEditTime: 2024-01-05 14:18:51
 * @FilePath: /portal-react-start/src/constant/storageKey.ts
 * @Description: 存储的KEY键
 */

export const STORAGE_KEY = {
  TOKEN: 'tokenStr',
  USER_NAME: 'UserName',
  RECENT_USE: 'recentUse',
  ORG_INFO: 'orgInfo',
  DOWNLOAD_TIP: 'downloadTip',
  HasVisited: 'hasVisited',
  LOGOUT: 'logout',
  PasswordModifyDate: 'passwordModifyDate',
  LANG:'lang'
};

export const Cookie_KEY = {
  USER_NAME: 'UserName',
  USER_CAPTION: 'UserCaption',
  ERP_TOKEN: 'erptoken',
  // DefaultOrgName: 'DefaultOrgName',
  // LastLoginDate: 'lastLoginDate',
  // PasswordModifyDate: 'passwordModifyDate',
  // HasVisited: 'hasVisited',
};

export const IDB_key = {
  QUICK_APPLICATION: 'QuickApplication',
};

export const SEARCH_KEY = {
  SEARCH: 'search',
};
