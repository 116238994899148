/*
 * @Author: jld
 * @Date: 2023-02-08 15:40:18
 * @LastEditTime: 2023-04-01 16:28:28
 * @LastEditors: ZY
 * @Description: //自定义模板首页中间部分组件
 * @FilePath: /erp-finance-biz-portal-react-start/src/components/GridLayoutContent/index.tsx
 */
import GridItem from 'src/pages/CustomDashboard/components/gridItem';
import React, { useEffect, useRef, useState } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';
import { useSize } from 'ahooks';
import { IChildren, IDashboard } from 'src/api/types/dashboard';

export const GridLayoutContent: React.FC<{
  activeMenuKey: string | any; // 左侧选中的菜单
  pageInfo: IDashboard[]; // 菜单及对应内容
}> = (props) => {
  const { activeMenuKey, pageInfo } = props;
  const [layout, setLayout] = useState<Array<Layout>>([]);
  const layoutRef = useRef<any>([]);
  layoutRef.current = layout;
  const layoutActiveMenuWuchaiRef = useRef<any>({});
  const ref = useRef(null);
  const size: any = useSize(ref);
  const [rowHeight, setRowHeight] = useState<number>(10);

  // layout 间隙配置
  const gridLayoutGap:any = {
    margin:[10,10]
  }

  const defaultProps = {
    className: 'layout',
    cols: 10,
    isDraggable: false, // 首页不允许拖动
    isResizable: false, // 首页不允许调整大小
  };
  /**
   * 计算layout 最大行数
   * @param layout
   */
  const calcLayoutMaxRow = (layout:any) => {
    let max = 0;
    let bottomY;
    for (let i = 0, len = layout.length; i < len; i++) {
      bottomY = layout[i].y + layout[i].h;
      if (bottomY > max) max = bottomY;
    }
    return max;
  }
  /**
   * 获取rowHeight
   * @param containerHeight
   * @param rowNum
   */
  const getRowHeight = (containerHeight:any,rowNum:any) => {
    const marginY = gridLayoutGap?.margin[1];
    const containerPaddingY = gridLayoutGap?.containerPadding ? gridLayoutGap?.containerPadding[1] : marginY;
    return ((containerHeight - containerPaddingY * 2) - (rowNum - 1) * marginY) / rowNum
  }

  useEffect(() => {
    const gDataIndex = pageInfo.find((item: IDashboard) => {
      return item.id === activeMenuKey;
    });
    let layoutArrayTemp = gDataIndex?.children.map((item) => {
      return {
        i: item.id,
        x: item.x,
        y: item.y,
        w: item.width,
        h: item.height,
        componentType: item.componentType,
      };
    });
    if (layoutArrayTemp) {
      // 根据容器高度设置rowHeight
      setLayout(layoutArrayTemp as any);
    } else {
      setLayout([]);
    }
  }, [activeMenuKey, pageInfo]);

  useEffect(() => {
    if(layoutRef.current?.length > 0 && size?.height){
      if(size?.height){
        let calcHeight = getRowHeight(size?.height,calcLayoutMaxRow(layoutRef.current));
        // 如果计算的高度与 10 的误差在5的范围内就重新设置rowHeight
        let wuchaiResult;
        if(typeof layoutActiveMenuWuchaiRef.current[activeMenuKey] !== 'undefined'){
          wuchaiResult = layoutActiveMenuWuchaiRef.current[activeMenuKey]
        }else{
          wuchaiResult = Math.abs(calcHeight - 10) < 5;
          layoutActiveMenuWuchaiRef.current[activeMenuKey] = wuchaiResult;
        }
        console.log('size',size,activeMenuKey,calcHeight,layoutActiveMenuWuchaiRef)
        if(wuchaiResult){
          setRowHeight(calcHeight)
        }
      }
    }
  },[size,layoutRef.current])

  /**
   * 根据布局渲染dom
   */
  const generateDOM = (el: any) => {
    el.id = el.i;
    return (
      // 这个key是必传的而且不能与之前的已删除的key重复！！！
      // data-grid也是必传，传的是layouts的位置信息
      <div key={el.i} data-grid={el}>
        <GridItem item={el} />
      </div>
    );
  };

  return (
    <div style={{ width: '100%',height:'100%' }} ref={ref}>
      {size && (
        <GridLayout {...defaultProps} rowHeight={rowHeight} layout={layout} compactType="vertical" margin={gridLayoutGap.margin} width={size.width}>
          {layout?.map((el, i) => generateDOM(el))}
        </GridLayout>
      )}
    </div>
  );
};
