/*
 * @Author: WGF
 * @Date: 2023-02-09 20:48:58
 * @LastEditors: jld
 * @LastEditTime: 2023-03-09 19:23:42
 * @FilePath: \erp-finance-biz-portal-react-start\src\stores\index.ts
 * @Description: 文件描述
 */
import AppState from './components/AppState';
import TagsViewStore from './components/TagsViewStore';
import UserStore from './components/UserStore';
import DrawerStore from './components/DrawerStore';
import DashboardStore from './components/DashboardStore';
import RecentUseStore from './components/RecentUseStore';
import ThemeStore from './components/ThemeStore';
import InternationalizationStore from './components/InternationalizationStore';
const stores = {
  AppState,
  TagsViewStore,
  UserStore,
  DrawerStore,
  DashboardStore,
  RecentUseStore,
  ThemeStore,
  InternationalizationStore,
};

export default stores;
