/*
 * @Author: ZLL
 * @Date: 2023-02-06 13:37:13
 * @LastEditors: lmy
 * @LastEditTime: 2023-12-25 15:44:26
 * @FilePath: \portal-react-start\src\components\ExhibitionItem\index.tsx
 * @Description: 单据卡片
 */
import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import BoxEllipsisTooltip from 'src/components/BoxEllipsis';
import IconFont from '../IconFont';
import arrow_new from 'src/asset/arrow_new.svg';
import arrow_new_down from 'src/asset/arrow_new_down.svg';
import FunctionDocumentsCard from '../FunctionDrawer/components/FunctionDocumentsCard';
import FunctionSearchTitle from '../FunctionDrawer/components/FunctionSearchTitle';
import { getMenuListAndCollectionList, openMenuItem } from '../../utils/menu';
import { getFavoritesAdd, getFavoritesDelete } from 'src/api';
import { inject, observer } from 'mobx-react';
import stores from 'src/stores';
import { MenuItem } from 'src/types';
import { OPEN_TYPE_ARRAY } from 'src/constant/menu';
import useErpNavigate from 'src/hooks/useNavigate';
import { message, Collapse } from 'antd';
import { MenuItem as IMenuItem } from '../../types';
import PuiIconFont from '../PuiIconFont';

export interface ItemModel {
  name: string;
  key: string;
  icon?: string;
  collection?: boolean;
  date?: number;
  subjectMatter?: string;
}
export enum ItemType {
  collectionMenu = 'collectionMenu', // 我的收藏菜单
  recentVisits = 'recentVisits', // 最近访问
  bill = 'bill', // 单据
  tileItem = 'tileItem', // 平铺
  modular = 'modular', // 功能模块
  searchFuc = 'searchFuc', // 搜索结果功能
  globalSearchFuc = 'globalSearchFuc', // 全局搜索功能
}

const { Panel } = Collapse;

const ExhibitionItem: React.FC<{
  item: MenuItem | any;
  keyWords?: string;
  type: ItemType;
  [key: string]: any;
  rightActiveMenuKey?: string;
  visIcon?: boolean; // 是否展示globalSearchFuc的icon，默认false展示
}> = inject(
  'AppState',
  'TagsViewStore',
  'UserStore',
  'DrawerStore',
)(
  observer((props) => {
    const { item, keyWords, type, rightActiveMenuKey, visIcon } = props;
    let navigate = useErpNavigate();
    const [activityKey, setActivityKey] = useState<string>();

    // 明细菜单功能点击
    const onItemClick = (item: IMenuItem) => {
      console.log('菜单是否为加载状态', stores.AppState.menuLoadingStatus);
      if (stores.AppState.menuLoadingStatus) {
        return;
      }
      // 路由跳转的时候关闭菜单弹窗
      if (stores.DrawerStore.drawerOpenStatus === 'open') {
        stores.DrawerStore.setDrawerOpen(false);
      }
      let isOpenMenu = OPEN_TYPE_ARRAY.find((value) => value === item.config.openType);
      if (isOpenMenu) {
        openMenuItem(item);
      } else {
        if (item.isUesStatus) {
          navigate(item.path);
        } else {
          message.warning('该功能不可用');
        }
      }
    };

    useEffect(() => {
      setActivityKey(rightActiveMenuKey);
    }, [rightActiveMenuKey]);

    /**
     * @description: 删除我的收藏菜单
     * @param {string} id
     * @return {*}
     */
    const deleteMenuItems = (id: string) => {
      getFavoritesDelete({ menuId: id }).then((res) => {
        if (res) {
          getMenuListAndCollectionList();
        }
      });
    };

    // 收藏菜单
    const itemCollection = (item: any) => {
      // 已经收藏再点点击取消
      if (item.isCollect) {
        deleteMenuItems(item.id);
      } else {
        getFavoritesAdd({ menuId: item.id }).then((res) => {
          if (res) {
            getMenuListAndCollectionList();
          }
        });
      }
    };

    const getItemDom = (menuItem: IMenuItem, hasChildren: boolean) => {
      let itemDom;
      switch (type) {
        case ItemType.recentVisits:
          itemDom = (
            <div className={styles.recentVisitsItem} key={menuItem.id} onClick={() => onItemClick(menuItem)}>
              <div className={styles.recentVisitsItemName}>
                <BoxEllipsisTooltip text={menuItem.name} icon={menuItem.icon} isLeaf={menuItem.isLeaf} />
              </div>
            </div>
          );
          break;
        case ItemType.bill:
          itemDom = <FunctionDocumentsCard item={menuItem} keyWords={keyWords} />;
          break;
        case ItemType.tileItem:
          itemDom = (
            <div className={styles.listItem} key={`${menuItem.id}${menuItem.id}`} onClick={() => onItemClick(menuItem)}>
              <div className={styles.listItemLeft}>
                <span className={`${styles.listItemName} ${activityKey === menuItem.id && styles.activityMenuItem}`}>
                  {menuItem.name}
                </span>
              </div>
              <IconFont
                className={`${styles.collectionIcon} ${!menuItem?.isCollect ? styles.hide : ''}`}
                type={menuItem?.isCollect ? 'icon-shoucang-xuanzhong' : 'icon-shoucang-weixuanzhong'}
                onClick={(e) => {
                  e.stopPropagation();
                  itemCollection(menuItem);
                }}
              />
            </div>
          );
          break;
        case ItemType.modular:
          itemDom = (
            <div
              key={menuItem.id}
              onClick={() => onItemClick(menuItem)}
              // eslint-disable-next-line no-useless-concat
              className={
                `${styles.flexModuleItem}` + ` ` + `${activityKey === menuItem.id && styles.activityBackgroundItem}`
              }
              style={hasChildren ? { padding: '0 10px 0 40px' } : { padding: '0 10px 0 36px' }}
            >
              <div
                className={`${styles.documentName} ${activityKey === menuItem.id && styles.activityMenuItem}`}
                style={hasChildren ? { paddingLeft: '0px' } : {}}
              >
                {menuItem.icon ? (
                  menuItem.isLeaf ? (
                    <img src={`/portal/images/isLeafMenuIcon/${menuItem.icon}.svg`} className={styles.iconSize} />
                  ) : (
                    <PuiIconFont type={menuItem.icon ?? ' '} className={styles.iconSize} />
                  )
                ) : (
                  ''
                )}
                <span style={{ paddingLeft: menuItem.icon ? '6px' : '10px' }}>{menuItem.name}</span>
              </div>
              <IconFont
                onClick={(e) => {
                  e.stopPropagation();
                  itemCollection(menuItem);
                }}
                className={`${styles.collectionIcon} ${menuItem?.isCollect ? '' : styles.hide}`}
                type={menuItem?.isCollect ? 'icon-shoucang-xuanzhong' : 'icon-shoucang-weixuanzhong'}
              />
            </div>
          );
          break;
        case ItemType.globalSearchFuc:
          itemDom = (
            <div className={styles.fucItemContentItem} key={menuItem.id} onClick={() => onItemClick(menuItem)}>
              {!visIcon && <IconFont type={menuItem.icon ?? ''} className={styles.fucItemContentItemIcon} />}
              <FunctionSearchTitle title={menuItem.name} keyWords={keyWords} />
            </div>
          );
          break;
        case ItemType.searchFuc:
          itemDom = (
            <div className={styles.searchMenuItemTitle} onClick={() => onItemClick(menuItem)}>
              <div className={styles.menuItemTitleNameBox}>
                <BoxEllipsisTooltip text={<span dangerouslySetInnerHTML={{ __html: menuItem.name }} />} />
              </div>
              <div
                className={styles.menuItemCollectionIconBox}
                onClick={(e) => {
                  e.stopPropagation();
                  return false;
                }}
              >
                <IconFont
                  className={`${styles.collectionIcon} ${!menuItem?.isCollect ? styles.hide : ''}`}
                  type={menuItem?.isCollect ? 'icon-shoucang-xuanzhong' : 'icon-shoucang-weixuanzhong'}
                />
              </div>
            </div>
          );
          break;
        case ItemType.collectionMenu:
          itemDom = (
            <div className={styles.collectionMenuItemTitle}>
              <div className={styles.menuItemTitleNameBox}>
                <BoxEllipsisTooltip text={menuItem.name} />
              </div>
              <div
                className={styles.menuItemCloseIconBox}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteMenuItems(menuItem.id);
                  return false;
                }}
              >
                <IconFont type="icon-yeqianlan-guanbi" className={styles.menuCloseIcon} />
              </div>
            </div>
          );
      }
      return itemDom;
    };

    return (
      <>
        {!item.children?.length ? (
          getItemDom(item, false)
        ) : (
          <div key={item.id} className={`${activityKey === item.id && styles.activityBackgroundItem}`}>
            <Collapse
              defaultActiveKey={['1']}
              ghost
              expandIcon={({ isActive }) =>
                isActive ? <img src={arrow_new_down} alt="" /> : <img src={arrow_new} alt="" />
              }
            >
              <Panel
                header={
                  <div>
                    {item.icon ? (
                      item.isLeaf ? (
                        <img src={`/portal/images/isLeafMenuIcon/${item.icon}.svg`} className={styles.iconSize} />
                      ) : (
                        <PuiIconFont type={item.icon ?? ' '} className={styles.iconSize} />
                      )
                    ) : (
                      ''
                    )}
                    <span
                      style={{ paddingLeft: item.icon ? '6px' : '0px' }}
                    >{`${item.name} (${item.children.length})`}</span>
                  </div>
                }
                key={item.id}
              >
                {item.children.map((nextItem: IMenuItem) => getItemDom(nextItem, true))}
              </Panel>
            </Collapse>
          </div>
        )}
      </>
    );
  }),
);
export default ExhibitionItem;
