/*
 * @Author: ZY
 * @Date: 2023-02-15 20:17:19
 * @LastEditors: atwlee
 * @LastEditTime: 2023-08-31 15:27:23
 * @FilePath: /erp-finance-biz-portal-react-start/src/utils/microApp.ts
 * @Description: 微前端单例，加载的子应用列表管理
 */

import { getMicroConfigList } from 'src/api/microApp';
import { IMicroAppModel } from 'src/api/types/microConfig';
import WujieReact from 'wujie-react';
const { preloadApp, destroyApp } = WujieReact;
import { isEmpty } from 'lodash-es';
import stores from 'src/stores';

export class MicroAppManager {
  private static instance: MicroAppManager;
  public static shared() {
    if (!this.instance) {
      this.instance = new MicroAppManager();
    }
    return this.instance;
  }
  private microApps: IMicroAppModel[] = [];
  private constructor() {}

  /**
   * @description: 加载微前端子应用配置
   * @return {*}
   */
  public loadMicroApps = () => {
    const LoginInfo = {
      IsLogin: stores.UserStore.isLogin,
      UserName: stores.UserStore.userName,
      UserCaption: stores.UserStore.userCaption,
    };
    getMicroConfigList().then((res) => {
      MicroAppManager.shared().microApps = res;
      for (const app of MicroAppManager.shared().microApps) {
        if (app.preLoadApp) {
          preloadApp({
            ...app,
            alive: true,
            props: {
              operateTabKey: `${app.name.toUpperCase()}_TAB_KEY`,
              LoginInfo,
              isInnerTab: true,
              stores: stores,
            },
            url: app.expinfo.preloadUrl ? app.url + app.expinfo.preloadUrl : app.url + '\\',
            activated: () => {
              if (app.exec) {
                console.log('预执行----activated', app.name);
                stores.AppState.setExecAppLoadedState(app.name, true);
              }
            },
            beforeLoad: () => {
              if (app.exec) {
                console.log('预执行----beforeLoad', app.name);
                stores.AppState.setExecAppLoadedState(app.name, false);
              }
            },
          });
        }
      }
    });
  };

  /**
   * @description: 获取微前端子应用配置
   * @return {*}
   */
  public getMicroApps = async () => {
    if (isEmpty(this.microApps)) {
      // MicroAppManager.shared().microApps = microAppsJson.data;
      MicroAppManager.shared().microApps = await getMicroConfigList();
      return MicroAppManager.shared().microApps;
    }
    return this.microApps;
  };

  /**
   * @description: 销毁微前端子应用
   * @return {*}
   */
  public destroyMicroApps = async (destroyAppKey: string) => {
    const microApps = await this.getMicroApps();
    const allowDestroy = microApps.find((i) => i.name === destroyAppKey)?.expinfo?.destroy;
    if (allowDestroy) {
      console.log('销毁应用---', destroyAppKey);
      destroyApp(destroyAppKey);
    }
  };
}
