/*
 * @Author: jld
 * @Date: 2023-03-21 09:34:22
 * @LastEditTime: 2024-03-18 18:14:07
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @Description:
 * @FilePath: /portal-react-start/src/app.tsx
 */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './routes/router';
import { ConfigProvider } from 'antd';
import { inject, observer } from 'mobx-react';
import {changeThemeToken, primaryTransformAlpha, transformedThemeTokenData} from 'src/utils/theme';
import { IntlProvider } from 'react-intl';
import { getAntdLocale, getLang, getMessages } from './utils/locale';
import { getCookies } from './utils/storage';
import { Cookie_KEY, STORAGE_KEY } from './constant/storageKey';
import {getDashboard, getSystemSettings} from './api';
import { MicroAppManager } from './utils/microApp';
import { getUsableThemeSettingInfo } from './api/theme';
import { getBrowserAndValidGlobalTheme } from './api/systemSetting';
import { SYSTEM_SETTING } from './pages/SystemSetting/model';
import { isLowerBrowser } from './utils/browsers';

const IndexPage: React.FC<{ [key: string]: any }> = inject(
  'AppState',
  'UserStore',
  'InternationalizationStore',
  'DashboardStore',
  'ThemeStore',
)(
  observer((props) => {
    const {
      AppState: { themeToken,setThemStyle,setAppEnvInited},
      InternationalizationStore: { lang},
      UserStore: { setIsLogin, setToken, setUserInfo, setUserCaption },
      DashboardStore: { setDashboardList },
      ThemeStore: { setUsableThemeListState, setGlobalThemeId, setGrayTheme, setGlobalThemeEndTime},

    } = props;

    const themeList = useRef<any>();

    useEffect(() => {
      if (themeToken) {
        ConfigProvider.config({
          theme: {
            primaryColor: themeToken.colorPrimary,
            infoColor: themeToken.colorInfo,
            successColor: themeToken.colorSuccess,
            errorColor: themeToken.colorError,
            warningColor: themeToken.colorWarning,
          },
        });
        if (themeToken.colorPrimary) {
          document.documentElement.style.setProperty(
            '--antd-primary-bg-base',
            primaryTransformAlpha(themeToken.colorPrimary, 0.08),
          );
          localStorage.setItem('themeToken', JSON.stringify(themeToken));
        }
      }
    }, [themeToken]);

    const initColorData = async () => {
      await getUsableThemeList();
      await getBrowserAndValidGlobalThemeSetting();
    };

    const getUsableThemeList = async () => {
      await getUsableThemeSettingInfo()
        .then((res: any) => {
          if (res?.length > 0) {
            const themeTokenList = transformedThemeTokenData(res);
            setUsableThemeListState(themeTokenList);
            themeList.current = res;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getBrowserAndValidGlobalThemeSetting = async () => {
      const res: any = await getBrowserAndValidGlobalTheme();
      const globalThemeId = res?.[SYSTEM_SETTING.GLOBAL_THEME_LIMIT]?.jsonValue?.themeId;
      if (globalThemeId) {
        const globalThemeInfo = themeList.current.filter((item: any) => item.themeId === globalThemeId);
        if (globalThemeInfo[0].themeType === '1') {
          setGrayTheme('1');
        } else {
          setGrayTheme('0');
        }
      }

      setGlobalThemeId(globalThemeId);
      setGlobalThemeEndTime(res?.[SYSTEM_SETTING.GLOBAL_THEME_LIMIT]?.jsonValue?.endTs);
    };

    const messages = useMemo(() => {
      return getMessages(lang);
    }, [lang]);

    const antdLocale = useMemo(() => {
      return getAntdLocale(lang);
    }, [lang]);

    // 获取erptoken,放到local里面
    useEffect(() => {
      let erpToken = getCookies(Cookie_KEY.ERP_TOKEN);
        // 获取系统配置
        const initAppEnv = async () => {
            const browserThemeSetting = await getBrowserAndValidGlobalTheme();
            if (browserThemeSetting[SYSTEM_SETTING.BROWSER_LOW_ALERT]?.jsonValue?.type !== 1 || !isLowerBrowser()) {
                initColorData();
                if (erpToken) {
                    setToken(erpToken);
                    setIsLogin(true);
                    localStorage.removeItem(STORAGE_KEY.LOGOUT);
                    MicroAppManager.shared().loadMicroApps();
                } else {
                    setIsLogin(false);
                    setToken(undefined);
                }
                getDashboard().then((res) => {
                    setDashboardList(res);
                });
            }
            const res: any = await getSystemSettings();
            if (res?.length) {
                res.forEach((item:any) => {
                    switch (item.configCode) {
                        case 'overallStyle':{
                            setThemStyle(item.configValueCode);
                            break;
                        }
                        default:
                            break;
                    }
                });
            }
            setAppEnvInited(true)

        };
        initAppEnv()
    }, []);

    return (
      <IntlProvider locale={getLang(lang)} messages={messages}>
        <ConfigProvider
          theme={{
            token: themeToken,
          }}
          locale={antdLocale}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </IntlProvider>
    );
  }),
);
export default IndexPage;
