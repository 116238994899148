/*
 * @Author: jld
 * @Date: 2023-07-05 09:44:08
 * @LastEditTime: 2024-01-03 11:06:12
 * @LastEditors: YL
 * @Description: 功能注册
 * @FilePath: /portal-react-start/src/routes/functionRegister.tsx
 */
import Loadable from 'react-loadable';
const MyLoadingComponent = () => {
  return <div />;
};
export const functionRegister: any[] = [
  {
    name: '/menuMaintain',
    caption: '菜单维护',
    component: Loadable({
      loader: () => import('src/pages/MenuMaintain'),
      loading: MyLoadingComponent,
    }),
  },
  {
    name: '/customThemeStyle',
    caption: '自定义主题样式',
    component: Loadable({
      loader: () => import('src/pages/CustomThemeStyle'),
      loading: MyLoadingComponent,
    }),
  },
  {
    name: '/themeSetting',
    caption: '主题设置',
    component: Loadable({
      loader: () => import('src/pages/ThemeSetting'),
      loading: MyLoadingComponent,
    }),
  },
  {
    name: '/systemSetting',
    caption: '系统设置',
    component: Loadable({
      loader: () => import('src/pages/SystemSetting'),
      loading: MyLoadingComponent,
    }),
  },
  {
    name: '/lowBrowser',
    caption: '浏览器版本低',
    component: Loadable({
      loader: () => import('src/pages/LowBrowser'),
      loading: MyLoadingComponent,
    }),
  },
  {
    name: '/applicationConfig',
    caption: '微前端配置',
    component: Loadable({
      loader: () => import('src/pages/ApplicationConfig'),
      loading: MyLoadingComponent,
    }),
  },

];
