/*
 * @Author: jld
 * @Date: 2023-03-02 17:32:00
 * @LastEditTime: 2023-03-02 17:32:00
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\DashboardIconFont\index.tsx
 */

import { createFromIconfontCN } from '@ant-design/icons';
import { URL_CONSTANT } from 'src/constant';

const DashboardIconFont = createFromIconfontCN({
  scriptUrl: URL_CONSTANT.DASHBOARD_ICON_FONT,
});

export default DashboardIconFont;
