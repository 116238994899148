/*
 * @Author: ZLL
 * @Date: 2023-02-08 20:03:05
 * @LastEditors: jld
 * @LastEditTime: 2023-08-24 09:38:34
 * @FilePath: \erp-finance-biz-portal-react-start\src\constant\url.ts
 * @Description: url 常量
 */
export const URL_CONSTANT = {
  ICON_FONT: '//at.alicdn.com/t/c/font_3839183_nsw9y61vhj9.js',
  ICON_PUI_FONT: '//at.alicdn.com/t/c/font_3928082_th8hn2qit1.js',
  DASHBOARD_ICON_FONT: '//at.alicdn.com/t/c/font_3923323_qfzhrao91bm.js',
  DASHBOARD_ICON_FONT_JSON:
    'https://at.alicdn.com/t/c/font_3923323_qfzhrao91bm.json?spm=a313x.7781069.1998910419.50&file=font_3923323_qfzhrao91bm.json',
};
