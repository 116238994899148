/*
 * @Author: YXP
 * @Date: 2023-05-30 16:03:42
 * @LastEditors: yxp
 * @LastEditTime: 2023-07-24 17:06:20
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Aside\index.tsx
 * @Description: 文件描述
 */
import React, { useMemo, useRef, useState } from 'react';
import styles from './index.module.less';
import CollapseMenu from '../../../components/FunctionDrawer/components/FunctionMenuNailing';
import { inject, observer } from 'mobx-react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { InputRef, Tooltip } from 'antd';
import debounce from 'lodash/debounce';
import FunctionSearch from '../../../components/FunctionDrawer/components/FunctionSearch';
import IconFont from 'src/components/IconFont';
import { MESSAGE_KEY } from 'src/constant';
import WujieReact from 'wujie-react';
const { bus } = WujieReact;

const IndexPage: React.FC<{
  [key: string]: any;
}> = inject('DrawerStore')(
  observer((props) => {
    const {
      DrawerStore: { setDrawerOpen, setShowFunDrawer, setShowFunDrawerFlag, collectMenuFixed, setCollectMenuFixed },
    } = props;
    const [collapse, setCollapse] = useState(false);
    const searchRef = useRef<InputRef>();
    const searchOnChange = debounce((e: any) => {
      if (e.target.value) setDrawerOpen(true);
    }, 500);
    return (
      <div
        className={styles.aside}
        style={{
          display: collectMenuFixed ? 'block' : 'none',
          width: collapse ? '60px' : '240px',
          flex: `0 0 ${collapse ? '60px' : '240px'}`,
        }}
      >
        <div
          className={styles.collectSearch}
          onMouseEnter={() => {
            // setCollapse(false);
          }}
        >
          {collapse ? (
            <IconFont type="icon-daohanglan-sousuo" className={styles.searchIcon} />
          ) : (
            <FunctionSearch inputRef={searchRef} stylesProps={{ width: '100%' }} onChange={searchOnChange} />
          )}
        </div>
        <div
          className={styles.collectMenu}
          onMouseEnter={() => {
            // setCollapse(false);
          }}
        >
          <CollapseMenu
            allFunctionsBtnClick={() => {
              setShowFunDrawer(true);
              setShowFunDrawerFlag(true);
              setDrawerOpen(true);
            }}
            collapse={collapse}
            toTop={collectMenuFixed}
            setToTop={setCollectMenuFixed}
          />
        </div>
        <div
          className={styles.collapse}
          style={{ justifyContent: 'flex-start', cursor: 'pointer' }}
          onClick={() => {
            bus.$emit(MESSAGE_KEY.RETRACT_CHANGE, {
              isRetract: !collapse,
            });
            setCollapse(!collapse);
          }}
        >
          {collapse ? (
            <Tooltip
              title="展开导航"
              placement="right"
              color="rgba(0,0,0,0.65)"
              overlayClassName={styles.collapseTooltip}
            >
              <MenuUnfoldOutlined />
            </Tooltip>
          ) : (
            <Tooltip
              title="收起导航"
              placement="right"
              color="rgba(0,0,0,0.65)"
              overlayClassName={styles.collapseTooltip}
            >
              <MenuFoldOutlined />
            </Tooltip>
          )}
        </div>
      </div>
    );
  }),
);
export default IndexPage;
