import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { message, Modal } from 'antd';
import screenfull from 'screenfull';
import { CloseOutlined } from '@ant-design/icons';
import './index.less';
import { uuid } from 'src/utils/uuid';
import ReactComp from '../../ReactComp/index';
import IFrame from '../../IFrame/index';
import MicroApp from '../../Wujie/index';
import { LOAD_TYPE } from 'src/constant/menu';
import { MenuItem } from 'src/types';

function ModalForm(props: { menuItem: MenuItem; prevProps: any }) {
  const { menuItem, prevProps } = props;

  const [visible, setVisible] = useState(true);

  const hideModal = () => {
    if (menuItem.config.BrowserFullscreen === '1') {
      fullFnc();
    } else {
      closeFnc();
    }
  };

  const closeFnc = () => {
    const closeWindow = menuItem?.config.closeWindowFnc;
    closeWindow?.call(menuItem?.config.closeParams);
    setVisible(false);
  };

  const getContent = () => {
    const type = menuItem?.config?.loadType;
    switch (type) {
      case LOAD_TYPE.Micro_APP:
        return <MicroApp menuItem={menuItem} />;
      case LOAD_TYPE.REACT_CODE:
        return <ReactComp menuItem={menuItem} />;
      case LOAD_TYPE.IFRAME:
        return <IFrame menuItem={menuItem} />;
    }
  };

  useEffect(() => {
    screenfull.isEnabled && screenfull.on('change', change);
    return () => {
      screenfull.isEnabled && screenfull.off('change', change);
    };
  }, []);

  const change = () => {
    if (!screenfull.isFullscreen) {
      closeFnc();
    }
  };

  useEffect(() => {
    if (menuItem?.config) {
      fullFnc();
    }
  }, [menuItem]);

  const fullFnc = () => {
    if (menuItem.config.BrowserFullscreen === '1') {
      if (!screenfull.isEnabled) {
        message.warning('you browser can not work');
        return false;
      }
      screenfull.toggle();
    }
  };

  // 右上角关闭图标
  const getCloseIcon = () =>
    menuItem?.config?.CloseIcon ? (
      <img style={{ maxWidth: '100%' }} src={menuItem.config.CloseIcon} />
    ) : (
      <CloseOutlined />
    );

  return (
    <div>
      {visible ? (
        <Modal
          closable
          closeIcon={getCloseIcon()}
          footer={null}
          open
          onCancel={hideModal}
          wrapClassName="fullScreenModal"
          zIndex={1002}
        >
          {getContent()}
        </Modal>
      ) : null}
    </div>
  );
}

class FullScreenClass {
  public guid: any;
  public constructor() {
    this.guid = '';
  }

  public open = (item: MenuItem, props: any) => {
    this.guid = uuid();
    const div = document.createElement('div');
    div.setAttribute('id', this.guid);
    document.body.appendChild(div);
    ReactDOM.render(React.createElement(ModalForm, { menuItem: item, prevProps: props }), div);
  };
}
const FullScreenModal = new FullScreenClass();
export default FullScreenModal;
