/*
 * @Author: sjt
 * @Date: 2023年2月13日14:04:04
 * @LastEditTime: 2023-07-05 09:52:16
 * @LastEditors: jld
 * @Description:自定义模板首页
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\CustomDashboard\components\gridItem.tsx
 */
import React, { useMemo } from 'react';
import styles from '../index.module.less';
import IconFont from '../../../components/IconFont';
import { IChildrenComponent } from 'src/api/types/dashboard';
import loadable from '@loadable/component';
import { customDashboardList } from 'src/routes/componentsRegister';
interface IGridItem {
  deleteItem?: (item: IChildrenComponent) => void;
  type?: string;
  item: IChildrenComponent;
}

const GridItem: React.FC<IGridItem> = (props) => {
  const { deleteItem, type, item } = props;

  const ItemDom: any = useMemo(() => {
    let dom = null;
    if (item?.componentType) {
      const components = customDashboardList.find(
        (listItem: { componentType: string }) => listItem.componentType === item.componentType,
      );
      if (components?.path) {
        dom = loadable(() => import(`src/${components.path}/index`));
      }
    }

    return dom;
  }, [item]);
  // 删除部件
  const onDelete = () => {
    if (deleteItem) {
      deleteItem(item);
    }
  };

  return (
    <div className={styles.gridItem}>
      <div className={styles.gridContainer}>
        {type !== 'edit' ? null : (
          <div className={styles.itemMask}>
            <IconFont className='delete' type="icon-yeqianlan-guanbi" onClick={onDelete} />
          </div>
        )}
        <ItemDom />
      </div>
    </div>
  );
};

export default GridItem;
