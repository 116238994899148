/*
 * @Author: ZY
 * @Date: 2023-02-15 16:02:47
 * @LastEditors: lmy
 * @LastEditTime: 2023-12-01 14:59:33
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\routerContainer\Wujie\index.tsx
 * @Description: wujie 容器
 */
import React, { useEffect, useState } from 'react';
import WujieReact from 'wujie-react';
import type { MenuItem } from 'src/types';
import lifecycles from './lifecycle';
import { MicroAppManager } from 'src/utils/microApp';
import { IMicroAppModel, microAppLoadingType } from 'src/api/types/microConfig';
import Loading from 'src/components/Loading';
import { MESSAGE_KEY } from 'src/constant';
import stores from 'src/stores';
import { EventTargetPlugin } from 'wujie-polyfill';

type WuJieProps = IMicroAppModel & { microAppPath: string; microAppName: string };
const { bus } = WujieReact;

const IndexPage: React.FC<{ menuItem: MenuItem }> = (props) => {
  const { menuItem } = props;
  // 自定义loading是否加载
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const [wuJieLoading, setWuJieLoading] = useState<boolean>(false);
  const [wujieProps, setWujieProps] = useState<WuJieProps | null>(null);

  // 后台返回Path是appName/path 形式。  需要解析拿到appName名称去微前端列表匹配
  const microAppName = menuItem.config.url?.split('/')[0];

  const getMicroApp = async () => {
    let microApps = await MicroAppManager.shared().getMicroApps();
    const microApp = microApps.find((item) => item.name === microAppName);
    if (!microApp) {
      return;
    }
    // microApp.attrs = { src: microApp.url };
    const microAppPath = menuItem.config.url?.replace(`${microAppName}`, microApp.url ?? '');
    const { microLoading, ...microAppOtherProps } = microApp.expinfo;
    switch (microLoading) {
      // 自定义
      case microAppLoadingType.CUSTOM:
        setCustomLoading(true);
        break;
      // 无loading
      case microAppLoadingType.NONE:
        setWuJieLoading(false);
        break;
      // 无界loading
      case microAppLoadingType.WUJIE:
        setWuJieLoading(true);
        break;
    }
    setWujieProps({
      microAppName,
      microAppPath,
      ...microApp,
      expinfo: microAppOtherProps,
    });
  };
  useEffect(() => {
    getMicroApp();
  }, [menuItem]);

  bus.$on(MESSAGE_KEY.PAGE_LOADED, () => {
    setCustomLoading(false);
  });

  console.log('请求url是:', wujieProps?.microAppPath);

  let LoginInfo = {
    IsLogin: stores.UserStore.isLogin,
    UserName: stores.UserStore.userName,
    UserCaption: stores.UserStore.userCaption,
  };
  console.log('LoginInfo', LoginInfo);

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Loading spinning={customLoading}>
        {wujieProps && (
          <WujieReact
            width="100%"
            height="100%"
            name={menuItem.id}
            loading={wuJieLoading ? null : (document.createElement('span') as any)}
            url={wujieProps.microAppPath}
            alive={wujieProps.alive}
            sync={wujieProps.sync}
            attrs={wujieProps.attrs}
            degrade={wujieProps.degrade}
            props={{
              ...props,
              LoginInfo: LoginInfo,
              tabParams: props.menuItem.tabParams,
              stores: stores
            }}
            {...lifecycles}
            activated={() => {
              setCustomLoading(false);
            }}
            plugins={[EventTargetPlugin()]}
          />
        )}
      </Loading>
    </div>
  );
};
export default IndexPage;
