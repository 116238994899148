/*
 * @Author: jld
 * @Date: 2023-02-15 16:08:15
 * @LastEditTime: 2023-07-05 10:29:46
 * @LastEditors: jld
 * @Description: 硬编码组件容器
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\routerContainer\ReactComp\index.tsx
 */
import FormLocaleBase from 'src/components/formlocalebase';
import React, { useEffect, useState } from 'react';
import { functionRegister } from 'src/routes/functionRegister';
import { MenuItem } from '../../../types';
const ReactComp: React.FC<{ menuItem: MenuItem }> = (props) => {
  const [reactComps, setReactComps] = useState<any>(null);

  // 根据menuItem获取对应组件
  const getComps = (menuItem: MenuItem) => {
    const reactCompType = menuItem?.config?.url;
    const list = functionRegister;
    const domArr = list.filter((item: any) => item.name === reactCompType);
    if (domArr && domArr.length > 0) {
      const Dom: any = domArr[0].component;
      setReactComps(<Dom config={menuItem} />);
    } else {
      console.warn(`没找到【${reactCompType}】类型对应的组件，请检查是否注册该组件！`);
    }
  };

  useEffect(() => {
    if (props) {
      getComps(props.menuItem);
    }
  }, [props.menuItem]);

  return (
    <FormLocaleBase>
      <div style={{ width: '100%', height: '100%' }}>{reactComps}</div>
    </FormLocaleBase>
  );
};
export default ReactComp;
