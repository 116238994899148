/*
 * @Author: LMY
 * @Date: 2023-09-15 10:42:12
 * @LastEditors: lmy
 * @LastEditTime: 2023-09-15 14:41:20
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\browser.ts
 * @Description: 获取浏览器信息
 */
import { APP_SETTINGS } from '../constant';
import { IBrowserProps } from '../components/BrowserTip';

declare interface IBrowserListProps {
  // 'IE': boolean;
  // 'IE>11': boolean;
  Chrome: boolean;
  Firefox: boolean;
  Opera: boolean;
  Safari: boolean;
  Edge: boolean;
  qq: boolean;
  [key: string]: boolean;
}

declare interface IBrowserReturnProps {
  type: string;
  versions: number;
}

// 判断是否是360浏览器
const isBrowser360 = (mimeTypes: any) => {
  for (let mt in mimeTypes) {
    // 检测是否是360浏览器(测试只有pc端的360才起作用)
    if (mimeTypes[mt]['type'] === 'application/360softmgrplugin') {
      return '360';
    }
  }
};

// 获取浏览器信息 类型、版本号
export const getBrowser = () => {
  const UserAgent = navigator.userAgent.toLowerCase();
  const browserInfo: IBrowserReturnProps = {
    type: 'unknown',
    versions: 0,
  };
  const browserArray: IBrowserListProps = {
    // IE
    // 'IE': UserAgent.indexOf("compatible") > -1 && UserAgent.indexOf("MSIE") > -1,
    // 'IE>11': window.ActiveXObject || 'ActiveXObject' in window,
    // Chrome浏览器
    Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1,
    // 火狐浏览器
    Firefox: UserAgent.indexOf('firefox') > -1,
    // Opera浏览器
    Opera: UserAgent.indexOf('opera') > -1 || UserAgent.indexOf('opr') > -1,
    // safari浏览器
    Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') === -1,
    // Edge浏览器
    Edge: UserAgent.indexOf('edg') > -1,
    // qq浏览器
    qq: /qqbrowser/.test(UserAgent),
  };
  for (let i in browserArray) {
    if (browserArray[i]) {
      let versions = '';
      let result = null;
      switch (i) {
        // case 'IE':
        //     versions = UserAgent.match(/(msie\s|trident.*rv:)([\w.]+)/)?.[2] ?? '';
        //     break;
        case 'Chrome':
          result = isBrowser360(navigator.mimeTypes);
          result && (i = result);
          versions = UserAgent.match(/chrome\/([\d.]+)/)?.[1] ?? '';
          break;
        case 'Firefox':
          versions = UserAgent.match(/firefox\/([\d.]+)/)?.[1] ?? '';
          break;
        case 'Opera':
          versions = UserAgent.match(/(?:opera|opr).([\d\.]+)/)?.[1] ?? '';
          break;
        case 'Safari':
          versions = UserAgent.match(/version\/([\d.]+)/)?.[1] ?? '';
          break;
        case 'Edge':
          versions = UserAgent.match(/edg\/([\d.]+)/)?.[1] ?? '';
          break;
        case 'qq':
          versions = UserAgent.match(/qqbrowser\/([\d.]+)/)?.[1] ?? '';
          break;
        default:
          break;
      }
      browserInfo.type = i;
      browserInfo.versions = parseInt(versions, 10);
    }
  }
  return browserInfo;
};

// 判断是否是低版本浏览器
export const isLowerBrowser = () => {
  const { type, versions } = getBrowser();
  let flag = false;
  APP_SETTINGS.BROWSER_LIST.forEach((item: IBrowserProps) => {
    const { version, browserId } = item;
    const versionNum = Number(version);
    if (type === browserId && versionNum > versions) {
      flag = true;
    }
  });
  return flag;
};
