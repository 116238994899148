/*
 * @Author: ZLL
 * @Date: 2023-02-06 13:37:13
 * @LastEditors: yxp
 * @LastEditTime: 2023-07-25 10:42:31
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\components\FunctionDocumentsCard\index.tsx
 * @Description: 单据卡片
 */
import React from 'react';
import styles from './index.module.less';
import BoxEllipsisTooltip from 'src/components/BoxEllipsis';
import { conversionDate } from 'src/utils/date';
import FunctionSearchTitle from '../FunctionSearchTitle';
import { MenuItem } from '@/types';
import { inject, observer } from 'mobx-react';

interface ItemModel extends MenuItem {
  name: string;
  date?: number;
  subjectMatter?: string;
  creator?: string;
  amount?: number;
}

// TODO: 类型报错，数据先写死
const FunctionDocumentsCard: React.FC<{
  item: ItemModel;
  keyWords?: string;
}> = inject(
  'AppState',
  'TagsViewStore',
  'UserStore',
  'DrawerStore',
)(
  observer((props) => {
    const { item, keyWords } = props;
    return (
      <div className={styles.documentsItem} key={item.id} onClick={() => {}}>
        <div className={styles.documentsTitle}>
          <div className={styles.documentsDate}>{conversionDate(item.date ?? 0)}</div>
          <div className={styles.documentsName}>
            <FunctionSearchTitle title={item.name} keyWords={keyWords} />
          </div>
        </div>
        <div className={styles.documentsMatter}>
          <BoxEllipsisTooltip multiline={false} text={`出差事由：${item.subjectMatter}`} />
        </div>
        <div className={styles.documentsCreatorAmount}>
          <span>{`制单人：${item.creator}`}</span>
          <span className={styles.documentsSeg}>|</span>
          <span>{`报销金额：${item.amount}`}</span>
        </div>
      </div>
    );
  }),
);
export default FunctionDocumentsCard;
