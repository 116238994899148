/*
 * @Author: WGF
 * @Date: 2023-12-27 17:29:10
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @LastEditTime: 2024-03-12 15:15:15
 * @Description:
 * @FilePath: /shared-business-service-react-apps/apps/portal/src/layout/components/UserSystemDrawer/dataModel.ts
 */

import { ISettingMenuItem } from './type';

// 账号设置
export const accountSettingList: ISettingMenuItem[] = [
  {
    key: 'baseInformation',
    icon: 'icon-jibenxinxi',
    name: 'baseInformation',
    path: '/userAccount?activeCode=baseInformation',
  },
  // { key: 'role', icon: 'icon-wodeyinhangka', name: 'role', path: '/userAccount?activeCode=role' },
  {
    key: 'changePassword',
    icon: 'icon-wodeshenpishouquan',
    name: 'changePassword',
    path: '/userAccount?activeCode=changePassword',
  },
  {
    key: 'userGroupInformation',
    icon: 'icon-xiugaimima',
    name: 'userGroupInformation',
    path: '/userAccount?activeCode=userGroupInformation',
  },
];

// 系统设置
export const systemSettingList: ISettingMenuItem[] = [
  {
    key: 'ThemeColor',
    icon: 'icon-xitongzhutise',
    name: 'ThemeColor',
    path: '/systemConfig?activeCode=themeColor',
    select: true,
    options: [],
  },
  {
    key: 'overallStyleSetting',
    icon: 'icon-gongzuochangjing',
    name: 'overallStyleSetting',
    path: '/systemConfig?activeCode=completeStyle',
    ref: 'overallStyleOpen',
    select: true,
    options: [
      { label: '黑色', value: 'overallStyleBlackTab' },
      { label: '白色', value: 'overallStyleWhiteTab' },
    ],
    value: 'overallStyleWhiteTab',
  },
  {
    key: 'systemLanguageSetting',
    icon: 'icon-xitongyuyan',
    name: 'systemLanguageSetting',
    path: '/systemConfig?activeCode=systemLanguage',
    select: true,
    options: [
      {
        value: 'zh_CN',
        label: '简体中文',
        key: 'languageChinese',
      },
      {
        value: 'zh_TW',
        label: '繁体中文',
        key: 'languageTradition',
      },
      {
        value: 'en_US',
        label: 'English',
        key: 'languageEnglish',
      },
    ],
    value: 'languageChinese',
  },
  {
    key: 'defaultMenuSetting',
    icon: 'icon-xiaoxipeizhi',
    name: 'defaultMenuSetting',
    path: '/systemConfig?activeCode=defaultMenu',
    select: true,
    options: [
      { label: '我的收藏', value: 'menuOptionRecently' },
      { label: '所有功能', value: 'menuOptionMenu' },
    ],
    value: 'menuOptionRecently',
  },
  {
    key: 'clearCache',
    icon: 'icon-xiaoxipeizhi',
    name: 'clearCache',
    path: '',
  },
];
