/*
 * @Author: ww
 * @Date: 2024-02-27 14:44:02
 * @LastEditTime: 2024-02-27 09:57:43
 * @LastEditors: ww
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\theme.ts
 */
import request from '../utils/request';

// 获取浏览器和全局主题配置
export const getBrowserAndGlobalTheme = () => {
  return request<any[]>({
    url: '/portal/data-dict/getBrowserAndGlobalTheme',
    method: 'post',
  });
};

// 获取浏览器和有效的全局主题配置
export const getBrowserAndValidGlobalTheme = () => {
  return request<any[]>({
    url: '/portal/data-dict/getBrowserAndValidGlobalTheme',
    method: 'post',
  });
};

// 单个更新
export const updateByCode = (data: any) => {
  return request<any[]>({
    url: `/portal/data-dict/updateByCode`,
    method: 'post',
    data: data,
  });
};

// 批量更新
export const updateBatch = (data: any) => {
  return request<any[]>({
    url: '/portal/data-dict/updateBatch',
    method: 'post',
    data: data,
  });
};
