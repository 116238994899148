/*
 * @Author: jld
 * @Date: 2023-02-16 10:05:33
 * @LastEditTime: 2023-06-16 10:35:05
 * @LastEditors: atwLee
 * @Description:
 * @FilePath: /erp-finance-biz-portal-react-start/src/utils/uuid.ts
 */
/**
 * uuid生成工具类
 * 默认生成32位uuid
 */

import { isNumber } from 'lodash-es';

export const uuid = () => {
  const s: Array<any> = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  // s[8] = s[13] = s[18] = s[23] = "-";
  s[8] = '';
  s[13] = '';
  s[18] = '';
  s[23] = '';
  return s.join('');
  // uuid = uuid.replace(new RegExp('-', 'g'), '');
};

/**
 * @description: 子应用向门户发消息打开新页面（动态路由）的的menuId。之前是用的uuid，导致打开同一个页面，会生成两个页签。换种生成方式，不打开新的，切换到已打开的。
 * @param {string} S 方法逻辑：参数（其实就是子应用要打开的路由url）大写化-》找出A-Z及？&有几个，来组成url
 * @return {*}
 */
export const compress = (S: string) => {
  const strUpper = S.toLocaleUpperCase();

  const isPush = (s: string) => {
    const arr: string[] = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'S',
      'Y',
      'Z',
    ];
    return arr.indexOf(s) > -1;
  };

  let str = '';
  let number = 0;
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < strUpper.length; i++) {
    const element = strUpper[i];
    if (isPush(element)) {
      str = str + element;
    }
    if (!isNaN(Number(element))) {
      number = number + (Number(element) + i) + Number(element) * i;
    }
  }

  let result = '';
  const ObjMap = new Map<string, number>();
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < str.length; i++) {
    const element = str[i];
    if (ObjMap.has(element)) {
      ObjMap.set(element, ObjMap.get(element)! + 1);
    } else {
      ObjMap.set(element, 1);
    }
  }
  for (const [key, value] of ObjMap as any) {
    result = result + `${key}${value}`;
  }

  return result + number;
};
