/*
 * @Author: WGF
 * @Date: 2023-02-09 20:48:58
 * @LastEditors: YL
 * @LastEditTime: 2023-12-21 17:42:13
 * @FilePath: /portal-react-start/src/stores/components/AppState.tsx
 * @Description: 文件描述
 */
import { action, computed, observable } from 'mobx';
import { ITopMenuItemProps } from '../type';
import { MenuItem } from '../../types';
import { menu } from 'src/mock/menu';
import { THEME } from 'src/constant/theme';
import { defaultThemeToken } from 'src/constant/defaultThemeToken';

class AppState {
  // 顶部菜单列表
  @observable public topMenuList: ITopMenuItemProps[] = [];

  // 顶部菜单当前活动项
  @observable public headerNavActive: Partial<ITopMenuItemProps> = {};

  // 个人信息下拉菜单列表
  @observable public userConfigMenuList = [];

  // 主题
  @observable public theme =
    localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : THEME.PRIMARY_COLOR;

  // 主题Token
  @observable public themeToken =
    localStorage.getItem('themeToken') !== null && localStorage.getItem('themeToken')?.indexOf('{') !== -1 ? JSON.parse(localStorage.getItem('themeToken') as string) : {};

  // 主题风格
  @observable public themStyle = 'overallStyleBlackTab';
  // 左侧菜单列表
  @observable public leftMenuList: MenuItem[] = [];

  @observable public collectionMenuList: MenuItem[] = [];

  // 预执行的app进行记录
  @observable public execAppLoadedState: Record<string, boolean> = {};

  // 点击某个菜单的是否是在Loading
  @observable public menuLoadingStatus = false;

  // 是否初始化完成 - 默认没有初始化完成
  @observable public appEnvhasInited = false;

  // 设置初始化状态
  @action.bound
  public setAppEnvInited(status: boolean) {
    this.appEnvhasInited = status;
  }

  // 设置菜单点击后的状态
  @action.bound
  public setMenuLoadingStatus(status: boolean) {
    this.menuLoadingStatus = status;
  }

  @action.bound
  public setTopMenuList(list: any[]) {
    this.topMenuList = list;
  }

  @action.bound
  public setHeaderNavActive(activeMenu: ITopMenuItemProps) {
    this.headerNavActive = activeMenu;
  }

  @action.bound
  public setTheme(value: string) {
    this.theme = value;
  }

  @action.bound
  public setThemeToken(value: typeof this.themeToken & { [key: string]: string | number }) {
    this.themeToken = value;
  }

  @action.bound
  public setThemStyle(value: string) {
    this.themStyle = value;
  }
  @action.bound
  public setLeftMenuList(list: MenuItem[]) {
    this.leftMenuList = list;
  }

  @action.bound
  public setCollectionMenuList(list: MenuItem[]) {
    this.collectionMenuList = list;
  }

  @action.bound
  public setExecAppLoadedState(name: string, state: boolean) {
    this.execAppLoadedState[name] = state;
  }
}

const appState = new AppState();
export default appState;
