/*
 * @Author: LHY
 * @Date: 2023-11-10 12:35:41
 * @LastEditTime: 2023-12-01 17:33:52
 * @LastEditors: lmy
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\user.ts
 */
import { clearCookiesAll, setItem } from './storage';
import { STORAGE_KEY } from 'src/constant/storageKey';
export const loginOut = () => {
  localStorage.removeItem(STORAGE_KEY.HasVisited);
  clearCookiesAll();
  location.href = `${location.origin}/api/login/logout`;
  // 标志是否处于登出状态
  setItem(STORAGE_KEY.LOGOUT, 'true');
};
