/*
 * @Author: jld
 * @Date: 2023-03-09 19:46:48
 * @LastEditTime: 2023-11-24 17:08:30
 * @LastEditors: lmy
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\constant\language.ts
 */
export interface IItem {
  CODE: string;
  NAME: string;
}
export const LANG_TYPE = [
  {
    CODE: 'zh_CN',
    NAME: '简体中文',
    VALUE: 'languageChinese'
  },
  {
    CODE: 'zh_TW',
    NAME: '繁体中文',
    VALUE: 'languageTradition'
  },
  {
    CODE: 'en_US',
    NAME: 'English',
    VALUE: 'languageEnglish'
  },
];

export interface ILangType {
  [key: string]: string;
}
export const LANG_OBJECT: ILangType = {
  zh_CN: '简体中文',
  zh_TW: '繁体中文',
  en_US: 'English',
};
