/*
 * @Author: yar
 * @Date: 2023-02-08 20:24:57
 * @LastEditTime: 2024-03-14 11:41:10
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\index.tsx
 */
import React, { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { IHeaderProps } from './type';
import IconFont from '../../../components/IconFont';
import Navigation from './components/Navigation';
import GlobalSearch from './components/GlobalSearch';
import UserInfo from './components/UserInfo';
import UserSystemDrawer from './components/UserSystemDrawer';
import OperateBtn from './components/OperateBtn';
import SettingDrawer from '../../../components/SettingDrawer';
import FunctionDrawer from '../../../components/FunctionDrawer';
import classnames from 'classnames';

import styles from './index.module.less';
import heardLogo from 'src/asset/heardLogo.svg';
import logoWhite from 'src/asset/logoWhite.png';

const IndexPage: React.FC<IHeaderProps> = inject(
  'AppState',
  'UserStore',
  'TagsViewStore',
  'DrawerStore',
)(
  observer(
    forwardRef((props, ref) => {
      // 【1】props
      const {
        AppState: { themStyle, menuLoadingStatus },
        DrawerStore: { setDrawerOpen, drawerOpen, setDrawerOpenStatus, drawerOpenStatus },
      } = props;
      // 鼠标是否在汉堡包hover
      const isHamBurgerHover = useRef<any>(false);
      const imgLogoRef = useRef<any>(null);
      // 【2】useState
      const showAbilityDrawer = () => {
        setDrawerOpenStatus('opening');
        setDrawerOpen(!drawerOpen);
      };

      const [showSetting, setShowSetting] = useState(false);

      // 【3】useMemo

      // 【4】useImperativeHandle
      useImperativeHandle(ref, () => {
        return {};
      });

      // 【5】useEffect

      // useEffect(() => {
      //   setAbilityOpen(drawerOpen);
      // }, [drawerOpen]);

      // 【6】useCallback
      // 设置汉堡包Icon className

      const hamBurgerClassName = classnames({
        [styles['portalHeaderWrapTitleIcon']]: true,
        [styles['active']]: drawerOpen,
      });
      let headerLogSrc = `${window.location.origin}/portal/images/heardLogo.svg`;
      let headerWhiteLogSrc = `${window.location.origin}/portal/images/logoWhite.svg`;
      if ((window as any)?.reactEnv?.APP?.logo) {
        headerLogSrc = (window as any)?.reactEnv?.APP?.logo;
      }
      if ((window as any)?.reactEnv?.APP?.whiteLogo) {
        headerWhiteLogSrc = (window as any)?.reactEnv?.APP?.whiteLogo;
      }
      let titleStyle = {};
      if ((window as any)?.reactEnv?.APP?.logoTextOptions?.style) {
        titleStyle = (window as any)?.reactEnv?.APP?.logoTextOptions?.style;
      }
      console.log('headerLogSrc', headerLogSrc, 'headerWhiteLogSrc', headerWhiteLogSrc);
      return (
        <div
          className={
            themStyle === 'overallStyleBlackTab'
              ? `${styles['portalHeaderWrap']}`
              : `${styles['portalHeaderWrap']} ${styles['portalHeaderWrapOtherThem']}`
          }
        >
          {/* 标题区 */}
          <div className={styles['portalHeaderWrapTitleArea']}>
            <div
              className={hamBurgerClassName}
              /*    onMouseEnter={() => {
                              isHamBurgerHover.current = true;
                              let hamBurgerDebounce = debounce(() => {
                                if (!drawerOpen && isHamBurgerHover.current) {
                                  showAbilityDrawer();
                                }
                              }, 400);
                              hamBurgerDebounce();
                            }}
                            onMouseLeave={() => {
                              isHamBurgerHover.current = false;
                            }} */
              onClick={() => {
                console.log('菜单是否为加载状态', menuLoadingStatus);
                if (menuLoadingStatus) {
                  return;
                }
                if (drawerOpenStatus !== 'open') {
                  showAbilityDrawer();
                } else if (drawerOpenStatus === 'open') {
                  setDrawerOpenStatus('close');
                  setDrawerOpen(false);
                }
              }}
            >
              <IconFont type="icon-daohanglan-xuanfu" />
            </div>
            <div className={styles['portalHeaderWrapTitleText']} style={titleStyle}>
              {(window as any)?.reactEnv?.APP?.logoTextOptions &&
              (window as any)?.reactEnv?.APP?.logoTextOptions?.text ? (
                (window as any)?.reactEnv?.APP?.logoTextOptions?.text
              ) : (
                <img
                  ref={imgLogoRef}
                  onError={() => {
                    imgLogoRef.current.src = themStyle === 'overallStyleBlackTab' ? heardLogo : logoWhite;
                  }}
                  onLoad={() => {
                    imgLogoRef.current.className = styles['portalLogo'];
                  }}
                  src={themStyle === 'overallStyleBlackTab' ? headerLogSrc : headerWhiteLogSrc}
                  className={`${styles['portalLogo']} ${styles['portalLogoHidden']}`}
                />
              )}
            </div>
          </div>
          {/* 导航区 */}
          <Navigation />
          {/* 右侧区 */}
          <div className={styles['portalHeaderWrapRightArea']}>
            {/* 全局搜索 */}
            <GlobalSearch />
            {/* 按钮操作区 */}
            <OperateBtn />
            {/* 用户信息 */}
            {/* <UserInfo setShowSetting={setShowSetting} /> */}
            <UserSystemDrawer setShowSetting={setShowSetting} />
          </div>
          {/* 设置菜单抽屉 */}
          {/* <SettingDrawer
            open={showSetting}
            onClose={() => {
              setShowSetting((p) => !p);
            }}
          /> */}
          {/* 功能菜单抽屉 */}
          {/* <FunctionDrawer /> */}
        </div>
      );
    }),
  ),
);
export default IndexPage;
