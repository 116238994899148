/*
 * @Author: jld
 * @Date: 2023-02-20 18:27:09
 * @LastEditTime: 2023-03-10 13:43:24
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\UserLogin\components\LoginPanel\viewModal.tsx
 */
import { useIntl } from 'react-intl';

export interface ILoginItemTipsTye {
  namePlaceholderTips: string;
  userNameTips: string;
  passWordTips: string;
  passwordMessageTips: string;
}
export interface ILoginTipsTye {
  accountLogin: ILoginItemTipsTye;
  quickLogin: ILoginItemTipsTye;
}

export function loginInfoTipsFun() {
  const intl = useIntl();
  let data: ILoginTipsTye = {
    accountLogin: {
      namePlaceholderTips: intl.formatMessage({ id: 'loginInfoTips' }),
      userNameTips: intl.formatMessage({ id: 'userNameTips' }),
      passWordTips: intl.formatMessage({ id: 'passWordTips' }),
      passwordMessageTips: intl.formatMessage({ id: 'passWordTips' }),
    },
    quickLogin: {
      namePlaceholderTips: intl.formatMessage({ id: 'quickLoginLoginInfoTips' }),
      userNameTips: intl.formatMessage({ id: 'quickLoginUserNameTips' }),
      passWordTips: intl.formatMessage({ id: 'quickLoginPassWordTips' }),
      passwordMessageTips: intl.formatMessage({ id: 'quickLoginPassWordTips' }),
    },
  };
  return data;
}
export const tabsItem = [
  { name: '账号登录', code: 'accountLogin', id: 'accountLogin' },
  { name: '快捷登录', code: 'quickLogin', id: 'quickLogin' },
];

export const passWorldLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
