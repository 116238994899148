/*
 * @Author: jld
 * @Date: 2023-02-22 20:25:48
 * @LastEditTime: 2023-02-22 20:30:10
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\regularCheck.TS
 */
export function isPhoneNumber(phoneNumber: string) {
  let pred = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  return pred.test(phoneNumber);
}
