/*
 * @Author: atwlee
 * @Date: 2023-10-12 11:23:20
 * @LastEditors: atwlee
 * @LastEditTime: 2024-01-23 16:27:24
 * @Description: 封装一层select，符合该工程的select样式。注意，高度为0.32rem（32px）size属性没用了
 * @FilePath: /shared-business-service-react-apps/apps/portal/src/components/SelectWrapper/index.tsx
 */
import React from 'react';
import { forwardRef } from 'react';
import { Select } from 'antd';
import type { RefSelectProps, SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import classNames from 'classnames';
import PuiIconFont from '../PuiIconFont';
import styles from './index.module.less';

declare const SelectWrapperType: <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType> & {
    children?: React.ReactNode;
  } & {
    ref?: React.Ref<RefSelectProps>;
  },
) => React.ReactElement;

interface SelectWrapperProps<ValueType, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>
  extends SelectProps<ValueType, OptionType> {
  children?: React.ReactNode;
  ref?: React.Ref<RefSelectProps>;
}

function Index<ValueType = any, OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
  props: SelectProps<ValueType, OptionType>,
  ref: React.Ref<RefSelectProps>,
) {
  return (
    <Select
      suffixIcon={
        <PuiIconFont
          type="icon-xiala"
          style={{
            color: '#86909C',
            rotate: '90deg',
            fontSize: '0.08rem',
          }}
          className="anticon-down ant-select-suffix"
        />
      }
      {...props}
      ref={ref}
      className={classNames([styles.select, props.className])}
      popupClassName={classNames([styles.popupClassName, props.popupClassName])}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
    />
  );
}

export default forwardRef(Index) as typeof SelectWrapperType;
