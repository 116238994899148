/*
 * @Author: jld
 * @Date: 2023-07-12 19:21:29
 * @LastEditTime: 2023-11-17 18:45:49
 * @LastEditors: lmy
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\settings.ts
 */
import request from 'src/utils/request';
import { IOrganizations } from './types/settings';

// 机构设置
export const getOrganizations = () => {
  return request<IOrganizations[]>({
    url: '/uc-core/user/organizations',
    method: 'post',
  });
};

// 获取系统设置
export const getSystemSettings = () => {
  return request({
    url: '/portal/sys/config/list',
    method: 'post',
  });
};

// 系统设置更新
export const updateSystemSettings = (params: {configCode: string, configValueCode: string}) => {
  return request({
    url: '/portal/sys/config/update',
    method: 'post',
    data: params
  });
};
