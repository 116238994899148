/*
 * @Author: jld
 * @Date: 2023-03-01 16:45:51
 * @LastEditTime: 2023-03-13 09:26:46
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\constant\menu.ts
 */
/**
 * 表单类型
 * formType
 * config里面
 */
export const LOAD_TYPE = {
  REACT_CODE: 'component', // react编码
  IFRAME: 'iframe', // iframe嵌套url
  Micro_APP: 'microApp', // 微应用
};

/**
 * 展示方式
 * displayType
 * config里面
 */
export const OPEN_TYPE = {
  OPEN_TAB: 'openTab', // 打开tab
  OPEN_BROWSER_TAB: 'browserTab', // 打开浏览器tab页
  OPEN_WINDOW: 'openWindow', // 弹窗
  OPEN_FULL_SCREEN: 'fullScreen', // 全屏
};

/**
 * 类型
 */
export const OPEN_TYPE_ARRAY = ['browserTab', 'openWindow', 'fullScreen'];
