/*
 * @Author: jld
 * @Date: 2023-03-09 18:04:56
 * @LastEditTime: 2023-11-24 17:09:21
 * @LastEditors: lmy
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\stores\components\InternationalizationStore.tsx
 */
import { action, observable } from 'mobx';

class InternationalizationStore {
  // 默认中文
  @observable public lang = 'zh_CN';
  // 默认菜单
  @observable public overallDefaultMenu = 'menuOptionRecently';

  @action.bound
  public setLang(state: string) {
    this.lang = state;
  }

  @action.bound
  public setDefaultMenu(state: string) {
    this.overallDefaultMenu = state;
  }
}

const internationalizationStore = new InternationalizationStore();
export default internationalizationStore;
