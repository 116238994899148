/*
 * @Author: jld
 * @Date: 2023-02-16 14:30:59
 * @LastEditTime: 2023-04-04 15:03:49
 * @LastEditors: ZLL
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\routes\routes.tsx
 */
import { RouteObject } from 'react-router-dom';
import Layout from '../layout';
import CustomDashboard from 'src/pages/CustomDashboard';
import EditDashboard from 'src/pages/CustomDashboard/edit';
import UserLogin from 'src/pages/UserLogin';
import ForgetPassWorld from 'src/pages/UserLogin/components/ForgetPassWorld';
import LoginError from 'src/pages/LoginError';

const routes: RouteObject[] = [
  {
    path: '/login',
    element: <UserLogin />,
  },
  {
    path: '/forgetPassWorld',
    element: <ForgetPassWorld />,
  },
  {
    path: '/customDashboard',
    element: <CustomDashboard />,
  },
  {
    path: '/editDashboard/:id',
    element: <EditDashboard />,
  },
  {
    path: '/error',
    element: <LoginError />,
  },
  {
    path: '*',
    element: <Layout />,
  },
];

export default routes;
