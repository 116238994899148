/*
 * @Author: ZY
 * @Date: 2023-02-21 17:41:32
 * @LastEditors: jld
 * @LastEditTime: 2023-02-23 15:34:58
 * @FilePath: \erp-finance-biz-portal-react-start\src\constant\theme.ts
 * @Description: 文件描述
 */
export const THEME = {
    PRIMARY_COLOR: 'rgba(211, 48, 3, 1)',
    // css变量对应的css文本
    CSS_VAR_TEXT: [{
        cssVariable: '--custom-wrapper-bg-color',
        cssText: `
    .ant-modal .ant-modal-header,.ant-modal .ant-modal-content,
    .ant-select-dropdown,
    .ant-select-tree,
    .ant-tree-select-dropdown .ant-select-tree,
    .ant-color-picker-trigger,
    .ant-popover .ant-popover-inner,
    .ant-popover .ant-popover-arrow:before{
      background: var(--custom-wrapper-bg-color);
       background-color: var(--custom-wrapper-bg-color);
    }`
    }, {
        cssVariable: '--custom-input-color',
        cssText: `
     .ant-select-dropdown,
     .ant-select-tree,
     .ant-tree-select-dropdown .ant-select-tree .ant-select-tree-node-content-wrapper, 
     .ant-tree-select-dropdown .ant-select-tree .ant-select-tree-checkbox+span,
     .ant-select-dropdown .ant-select-item,
     .ant-tree-select-dropdown .ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper,
     .ant-input-outlined,
     .ant-input-outlined[disabled],
     .ant-input::placeholder,.ant-select-single .ant-select-selector{
       color: var(--custom-input-color, inherit);
      }`
    }, {
        cssVariable: '--custom-input-bg-color',
        cssText: `
     .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector,
     .ant-input-outlined,.ant-input-outlined[disabled],.ant-input-outlined:hover,
     .ant-input-outlined:focus,
     .ant-input-outlined:focus-within{
       background: var(--custom-input-bg-color,#fff);
      }`
    }, {
        cssVariable: '--custom-icon-color',
        cssText: `
     .anticon{
     color:var(--custom-icon-color)
    }
    .ant-modal-close{
      color: var(--custom-icon-color);
      &:hover{
        color: var(--custom-icon-color);
      }
    }`
    }, {
        cssVariable: '--custom-label-color',
        cssText: `
      .ant-radio-wrapper,
      .ant-popconfirm-description,
      .ant-space-item,
      .ant-color-picker-trigger .ant-color-picker-trigger-text{
        color: var(--custom-label-color);
      }
     .ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
        border-color: var(--custom-label-color);
      }`
    }, {
        cssVariable: '--custom-btn-primary-bg-color',
        cssText: `
    .ant-btn-primary {
      background: var(--custom-btn-primary-bg-color);
    }
    .ant-switch.ant-switch-checked{
     background: var(--custom-btn-primary-bg-color);
    }`
    }, {
        cssVariable: '--custom-btn-primary-bg-color-hover',
        cssText: `
     .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover{
          background: var(--custom-btn-primary-bg-color-hover);
        }`
    }, {
        cssVariable: '--custom-btn-default-bg-color',
        cssText: `
      .ant-btn-default,.ant-btn-default:disabled,.ant-btn-default.ant-btn-disabled {
          background: var(--custom-btn-default-bg-color);
        }
         .ant-switch{
        background: var(--custom-btn-default-bg-color);
        }`
    }, {
        cssVariable: '--custom-btn-default-bg-color-hover',
        cssText: `
     .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
          background: var(--custom-btn-default-bg-color-hover);
        }`
    }, {
        cssVariable: '--custom-btn-default-color',
        cssText: `
      .ant-btn-default,.ant-btn-default:disabled,.ant-btn-default.ant-btn-disabled {
          color: var(--custom-btn-default-color);
        }`
    }, {
        cssVariable: '--custom-btn-default-color-hover',
        cssText: `
     .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
          color: var(--custom-btn-default-color-hover);
        }`
    }]
};
