/*
 * @Author: wh
 * @Date: 2023-08-16 11:59:21
 * @LastEditors: YL
 * @LastEditTime: 2024-01-09 17:21:46
 * @FilePath: /portal-react-start/src/constant/helpFileInfo.ts
 * @Description: 搜索帮助文档数据
 */

export interface DocumentModel {
  key: string;
  name: string;
  content: string;
  code: string;
}
export const documentData: DocumentModel[] = (window as any)?.reactEnv?.helpDocumentList && (window as any)?.reactEnv?.helpDocumentList?.length > 0 ? (window as any)?.reactEnv?.helpDocumentList : [
  {
    key: 'row1',
    name: '普联信创ERP用户操作手册-UAT环境登录V1.3.pdf',
    content: '普联信创ERP用户操作手册-UAT环境登录V1.3.pdf',
    code: '普联信创ERP用户操作手册-UAT环境登录V1.3.pdf',
  },
  {
    key: 'row2',
    name: '普联信创ERP用户操作手册-总账、报表部分v1.4.0219.pdf',
    content: '普联信创ERP用户操作手册-总账、报表部分v1.4.0219.pdf',
    code: '普联信创ERP用户操作手册-总账、报表部分v1.4.0219.pdf',
  },
  {
    key: 'row3',
    name: '普联信创ERP用户操作手册-资产部分V1.4.0108.pdf',
    content: '普联信创ERP用户操作手册-资产部分V1.4.0108.pdf',
    code: '普联信创ERP用户操作手册-资产部分V1.4.0108.pdf',
  },
  {
    key: 'row4',
    name: '普联信创ERP用户操作手册-用户中心部分V1.4.0109.pdf',
    content: '普联信创ERP用户操作手册-用户中心部分V1.4.0109.pdf',
    code: '普联信创ERP用户操作手册-用户中心部分V1.4.0109.pdf',
  },
  {
    key:'row5',
    name:'普联信创ERP用户操作手册-管控中心部分V1.4.0223.pdf',
    content: '普联信创ERP用户操作手册-管控中心部分V1.4.0223.pdf',
    code: '普联信创ERP用户操作手册-管控中心部分V1.4.0223.pdf',
  }
];
