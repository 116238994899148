/*
 * @Author: jld
 * @Date: 2023-03-09 18:04:03
 * @LastEditTime: 2024-03-12 15:10:23
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\locale\zhCN.ts
 */
export default {
  projectName: '普联ERP门户中心',
  // 登录界面
  scanCodeLogin: '扫码登陆',
  OpenCodeLogIn: '打开普联APP扫描二维码登录',
  DownloadPanSoftAPP: '下载普联APP',
  target: '为众多领域提供深入的特定方案',
  learnMore: '点击详细了解普联ERP产品',
  viewDetails: '查看详情',

  accountLogin: '账号登录',
  quickLogin: '快捷登录',
  forgetPassword: '忘记密码',
  login: '登录',
  loginPrompt: '登录视为您已同意第三方账号绑定协议、服务条款、隐私政策',

  completeSecurityVerification: '请完成安全验证',
  changeOne: '换一张',
  completePuzzle: '拖动滑块完成拼图',
  UnderLoad: '加载中...',
  getVerificationCode: '获取验证码',
  afterSecond: '后重发',
  loginInfoTips: '用户名/员工编号/手机号/邮箱',
  userNameTips: '请输入账户名',
  passWordTips: '请输入密码',
  quickLoginLoginInfoTips: '请输入手机号',
  quickLoginUserNameTips: '请输入正确的手机号',
  quickLoginPassWordTips: '请输入手机验证码',
  // 抽屉菜单相关
  myCollection: '我的收藏',
  allFunctions: '所有功能',
  recentVisit: '最近访问',
  recentDocument: '最近单据',
  return: '返回',
  // 设置
  accountSetting: '账号设置',
  systemSetting: '系统设置',
  exitAccount: '退出账号',
  overallStyleSetting: '整体风格',
  ThemeColor: '主题色',
  systemLanguageSetting: '系统语言',
  defaultMenuSetting: '默认菜单',
  customizeHomePageKanBan: '自定义首页看板',
  customizeThemeStyle: '自定义主题样式',

  // 账号设置
  baseInformation: '基本信息',
  userGroupInformation: '用户组信息',
  role: '所属角色',
  securitySet: '修改密码',

  // 清楚缓存
  clearCache: '清除缓存',
  clearingCacheSucceeded: '清除缓存成功',
  sureClearingCache: '确定清理缓存?',
  clearTip: '清除erp前端缓存,不影响系统正常使用',
  determine: '确定',
  cancel: '取消',

  // 自定义模板按钮
  newAdd: '新增',
  edit: '编辑',
  delete: '删除',
  finish: '完成',
  save: '保存',
  selectTemplate: '请选择预置模板布局',
  dashboardTips: '您可以拖动小部件进行添加、移动等自定义操作',
  enterKanBanName: '请输入看板名称',
  selectTemplateTips: '您可以选择预置模版布局',
  dragWidget: '或自行拖动小部件进行添加、移动等自定义操作',
  selectSave: '自定义看板至少含有一个部件，请选择后保存',
  // 搜索
  searchTips: '在此输入搜索内容',
  notFound: '没有找到相关内容',
  stayTuned: '敬请期待',
  recentSearch: '最近搜索',
  guessSearch: '猜你想搜',
  clearHistory: '是否清空历史记录?',
  close: '关闭',
  historyCleared: '已清空历史记录',
  recentAccess: '最近访问功能',
  enterKeywords: '请输入关键词',
  detailedSearchResults: '详细搜索结果',
  detailedSearch: '详细搜索',
  // 关闭标签
  closeLeft: '关闭左侧',
  closeRight: '关闭右侧',
  closeOther: '关闭其他',
  closeAll: '关闭所有',
  pinTab:'固定页签',
  unPinTab:'解除固定页签',
  // 忘记密码
  enterAccountNumber: '输入账号',
  resetPassword: '重置密码',
  passwordsDifferent: '两者密码不一致,请重新输入',
  enterSMSCode: '请输入短信验证码',
  enterVerification: '请输入验证码',
  confirmPassword: '请确认新密码',
  resetSuccessfully: '重置成功！',
  nextStep: '下一步',
  returnLogin: '秒自动返回登录',

  // 登录
  aboutUs: '关于我们',
  contactUs: '联系我们',
  newsUpdate: '新闻动态',
  softwareProduct: '软件产品',
  cloudService: '云服务',
  domainSolution: '领域解决方案',
  typicalCase: '典型案例',
  serviceSupport: '服务支持',
  allRightsReserved: '版权所有 © 2001- 2023 普联软件股份有限公司 · 鲁ICP备13012779号-5',

  // 个人设置
  personageTitle: '基本信息',
  personageName: '姓名',
  headSculpture: '头像',
  userName: '用户名',
  employeeNumber: '员工编号',
  personageCertificateStatus: '认证状态',
  personageSbmit: '提交认证',
  personageAccountID: '账号ID',
  personageReimbursementLevel: '报销级别',
  personageHRNumber: 'HR编号',
  personageRegistrationDate: '注册时间',
  personageAddressCompany: '地区公司',
  personageAddressCompanyOther: '地区公司2',
  personageDepartment: '部门',
  personageMail: '邮箱',
  personageMailRules1: '无效的邮箱格式!',
  personageMailRules2: '请输入您的邮箱!',
  personagePhone: '手机号',
  personagePhoneRules1: '手机号码格式错误',
  personagePhoneRules2: '请输入您的联系电话!',
  personageFax: '传真号码',
  personageFaxRules1: '传真格式错误',
  personageFaxRules2: '请输入您的传真号码!',
  personageSave: '保存',
  personageSaveSuccess: '保存成功',
  theConfirmationIsDifferentFromTheNewPassword: '确认密码与新密码不同',
  theOldPasswordIsTheSameAsTheNewPassword: '新密码与旧密码相同',
  personageUploadingFormat: '只能上传JPG/PNG文件!',
  personageUploadingSize: '图像必须小于2MB!',

  extendedInformation: '扩展信息',
  sex: '性别',
  birthday: '生日',
  idType: '证件类型',
  idNumber: '证件号码',
  nativePlace: '籍贯',
  address: '通讯地址',

  personageSettings: '账号设置',
  personagePasswordSetting: '密码设置',
  personageMessageSetting: '消息设置',
  personageOriginalPassword: '原密码',
  personageNewsPassword: '新密码',
  personagePleaseEnter: '请输入',
  personageAffirm: '确认新密码',
  personagePrompt: '是否跳转到登录页面',
  // 更多
  TopMore: '更多',
  // 顶部导航全部
  TopAll: '全部',
  // 没有找到相关内容
  NoFound: '没有找到相关内容',
  // 大事件组件
  MajorEventsTitle: '大事件',
  MajorEventsChange: '换一批',
  // 待办任务
  ToDoTasks: '待办任务',
  // 最近使用
  RecentlyUsed: '最近使用',
  // 快捷应用
  QuickApplication: '快捷应用',
  QuickApplicationTitle: '添加快捷应用',
  SearchAppName: '搜索应用名称',
  DragAndDropToSort: '拖拽进行排序',
  functions: '功能',
  noFunction: '暂无功能',
  // 通知提醒
  Notification: '通知提醒',
  // 全部已读
  AllRead: '全部已读',
  allChoose: '全选',
  noChoose: '不选',
  noRead: '未读',
  read: '已读',
  mark: '标记为',
  markUnread: '标记未读',
  markRead: '标记已读',
  // 帮助文档
  bills: '单据',
  message: '消息',
  searchKeywords: '搜索关键词',
  HelpDocuments: '帮助文档',
  HelpNovices: '新手帮助',
  HelpPractices: '最佳实践',
  // 待办任务表格标题
  TableNo: '序号',
  TableState: '状态',
  TableTaskNo: '任务编号',
  TableTaskName: '任务名称',
  TableLastTime: '最晚完成时间',
  TablePerson: '发起人',
  TableOperate: '操作',

  mechanismSwitching: '机构切换',
  messageCenter: '消息中心',
  workNotice: '工作通知',

  // 安全管理
  modalWarning: '警告',
  changePassword: '修改密码',
  modalCancel: '取消',
  safetyManagement: '安全管理',
  passwordSetting: '密码设置',
  passwordExpired: '密码过期',
  lockedAndInvalid: '用户锁定和失效',
  forceChange: '管理员重置用户密码后，强制用户更改密码',
  cannotSame: '新旧密码不能相同',
  specialCharacter: '密码必须包含特殊字符',
  inclusionDigit: '密码必须包含数字',
  includeCapitalLetters: '密码必须包含大写字母',
  includeLowercaseLetters: '密码必须包含小写字母',
  mixedCase: '密码必须包含混合大小写',
  includeUserName: '密码不能包含用户名',
  repeatingCharacter: '密码不能有重复字符',
  minimumLength: '最短密码长度',
  maximumLength: '最长密码长度',
  expirationTime: '密码过期时间',
  promptChange: '在到期前，以下天数内登录时提示用户登更改密码',
  expiredForcedChange: '密码过期后强制用户更改密码',
  userLock: '限定时间内未登录用户锁定',
  userFailure: '限定时间内未登录用户失效',
  reachNumberLocking: '限定登录失败次数，达到次数锁定',
  lockTime: '登录失败锁定时间',
  passwordHaveExpired: '您的密码已到期，需要修改密码后使用',
  remainingDaysDue: `您的密码还有 {restDays} 天到期，请及时更改密码。`,
  containIllegalChar: '密码中不能包含 ',
  exceptionTips: '异常提示',
  lastLoginInfo: '上次登录信息',
  time: '时间',
  IP: 'IP地址',
  loginAddress: '地点',

  // 主题设置
  customThemeSettings: '自定义主题设置',
  themeNameRequire:'主题名称不能为空',

  query:'查询',
  resetting:'重置',
  processSubmission:'流程提交',
  processReturn:'流程退回',
  processReset:'流程重置',
  processRetrieval:'流程取回',
  myDocuments:'我的单据',
  schedule:'待办',
  handled:'已办',
  completed:'已办结',
};
