/*
 * @Author: ZY
 * @Date: 2023-02-16 11:06:21
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @LastEditTime: 2024-03-18 18:30:37
 * @FilePath: /portal-react-start/src/utils/theme.ts
 * @Description: 主题相关工具类
 */
import {themeModelList, compatibleOspThemeModeList} from 'src/pages/CustomThemeStyle/model';
import {MESSAGE_KEY, THEME} from 'src/constant';
import stores from 'src/stores';
import {TinyColor} from '@ctrl/tinycolor';
import {getAllCSSVariableNames, getElementCSSVariables, loadStyleString} from 'src/utils';
import WujieReact from 'wujie-react';
import {sendMessageUtil} from 'src/components/routerContainer/IFrame/postMessage';
import {getIframeContainEle} from "src/utils/dom";

const {AppState, ThemeStore} = stores;
const {bus} = WujieReact;

export function primaryTransformAlpha(color: string, alpha: string | number) {
    if (color?.startsWith('#')) {
        return (
            'rgba(' +
            parseInt('0x' + color.slice(1, 3), 10) +
            ',' +
            parseInt('0x' + color.slice(3, 5), 10) +
            ',' +
            parseInt('0x' + color.slice(5, 7), 10) +
            ',' +
            alpha +
            ')'
        );
    } else if (color?.startsWith('rgba')) {
        return color.replace(/[\d\.]+\)$/g, `${alpha})`);
    } else if (color?.startsWith('rgb')) {
        return color.replace('rgb', 'rgba').replace(')', `${alpha})`);
    }
    return color;
}

/**
 * 设置iframe html 样式属性
 * @param iframeEl
 * @param themeVars
 */
export function setIframeHtmlCssVarAttr(iframeEl: any, themeVars: any) {
    const iframeContentWindow = iframeEl?.contentWindow;
    if (iframeContentWindow) {
        try {
            if (iframeContentWindow.document) {
                const iframeContentDocument = iframeContentWindow.document;
                Object.entries(themeVars).forEach(([cssVar, value]) => {
                    const cssValue = (typeof value === 'number' ? `${value}px` : value) as string;
                    iframeContentDocument.documentElement.style.setProperty(cssVar, cssValue);
                });
            }
        } catch (error) {
            console.log('无法设置样式，可能是因为跨域限制：', error);
        }
    }
}


/**
 * @description: 修改主题token，同时修改门户和子应用
 * @param {object} newTokenObj
 * @return {*}
 */
export const changeThemeToken = (newTokenObj: { [key: string]: string | number }) => {
    AppState.setThemeToken({
        // ...AppState.themeToken,
        ...newTokenObj,
    });
    setTimeout(() => {
        let cssVars = getAllCSSVariableNames();
        let cssVarsObj = getElementCSSVariables(cssVars, document.documentElement);
        const themeListTemp = Object.entries({
            ...cssVarsObj,
            ...newTokenObj,
        }).map(([key, value]) => {
            const CSSVariable = themeModelList.find((item) => item.key === key)?.CSSVariable ?? key;
            return [CSSVariable, value];
        });
        const theme = Object.fromEntries(themeListTemp);
        // 加上OSP部分css变量
        compatibleOspThemeModeList.forEach((ospThemnTokenItem) => {
            if (!theme[ospThemnTokenItem.CSSVariable]) {
                const CSSVariable = themeModelList.find((item) => item.key === ospThemnTokenItem.key)?.CSSVariable ?? '';
                theme[ospThemnTokenItem.CSSVariable] = theme[CSSVariable];
            }
        });

        // 加载高级配置定义的key转换成对应的css
        let cssText = '';
        let customStyleText = '';
        Object.entries(newTokenObj).forEach(([cssVar, value]) => {
            const cssValue = (typeof value === 'number' ? `${value}px` : value) as string;
            if (cssVar.startsWith('--')) {
                cssText += `${cssVar}:${cssValue};`;
                let findThemeCssVarObj = THEME.CSS_VAR_TEXT.find((findItem) => {
                    return findItem.cssVariable === cssVar;
                });
                if (findThemeCssVarObj?.cssText) {
                    customStyleText += findThemeCssVarObj?.cssText;
                }
            }
        });
        // 增加灰度主题
        if (ThemeStore.grayTheme === '1') {
            cssText += 'filter:grayscale(1)';
        }
        loadStyleString(document.documentElement, `html{${cssText}} ${customStyleText}`, 'currThemeVariable');

        // 过滤空属性
        function filterEmptyProperties(obj: { [key: string]: string | number }) {
            for (let key in obj) {
                if (obj[key] === null || obj[key] === undefined || obj[key] === '' || obj[key] === 'none') {
                    delete obj[key];
                }
            }
            return obj;
        }

        // 通知子应用主题色改变
        bus.$emit(MESSAGE_KEY.THEME_CHANGE, filterEmptyProperties(theme));
        // iframe 子应用的通知
        let iframeElArr = getIframeContainEle();
        if (iframeElArr?.length > 0) {
            for (let i = 0; i < iframeElArr?.length; i++) {
                sendMessageUtil(iframeElArr[i], {
                    type: MESSAGE_KEY.THEME_CHANGE,
                    data: filterEmptyProperties(theme),
                });
                setIframeHtmlCssVarAttr(iframeElArr[i], filterEmptyProperties(theme))
            }
        }
    }, 300);
};

/**
 * @description: 转换themetoken数据
 * @param {object}
 * @return {*}
 */
export const transformedThemeTokenData = (res: any) => {
    const themeTokenList: any = [];
    res.forEach((item: any) => {
        const {content} = item.infoJson;
        const transformedObject = content.reduce((acc: any, item: any) => {
            acc[item.key] = item.value;
            return acc;
        }, {});
        themeTokenList.push({
            key: item.themeId,
            name: item.themeName,
            themeToken: transformedObject,
            value: transformedObject.colorPrimary,
        });
    });
    return themeTokenList;
};

/**
 * 色彩转化
 */
export class TinyColorUtil {
    public static toHexString(baseColor: string, alpha?: string | number) {
        let tinyColorInstance = new TinyColor(baseColor);
        if (alpha) {
            return tinyColorInstance.setAlpha(alpha).toHex8String();
        }
        return tinyColorInstance.toHexString();
    }
}
