/*
 * @Author: ZY
 * @Date: 2023-02-15 20:25:08
 * @LastEditors: LHY
 * @LastEditTime: 2023-11-15 15:31:45
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\microApp.ts
 * @Description: MicroApps 数据加载
 */

import request from '../utils/request';
import { IMicroAppModel, IMicroAppModelType } from './types/microConfig';
import { IMicroAppAddModel } from './types/microConfigAdd';
// 获取为前端配置接口
export const getMicroConfigList = async () => {
  let getData = () => {
    return request<IMicroAppModelType[]>({
      url: '/portal/micro/config/list/system',
      method: 'post',
      data: {
        systemId: '00',
      },
    });
  };
  let requestData = await getData();
  let returnData: IMicroAppModel[] = requestData?.map((resItem: IMicroAppModelType) => {
    let returnItem = {
      ...resItem,
      alive: resItem.alive === '1' ? true : false,
      degrade: resItem.degrade === '1' ? true : false,
      dispatchParentWindow: resItem.dispatchParentWindow === '1' ? true : false,
      preLoadApp: resItem.preLoadApp === '1' ? true : false,
      sync: resItem.sync === '1' ? true : false,
      exec: resItem.preExec === '1' ? true : false,
    };

    const { preExec, ...returnOtherItem } = returnItem;
    return returnOtherItem;
  });
  return returnData;
};

// 删除微前端配置
export const deleteMicroConfig = (data: { id: string }) => {
  return request({
    url: '/portal/micro/config/delete',
    method: 'post',
    data: data,
  });
};

// 修改微前端配置
export const editMicroConfig = (data: Record<string, any>) => {
  return request({
    url: '/portal/micro/config/edit',
    method: 'post',
    data: data,
  });
};

// 新增微前端配置
export const addMicroConfig = (data: IMicroAppAddModel) => {
  return request({
    url: '/portal/micro/config/add',
    method: 'post',
    data: data,
  });
};
