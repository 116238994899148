/*
 * @Author: SJT
 * @Date: 2023年2月6日17:19:43
 * @LastEditors: atwLee
 * @LastEditTime: 2023-06-02 15:48:54
 * @FilePath: /erp-finance-biz-portal-react-start/src/utils/rem.ts
 * @Description: 设置rem（1rem = 100px）
 */
const DESIGN_WIDTH = 1440;
function setHtmlFontsize() {
  const docEl = document.documentElement;
  const w = window.innerWidth;
  docEl.style.fontSize = (w > 1440 ? 100 : (w / DESIGN_WIDTH) * 100).toFixed(3) + 'px';
}
export function setRem() {
  setHtmlFontsize();
  window.addEventListener('resize', setHtmlFontsize);
}
