/*
 * @Author: jld
 * @Date: 2023-02-09 09:10:48
 * @LastEditTime: 2023-03-10 14:46:42
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\CustomDashboard\components\HeadButtonGroup\mockData.ts
 */

import { useIntl } from 'react-intl';

// 按钮组信息
export interface buttonType {
  name: string;
  code: string;
  routes?: string;
}
export function buttonArrayFun() {
  let intl = useIntl();
  let buttonArrays = [
    {
      name: intl.formatMessage({ id: 'newAdd' }),
      code: 'newAdd',
      routes: '',
    },
    {
      name: intl.formatMessage({ id: 'edit' }),
      code: 'edit',
      routes: '',
    },
    {
      name: intl.formatMessage({ id: 'delete' }),
      code: 'delete',
      routes: '',
    },
    {
      name: intl.formatMessage({ id: 'finish' }),
      code: 'finish',
      routes: '',
    },
  ];
  return buttonArrays;
}
