/*
 * @Author: ZY
 * @Date: 2023-03-01 11:23:30
 * @LastEditors: lmy
 * @LastEditTime: 2023-12-01 16:54:36
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\pathOpenTab.ts
 * @Description: 通过路径新增Tab 通用Hooks
 */
import { getMenuItemInMenuListByProperty } from 'src/utils/menu';
import React from 'react';
import layoutChildrenRoutes, { getPathType } from 'src/routes/layoutRouteMap';
import stores from 'src/stores';
import { openMenuItem } from 'src/utils/menu';
import { parse } from 'qs';
import router from 'src/routes/router';
interface IOpenTypeProps {
  tabCurrentPath: string;
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>;
  setActivePath: any;
  locationSearch: string;
  locationPathname: string;
}

/** 
 * 备注：setCurrentPath和setActivePath改为实际打开页签时再设置，
 * 因为对leftMenuList进行了监听，首次执行方法时并没有实际的菜单数据，如果一开始就进行设置，后面再打开对应的menu会出现问题
 */

export default function pathOpenTab({
  tabCurrentPath,
  setCurrentPath,
  setActivePath,
  locationSearch,
  locationPathname,
}: IOpenTypeProps) {
  console.log('===============pathOpenTab====================');
  let searchObj = parse(locationSearch.slice(1));
  // 路径不是当前tab 页签，高亮选中
  if (locationPathname !== tabCurrentPath) {
    // 当前路径在菜单中
    if (getPathType(locationPathname) === 'menu') {
      // 找到路径对应菜单id
      const id = locationPathname.split('/').pop();
      if (id) {
        // 查找到菜单信息
        let menu = getMenuItemInMenuListByProperty(stores.AppState.leftMenuList, 'id', id);
        // 如果菜单有就先找菜单
        if (menu) {
          setCurrentPath(locationPathname);
          setActivePath(locationPathname);
          stores.RecentUseStore.addRecentUse(menu);
          openMenuItem(menu);
        } else {
          // 如果菜单没有id , 是从外面打开。从tagList 查找
          let menu = stores.TagsViewStore.tagList.find((item) => item.id === id);
          if (menu) {
            setCurrentPath(locationPathname);
            setActivePath(locationPathname);
            openMenuItem(menu as any);
          } else {
            /**
             * 实际请求菜单列表结束后，如果再没有菜单id，再跳转到dashboard，
             * 因为在外面打开时菜单返回延迟，若跳转到dashboard，则菜单返回后就不再进行匹配
             * */
            if(stores.AppState.leftMenuList?.length){
              router.navigate('/dashboard');
            }
          }
        }
      }
    } else if (getPathType(locationPathname) === 'local') {
      let menu = layoutChildrenRoutes.find((item) => item.path === locationPathname);
      if (!menu) {
        return;
      }
      setCurrentPath(locationPathname);
      setActivePath(locationPathname);
      if (searchObj.tagTitle) {
        stores.TagsViewStore.addTag({ name: searchObj.tagTitle as string, path: menu.path });
      } else {
        if (menu.name) {
          stores.TagsViewStore.addTag({ name: menu.name, path: menu.path });
        }
      }
    }
  } else {
    // 如果当前Tag和将要打开Tag 相同，判断是否需要刷新
    if (searchObj.reloadTag && searchObj.tagTitle) {
      setCurrentPath(locationPathname);
      setActivePath(locationPathname);
      stores.TagsViewStore.addTag({ name: searchObj.tagTitle as string, path: locationPathname, reloadFlag: true });
    }
  }
}
