/*
 * @Author: WGF
 * @Date: 2023-02-09 20:50:48
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @LastEditTime: 2024-03-18 15:58:31
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\index.tsx
 * @Description: 布局
 */
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { inject, observer } from 'mobx-react';
import Header from './components/Header';
import TagsView from './components/TagsView';
import Aside from './components/Aside';
import styles from './index.module.less';
import RequireAuth from './components/RequireAuth';
import { getMenuListAndCollectionList } from 'src/utils/menu';
import FunctionDrawer from '../components/FunctionDrawer';
import BrowserTip from '../components/BrowserTip';
import { isLowerBrowser } from '../utils/browsers';
import { useNavigate } from 'react-router-dom';
import { getBrowserAndValidGlobalTheme } from 'src/api/systemSetting';
import LowBrowser from 'src/pages/LowBrowser';
import { Cookie_KEY } from 'src/constant/storageKey';
import { getCookies } from 'src/utils/storage';
import { SYSTEM_SETTING } from 'src/pages/SystemSetting/model';
import { idbGetItem } from 'src/utils/storage';

const IndexPage: React.FC<{
  [key: string]: any;
}> = inject(
  'AppState',
  'DashboardStore',
  'UserStore',
  'ThemeStore',
)(
  observer((props) => {
    const {
      AppState: { setTopMenuList, setHeaderNavActive,appEnvhasInited },
      DashboardStore: { dashboardList },
      UserStore: { isLogin, token },
      ThemeStore: { grayTheme },
    } = props;

    const [showType, setShowType] = useState(0);
    const [showBrowserTip, setShowBrowserTip] = useState(true) 
    // const showBrowserTip = localStorage.getItem('showBrowserTip') === '0';
    const erpToken = getCookies(Cookie_KEY.ERP_TOKEN);

    //  获取看板列表
    useEffect(() => {
      if (dashboardList?.length > 0) {
        setTopMenuList(dashboardList);
        setHeaderNavActive(dashboardList[0]);
      }
    }, [dashboardList]);

    useEffect(() => {
      let backgroundUrl: string = window.location.origin;
      document.documentElement.style.setProperty('--background-url', `url(${backgroundUrl}/portal/images/background.jpg)`);
      
      const getShowTip =async () => {
        let value = await idbGetItem('showBrowserTip')
        setShowBrowserTip(value === '0')
      } 
      getShowTip()
      getBrowserAndValidGlobalTheme()
        .then((res) => {
          if (res[SYSTEM_SETTING.BROWSER_LOW_ALERT]?.jsonValue?.type === 1) {
            setShowType(1);
          }
          if (res[SYSTEM_SETTING.BROWSER_LOW_ALERT]?.jsonValue?.type !== 1 || !isLowerBrowser()) {
            getMenuListAndCollectionList();
          }
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    }, []);
    return (
      <RequireAuth>
        {!showBrowserTip && isLowerBrowser() && showType === 1 ? <LowBrowser /> : null}
        {isLogin && appEnvhasInited ? <Layout className={styles['portalMainLayout']}>
          <Header />
          <div className={styles['portalMainBody']}>
            {/* 功能菜单抽屉 */}
            <FunctionDrawer />
            <Aside />
            <TagsView />
          </div>
        </Layout> : null}
        {!showBrowserTip && isLowerBrowser() && showType === 0 && isLogin && appEnvhasInited ? <BrowserTip /> : null}
      </RequireAuth>
    );
  }),
);
export default IndexPage;
