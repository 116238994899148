/*
 * @Author: jld
 * @Date: 2023-02-20 19:05:43
 * @LastEditTime: 2023-03-21 08:58:08
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\login.ts
 */
import request from '../utils/request';

export const getToken = (data: {}) => {
  return request({
    url: '/zz/voucher/osplogin',
    data,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
