/*
 * @Author: wh
 * @Date: 2023-08-17 10:01:23
 * @LastEditors: wh
 * @LastEditTime: 2023-08-17 11:44:54
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\helpDocument.ts
 * @Description: 获取帮助文档
 */
export const helpDocument = () => {
  let fileUrl: string = window.location.origin;
  return fileUrl;
};
