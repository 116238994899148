/*
 * @Author: yar
 * @Date: 2023-02-08 20:24:57
 * @LastEditTime: 2023-09-05 10:54:10
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\OperateBtn\index.tsx
 */
import React, { useCallback, useEffect, useMemo, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { IOperateBtnProps } from './type';
import IconFont from '../../../../../components/IconFont';
import styles from './index.module.less';
import { MenuItem } from 'src/types';
import router from 'src/routes/router';
import stores from 'src/stores';
import { useNavigate } from 'react-router-dom';
import { Button, Popover } from 'antd';
import { getItem, setItem } from 'src/utils';
import { STORAGE_KEY } from 'src/constant';
const IndexPage: React.FC<IOperateBtnProps> = (props) => {
  let { TagsViewStore } = stores;
  let navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  const setFullScreen = () => {
    if (isFullScreen) {
      exitFullscreen();
    } else {
      fullScreen();
    }
    setIsFullScreen((p) => !p);
  };
  function fullScreen() {
    let de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
    }
  }
  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
  const helpStart = () => {
    router.navigate('/helpStart');
  };

  const downLoadContent = () => {
    return (
      <div className={styles.downLoadContent}>
        <div className={styles.tooltip}>内容已导出至【下载中心】，请前往进行下载</div>
        <div className={styles.buttonGroup}>
          <Button
            type="primary"
            onClick={() => {
              setOpen(false);
              router.navigate('/downLoadCenter');
            }}
          >
            去下载
          </Button>
          <Button
            onClick={() => {
              setItem(STORAGE_KEY.DOWNLOAD_TIP, 'false');
              setOpen(false);
            }}
          >
            不再提醒
          </Button>
        </div>
      </div>
    );
  };
  const handleOpenChange = (newOpen: boolean) => {
    let openTemp = getItem(STORAGE_KEY.DOWNLOAD_TIP);
    if (openTemp !== 'false') {
      setOpen(newOpen);
    } else {
      router.navigate('/downLoadCenter');
    }
  };
  return (
    <div className={styles.portalHeaderWrapOperateBtn}>
      <div
        className={`${styles.portalHeaderWrapOperateBtnFullScreen} ${styles.portalHeaderWrapOperateBtnItem}`}
        onClick={setFullScreen}
      >
        <IconFont type="icon-daohanglan-quanping" />
      </div>
      {/* <div */}
      {/*  className={`${styles.portalHeaderWrapOperateBtnHelp} ${styles.portalHeaderWrapOperateBtnItem}`} */}
      {/*  onClick={helpStart} */}
      {/* > */}
      {/*  <IconFont type="icon-daohanglan-bangzhu" /> */}
      {/* </div> */}
      {/*   <div className={`${styles.portalHeaderWrapOperateBtnKefu} ${styles.portalHeaderWrapOperateBtnItem}`}>
        <IconFont type="icon-daohanglan-kefu" />
      </div> */}
      <div
        className={`${styles.portalHeaderWrapOperateBtnMsg} ${styles.portalHeaderWrapOperateBtnItem}`}
        onClick={() => {
          navigate('/messageList', { state: { name: '全部', code: 'all' } });
        }}
      >
        <IconFont type="icon-daohanglan-xiaoxi" />
      </div>
      {/*       <Popover placement="bottom" content={downLoadContent} trigger="click" open={open} onOpenChange={handleOpenChange}>
       */}{' '}
      <div
        className={`${styles.portalHeaderWrapOperateBtnMsg} ${styles.portalHeaderWrapOperateBtnItem}`}
        onClick={() => {
          router.navigate('/downLoadCenter');
        }}
      >
        <IconFont type="icon-xiazai" />
      </div>
      {/*  </Popover> */}
    </div>
  );
};
export default IndexPage;
