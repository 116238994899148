/*
 * @Author: ZLL
 * @Date: 2023-02-06 13:37:13
 * @LastEditors: lmy
 * @LastEditTime: 2023-12-25 16:45:14
 * @FilePath: \portal-react-start\src\components\FunctionDrawer\components\FunctionalGrouping\index.tsx
 * @Description: 所有功能-分级
 */
import React, { useEffect, useRef, useState } from 'react';
import { Anchor } from 'antd';
import FunctionMenu from '../FunctionMenu';
import styles from './index.module.less';
import IconFont from 'src/components/IconFont';
import type { MenuProps } from 'antd/es/menu';
import ExhibitionItem, { ItemType } from 'src/components/ExhibitionItem';
import { inject, observer } from 'mobx-react';
import { MenuItem as IMenuItem } from '../../../../types';
type MenuItem = Required<MenuProps>['items'][number];
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { getCurrentMenuList } from 'src/utils/menu';
import { useLocation } from 'react-router-dom';
import PuiIconFont from 'src/components/PuiIconFont';
export interface ICurrentMenuType {
  item: MenuItem;
  parentItem: MenuItem;
}
interface IAnchorLinkItemProps extends AnchorLinkItemProps {
  icon: string;
}
const FunctionalGrouping: React.FC<{ [key: string]: any }> = inject(
  'AppState',
  'TagsViewStore',
  'UserStore',
  'DrawerStore',
)(
  observer((props) => {
    const {
      AppState: { leftMenuList },
      DrawerStore: { showFunDrawer, drawerOpen },
    } = props;
    const leftListRef = useRef<HTMLDivElement>(null); // 功能模块的ref
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]); // 左侧菜单的数据
    const [moduleList, setModuleList] = useState<IMenuItem[]>([]); // 右侧功能模块的数据
    const [anchorData, setAnchorData] = useState<IAnchorLinkItemProps[]>([]); // 锚点的数据
    const [activeMenuKey, setActiveMenuKey] = useState<string>(''); // 菜单选中的key
    const activeMenuKeyRef = useRef<string>(''); // 菜单选中的key
    const [rightActiveMenuKey, setRightActiveMenuKey] = useState<string>(''); // 菜单选中的key
    const [activeAnchor, setActiveAnchor] = useState('');
    const location = useLocation();
    /**
     * 之前采用multi-columns布局，会根据高度自动计算后进行重排，不符合预期，只适用于数据高度一定的情况
     * 修改为flex弹性布局+计算元素高度实现布局的方式，根据子元素的数目选择其应该拼接的列，实现高度变化时不变列展开
     */
    // 各列渲染的列表数组
    const columnListLeft = useRef<IMenuItem[]>([]);
    const columnListCenter = useRef<IMenuItem[]>([]);
    const columnListRight = useRef<IMenuItem[]>([]);

    useEffect(() => {
      // 左侧选中菜单
      let activity = getCurrentMenuList(leftMenuList, location.pathname.slice(1));
      activeMenuKeyRef.current = activity?.parentItem?.id ?? leftMenuList[0]?.id;
      setActiveAnchor(activity?.upperLevel?.id ? '#' + activity?.upperLevel?.id : '');
      setActiveMenuKey(activity?.parentItem?.id ?? leftMenuList[0]?.id);
      setRightActiveMenuKey(activity?.item?.id ?? '');
    }, [showFunDrawer, drawerOpen]);

    useEffect(() => {
      if (leftMenuList.length > 0) {
        getModuleListData(activeMenuKeyRef.current);
        getMenuItemsData();
      }
    }, [leftMenuList]);

    useEffect(() => {
      // 设置功能模块的数据
      getModuleListData(activeMenuKeyRef.current);
    }, [activeMenuKey]);

    /**
     * @description: 设置左侧菜单数据、默认选中的菜单
     * @return {*}
     */
    const getMenuItemsData = () => {
      const menuItems: MenuItem[] = [];
      leftMenuList?.forEach((item: IMenuItem) => {
        menuItems.push({
          label: item.name,
          key: item.id,
          icon: <PuiIconFont type={item.icon ?? ' '} className={styles.menuIcon} />,
        });
      });
      if (menuItems.length) {
        setMenuItems(menuItems);
      }
    };

    /**
     * @description: 设置功能模块的数据
     * @param {string} activeMenuKey
     * @return {*}
     */
    const getModuleListData = (activeMenuKeyParams: string) => {
      if (activeMenuKeyParams && leftMenuList.length) {
        const activeItems = leftMenuList.filter((item: IMenuItem) => item?.id === activeMenuKeyParams);
        if (activeItems.length) {
          let anchorData: IAnchorLinkItemProps[] = [];
          activeItems[0]?.children?.forEach((item: IMenuItem) => {
            anchorData.push({
              key: item.id,
              href: `#${item.id}`,
              title: item.name,
              icon: item.icon ?? '',
            });
          });
          setAnchorData(anchorData);
          const newModuleList = activeItems[0]?.children ? activeItems[0]?.children : [];
          setModuleList(newModuleList);
          caLFlex(newModuleList);
        }
      }
    };

    /**
     * @description: 左侧菜单点击事件
     * @param {*} e
     * @return {*}
     */
    const onClick: MenuProps['onClick'] = (e) => {
      activeMenuKeyRef.current = e.key;
      setActiveAnchor('');
      setActiveMenuKey(e.key);
    };
    const getCurrentAnchor = () => {
      return activeAnchor;
    };

    // 计算flex布局每列的数据项
    const caLFlex = (mList: IMenuItem[]) => {
      if (!mList.length) return;
      let arrLeft: IMenuItem[] = []; // 第一列的数据
      let arrCenter: IMenuItem[] = []; // 第二列的数据
      let arrRight: IMenuItem[] = []; // 第二列的数据
      let countArray_left: number[] = []; // 根据数量判断高度
      let countArray_center: number[] = [];
      let countArray_right: number[] = [];
      mList.forEach((item, index) => {
        if (index === 0) {
          // 第一行中的元素无需判断，直接加到新的数组中
          countArray_left.push(item?.children?.length ?? 0);
          arrLeft.push(item);
          return;
        }
        if (index === 1) {
          countArray_center.push(item?.children?.length ?? 0);
          arrCenter.push(item);
          return;
        }
        if (index === 2) {
          countArray_right.push(item?.children?.length ?? 0);
          arrRight.push(item);
          return;
        }

        // 累计各行元素数目
        const countTotal_left = countArray_left.length
          ? Array.from(countArray_left).reduce((acc, cur) => acc + cur)
          : 0; // 第一列的总个数
        const countTotal_center = countArray_center.length
          ? Array.from(countArray_center).reduce((acc, cur) => acc + cur)
          : 0; // 第二列的总个数
        const countTotal_right = countArray_right.length
          ? Array.from(countArray_right).reduce((acc, cur) => acc + cur)
          : 0; // 第三列的总个数

        // 找到最小值
        let minNumber = Math.min(countTotal_left, countTotal_center, countTotal_right);
        switch (minNumber) {
          case countTotal_left:
            countArray_left.push(item?.children?.length ?? 0);
            arrLeft.push(item);
            break;
          case countTotal_center:
            countArray_center.push(item?.children?.length ?? 0);
            arrCenter.push(item);
            break;
          case countTotal_right:
            countArray_right.push(item?.children?.length ?? 0);
            arrRight.push(item);
            break;
        }
      });

      // 重新将数据赋值给各列数组
      columnListLeft.current = arrLeft;
      columnListCenter.current = arrCenter;
      columnListRight.current = arrRight;
    };

    const generateDOM = (list: IMenuItem[]) =>
      list.map((item: IMenuItem) => {
        return (
          <div className={styles.module} key={item.id} id={item.id}>
            {/* 二级菜单名称 */}
            <div className={`${styles.title} ${activeAnchor === `#${item.id}` ? styles.activeTitle : ''}`}>
              <PuiIconFont type={item.icon ?? ' '} style={{ width: '14px', fontSize: '14px' }} />
              <span style={{ paddingLeft: '6px' }}>{item.name}</span>
            </div>
            {/* 三级菜单 */}
            {item?.children?.map((childrenItem: IMenuItem) => {
              return (
                <div key={`ExhibitionWrap${childrenItem.id}`} className={styles.content} style={{ display: 'block' }}>
                  <ExhibitionItem
                    type={ItemType.modular}
                    item={childrenItem}
                    key={childrenItem.id}
                    rightActiveMenuKey={rightActiveMenuKey}
                  />
                </div>
              );
            })}
          </div>
        );
      });

    return (
      <div className={styles.functionalGroupingBox}>
        <div className={styles.menuBox}>
          {activeMenuKeyRef.current && (
            <FunctionMenu
              onClick={onClick}
              style={{ width: '100%', height: '100%' }}
              selectedKeys={[activeMenuKeyRef.current]}
              mode="inline"
              items={menuItems}
            />
          )}
        </div>
        <div className={styles.groupContextBox}>
          <div className={styles.leftListBox} ref={leftListRef}>
            <div className={styles.flexLeftList}>
              <div className={styles.columnItem}>
                {columnListLeft?.current?.length ? generateDOM(columnListLeft.current) : null}
              </div>
              <div className={styles.columnItem}>
                {columnListCenter?.current?.length ? generateDOM(columnListCenter.current) : null}
              </div>
              <div className={styles.columnItem}>
                {columnListRight?.current?.length ? generateDOM(columnListRight.current) : null}
              </div>
            </div>
          </div>
          <div className={styles.rightAnchor}>
            <Anchor
              onClick={(e, link) => {
                e.preventDefault();
                setActiveAnchor(link.href);
              }}
              affix={false}
              showInkInFixed={true}
              getCurrentAnchor={getCurrentAnchor}
              getContainer={() => leftListRef?.current as HTMLDivElement}
              items={anchorData.map((item) => ({
                ...item,
                title: (
                  <div className={activeAnchor === item.href ? styles.selectAnchorTitle : ''}>
                    <PuiIconFont type={item.icon ?? ' '} style={{ width: '14px', fontSize: '14px' }} />
                    <span style={{ paddingLeft: '6px' }}>{item.title}</span>
                  </div>
                ),
              }))}
            />
          </div>
        </div>
      </div>
    );
  }),
);
export default FunctionalGrouping;
