import stores from 'src/stores';
/*
 * @Author: jld
 * @Date: 2023-07-12 16:36:18
 * @LastEditTime: 2023-11-15 15:36:10
 * @LastEditors: LHY
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\UserInfo\Organizations\viewModal.ts
 */
import { getItem, setItem } from 'src/utils';
import { STORAGE_KEY } from 'src/constant';
import { IOrganizations } from '@/api/types/settings';
import { getOrganizations } from 'src/api';
export interface IOption {
  label: string;
  value: string;
}
export const getFunctionOption = async () => {
  let optionTemp = await getOrganizations();
  let defaultOption = getItem(STORAGE_KEY.ORG_INFO);
  return optionTemp?.map((item: IOrganizations) => {
    if (defaultOption === null && item.defaultFlag === '1') {
      setItem(STORAGE_KEY.ORG_INFO, JSON.stringify(item));
      stores.UserStore.setOrgInfo(item);
    }
    return item;
  });
};
