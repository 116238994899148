/* eslint-disable no-template-curly-in-string */
/*
 * @Author: yar
 * @Date: 2023-02-17 16:02:58
 * @LastEditTime: 2023-04-26 14:55:53
 * @LastEditors: ZY
 * @Description: 登录面板组件
 * @FilePath: /erp-finance-biz-portal-react-start/src/pages/UserLogin/components/LoginPanel/index.tsx
 */
import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';
import IconFont from 'src/components/IconFont';
import TabsCard from '../TabsCard';
import { ILoginItemTipsTye, ILoginTipsTye, loginInfoTipsFun, tabsItem } from './viewModal';
import { useNavigate } from 'react-router';
import { setItem } from 'src/utils';
import { Cookie_KEY, STORAGE_KEY } from 'src/constant/storageKey';
import { isEmpty } from 'lodash-es';
import SliderCaptcha from 'rc-slider-captcha';
import createPuzzle from 'create-puzzle';
import DemoImage from 'src/asset/sunflower.jpg';
import Countdown from 'src/components/Countdown';
import { isPhoneNumber } from 'src/utils/regularCheck';
import { getMicroConfigList, getToken, getDashboard } from 'src/api';
import { MicroAppManager } from 'src/utils/microApp';
import { FormattedMessage } from 'react-intl';
import { setCookies } from 'src/utils/storage';
let loginInfoTips: ILoginTipsTye;
const IndexPage: React.FC<any> = inject(
  'UserStore',
  'DashboardStore',
)(
  observer((props) => {
    const {
      UserStore: { setUserName, setToken },
      DashboardStore: { setDashboardList },
    } = props;
    const [defaultCode, setDefaultCode] = useState<string>('accountLogin'); // 默认选中项
    const navigate = useNavigate();
    const [isStartCountdown, setIsStartCountdown] = useState<boolean>(false); // 是否开始倒计时
    const loginValues = useRef<{ password: string; username: string }>();
    const [form] = Form.useForm();
    const [error, setError] = useState<string>(); // 登录失败
    const [isSliderCaptcha, setIsSliderCaptcha] = useState<boolean>(false);
    const offsetXRef = useRef(0); // x 轴偏移值
    const [top, setTop] = useState(0);

    const onClickTab = (strCode: string) => {
      form.resetFields();
      setError('');
      setIsSliderCaptcha(false);
      setDefaultCode(strCode);
    };

    const onFinish = async (values: any) => {
      if (!isSliderCaptcha) {
        loginValues.current = values; // 用户名密码
        setIsSliderCaptcha(true);
        setError('');
      }
    };

    // 获取token系列操作
    const getTokenFn = (username: string, password: string) => {
      getToken({ username: username, password: password })
        .then((res: any) => {
          // setUserName(username);
          // setToken(res.token);
          // setItem(STORAGE_KEY.TOKEN, res.token);
          // setItem(STORAGE_KEY.USER_NAME, username);
          // setCookies(Cookie_KEY.TOKEN, res.token);
          // setCookies(Cookie_KEY.USER_CAPTION, res.UserCaption);
          // setCookies(Cookie_KEY.USER_NAME, username);
          getDashboard().then((res) => {
            setDashboardList(res);
          });
          // 获取为前端配置
          MicroAppManager.shared().loadMicroApps();
          navigate('/dashboard');
        })
        .catch(() => {
          setIsSliderCaptcha(false);
          loginValues.current = undefined;
          setError('账号/密码输入错误，请重新输入');
        });
    };

    const onSubmitButtonClick = () => {
      if (loginValues.current && isSliderCaptcha && defaultCode === 'accountLogin') {
        // 非手机号登录
        const { password, username } = loginValues.current;
        getTokenFn(username, password);
      }
    };
    // 获取验证码
    const buttonClick = () => {
      let formValue = form.getFieldsValue();
      let isPhoneNo = isPhoneNumber(formValue.username);
      if (isPhoneNo) setIsStartCountdown(true);
    };

    const countdownEnd = () => {
      setIsStartCountdown(false);
    };
    loginInfoTips = loginInfoTipsFun();

    const getAccountLoginFormDom = (defaultCode: string) => {
      let loginFormTips: ILoginItemTipsTye =
        defaultCode === 'accountLogin' ? loginInfoTips.accountLogin : loginInfoTips.quickLogin;
      return (
        <div className={styles.loginFormBox}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            className={styles.loginFormItem}
            form={form}
          >
            {!isSliderCaptcha && (
              <div className={styles.formGroup}>
                <Form.Item
                  name="username"
                  validateStatus="validating"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: loginFormTips.userNameTips,
                      pattern: defaultCode === 'accountLogin' ? /^[^\s]*$/ : /^1[3456789]\d{9}$/,
                    },
                  ]}
                >
                  <Input
                    placeholder={loginFormTips.namePlaceholderTips}
                    prefix={<IconFont type="icon-dengluming" className={styles.userNameIcon} />}
                    onBlur={() => {
                      setError('');
                    }}
                  />
                </Form.Item>
                <Form.Item name="password">
                  <div className={styles.passGroup}>
                    <Form.Item
                      name="passwordItem"
                      rules={[
                        {
                          required: true,
                          message: loginFormTips.passWordTips,
                        },
                      ]}
                    >
                      <Input
                        type={defaultCode === 'accountLogin' ? 'password' : 'text'}
                        placeholder={loginFormTips.passWordTips}
                        prefix={<IconFont type="icon-mima" className={styles.userNameIcon} />}
                        style={{ width: defaultCode === 'accountLogin' ? '310px' : '180px' }}
                        autoComplete="false"
                        onBlur={() => {
                          setError('');
                        }}
                      />
                    </Form.Item>
                    {defaultCode !== 'accountLogin' && (
                      <Button type="primary" className={styles.getCodeButton} onClick={buttonClick}>
                        {isStartCountdown ? (
                          <div className={styles.countDown}>
                            <Countdown
                              startSecond={6000}
                              isStart={isStartCountdown}
                              countDownFinishedText="获取验证码"
                              countdownEnd={countdownEnd}
                            />
                            s<FormattedMessage id="afterSecond" />
                          </div>
                        ) : (
                          <FormattedMessage id="getVerificationCode" />
                        )}
                      </Button>
                    )}
                  </div>
                </Form.Item>
                <Form.Item className={styles.formBottom}>
                  <span
                    className={styles.forgetPassWorld}
                    onClick={() => {
                      navigate('/forgetPassWorld');
                    }}
                  >
                    <FormattedMessage id="forgetPassword" />?
                  </span>
                </Form.Item>
              </div>
            )}

            {isSliderCaptcha && (
              <div className={styles.slideVerification}>
                <SliderCaptcha
                  request={() =>
                    createPuzzle(DemoImage, { bgWidth: 270, bgHeight: 100 }).then((res) => {
                      offsetXRef.current = res.x;
                      setTop(res.singlePuzzleY);
                      return {
                        bgUrl: res.bgUrl,
                        puzzleUrl: res.puzzleUrl,
                      };
                    })
                  }
                  onVerify={async (data) => {
                    if (data.x >= offsetXRef.current - 5 && data.x < offsetXRef.current + 5) {
                      onSubmitButtonClick();
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject();
                  }}
                  bgSize={{
                    width: 270,
                    height: 100,
                  }}
                  tipIcon={{
                    default: <div className={styles.sliderIcon} />,
                  }}
                  tipText={{
                    default: <FormattedMessage id="completePuzzle" />,
                    loading: <FormattedMessage id="UnderLoad" />,
                  }}
                />
                <div className={styles.slideVerificationText}>
                  <div className={styles.slideVerificationTextLeft}>
                    <FormattedMessage id="completeSecurityVerification" />
                  </div>
                  <div className={styles.slideVerificationTextRight}>
                    <FormattedMessage id="changeOne" />
                  </div>
                </div>
              </div>
            )}

            <Form.Item validateStatus="validating">
              <Button type="primary" htmlType="submit" className={styles.loginFormButton}>
                <FormattedMessage id="login" />
              </Button>
              {!isEmpty(error) && <div className={styles.loginError}>{error}</div>}
            </Form.Item>
          </Form>
        </div>
      );
    };

    return (
      <div className={styles.panelContent}>
        <div className={styles.top}>
          <div className={styles.panelLeftBox}>
            <div className={styles.panelLeftBoxDimensionalLabel}>
              <FormattedMessage id="scanCodeLogin" />
            </div>
            <div className={styles.panelLeftBoxDimensional}>
              <div className={styles.dimCodeIcon} />
              <div className={styles.dimCodeTips}>
                <FormattedMessage id="OpenCodeLogIn" />
              </div>
            </div>
            <div className={styles.panelLeftBoxDimDownLoad}>
              <Button type="primary" className={styles.downLoadButton}>
                <FormattedMessage id="DownloadPanSoftAPP" />
              </Button>
            </div>
          </div>
          <div className={styles.verticalLine} />
          <div className={styles.panelRightBox}>
            <TabsCard
              nameArr={tabsItem}
              defaultCode={defaultCode}
              onClickFuc={onClickTab}
              extraRightContent={getAccountLoginFormDom(defaultCode)}
            />
          </div>
        </div>
        <div className={styles.bottom}>
          <FormattedMessage id="loginPrompt" />
        </div>
      </div>
    );
  }),
);

export default IndexPage;
