/*
 * @Author: ZY
 * @Date: 2023-02-21 19:58:23
 * @LastEditors: ZY
 * @LastEditTime: 2023-03-01 09:51:37
 * @FilePath: /erp-finance-biz-portal-react-start/src/layout/components/TagsView/index.tsx
 * @Description:  TagsView 组件
 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { ITagsProps } from './type';
import TagList from './components/Taglist';

const TagsView: React.FC<ITagsProps> = inject('AppState')(
  observer((props) => {
    return <TagList />;
  }),
);

export default TagsView;
