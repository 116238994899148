/*
 * @Author: ZLL
 * @Date: 2023-02-06 13:37:13
 * @LastEditors: yxp
 * @LastEditTime: 2023-06-09 14:32:05
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\components\FunctionSearchTitle\index.tsx
 * @Description: 搜索关键词高亮
 */
import React from 'react';
import BoxEllipsisTooltip from 'src/components/BoxEllipsis';

const FunctionSearchTitle: React.FC<{ keyWords?: string; title: string }> = (props) => {
  const { keyWords, title } = props;
  let resultNodeText = String(title);
  const searchValueArr = keyWords?.trim().split(' ').filter(Boolean).join('|');
  let reg = new RegExp('(' + searchValueArr + ')', 'ig');
  resultNodeText = resultNodeText.replace(reg, '<span style="color:var(--ant-primary-color)">$1</span>');

  return (
    <BoxEllipsisTooltip
      text={!keyWords ? <span>{title}</span> : <span dangerouslySetInnerHTML={{ __html: resultNodeText }} />}
    />
  );
};
export default FunctionSearchTitle;
