/*
 * @Author: atwLee
 * @Date: 2023-05-24 15:02:02
 * @LastEditors: atwlee
 * @LastEditTime: 2023-07-14 10:28:36
 * @Description:
 * @FilePath: /erp-finance-biz-portal-react-start/src/hooks/useWJAppNames.ts
 */

import { ITagProps } from '@/stores/components/TagsViewStore';
import { MicroAppManager } from 'src/utils/microApp';
import { useEffect, useRef, useState } from 'react';
import { IMicroAppModel, microAppLoadingType } from 'src/api/types/microConfig';

type WuJieProps = (IMicroAppModel & { microAppPath: string; microAppName: string }) | undefined;

export interface WuJieAppsType {
  name: string;
  props: WuJieProps;
}

/**
 * @description: hooks，获取当前应用的相关信息
 * @param {ITagProps} getTagList
 * @param {string} activePath
 * @return {*}
 * app：打开的所有应用
 * curAppName：当前的应用，'zz'/'web'
 * curtMenu：当前的菜单，用于传给WuJie标签
 *
 */
function useWuJieAppNames(getTagList: ITagProps[], activePath: string) {
  const [appNames, setAppNames] = useState<string[]>([]); // 存一下所有的appNames
  const mapKeyApp = useRef<Map<string, string>>(); // 将activePath（url）与应用做映射，主要是为了判断当前url是哪个应用
  const [apps, setApps] = useState<WuJieAppsType[]>([]);

  const handleApp = async () => {
    const appNamesTemp: string[] = [];
    const mapKeyAppTemp = new Map<string, string>();
    const appsTemp: WuJieAppsType[] = [];
    const microApps = await MicroAppManager.shared().getMicroApps();
    getTagList
      .filter((i) => i.config) // 过滤一下没有config的
      .forEach((i) => {
        const app_name: string = i.config.url?.split('/')[0];
        if (!appNamesTemp.includes(app_name)) {
          // 保活模式，每个应用之需要找一次就行，页面切换通过给子应用通信
          appNamesTemp.push(app_name);
          const microApp = microApps.find((item) => item.name === app_name);
          let microAppPath: string = i.config.url?.replace(`${app_name}`, microApp?.url ?? '');
          // 拼接菜单名字
          if(microAppPath && microAppPath.search(/caption/gi) === -1 && i?.name && app_name === 'web'){
            if(microAppPath.indexOf('?') !== -1){
              microAppPath += `&caption=${i?.name}`
            }else{
              microAppPath += `?caption=${i?.name}`
            }
          }

          if (microApp) {
            appsTemp.push({
              name: app_name,
              props: {
                microAppName: app_name,
                microAppPath,
                ...microApp,
              },
            });
          }
        }
        mapKeyAppTemp.set(i.path, app_name);
      });

    // 销毁
    if (appNamesTemp.length < appNames.length) {
      const destroyAppKey = appNames.filter((i) => !appNamesTemp.includes(i))[0];
      MicroAppManager.shared().destroyMicroApps(destroyAppKey);
    }
    setApps(appsTemp);
    setAppNames(appNamesTemp);
    mapKeyApp.current = mapKeyAppTemp;
  };

  useEffect(() => {
    handleApp();
  }, [getTagList]);

  return {
    curAppName: mapKeyApp.current?.get(activePath),
    curMenu: getTagList.find((i) => i.path === activePath),
    apps: apps,
  };
}

export default useWuJieAppNames;
