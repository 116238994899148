export const FOOTER_CONTENT = [
  { name: '关于我们', code: 'aboutUs' },
  { name: '联系我们', code: 'contactUs' },
  { name: '新闻动态', code: 'newsUpdate' },
  { name: '软件产品', code: 'softwareProduct' },
  { name: '云服务', code: 'cloudService' },
  { name: '领域解决方案', code: 'domainSolution' },
  { name: '典型案例', code: 'typicalCase' },
  { name: '服务支持', code: 'serviceSupport' },
];
