/**
 * 获取元素样式
 * @param element
 * @param attr
 */
export const getStyle = (element: HTMLElement, attr?: string) => {
    let style = window.getComputedStyle ? window.getComputedStyle(element) : (element as any).currentStyle;
    return attr ? style[attr] : style;
};
/**
 * 获取root下所有css变量names
 * @param styleSheets
 */
export const getAllCSSVariableNames = (styleSheets:any  = document.styleSheets) => {
    let cssVars: string[] = [];
    for (let styleSheet of styleSheets) {
        try {
            for (let cssRuleItem of styleSheet.cssRules) {
                try {
                    for (let ruleSyleItem of cssRuleItem.style) {
                        let name = ruleSyleItem;
                        // eslint-disable-next-line max-depth
                        if (name.startsWith('--') && cssVars.indexOf(name) === -1) {
                            cssVars.push(name);
                        }
                    }
                } catch (error) {

                }
            }
        } catch (error) {

        }
    }
    return cssVars;
}
/**
 * 获取Css 变量的值
 * @param allCSSVars
 * @param element
 */
export const getElementCSSVariables = (allCSSVars: string[], element = document.body) => {
    let elStyles = getStyle(element);
    let cssVars: { [key: string]: any } = {};
    for (let key of allCSSVars) {
        let value = elStyles.getPropertyValue(key)
        if (value) {
            cssVars[key] = value;
        }
    }
    return cssVars;
}
/**
 * append cssText
 * @param el
 * @param cssText
 * @param elementId
 */
export const loadStyleString = (el:HTMLElement,cssText:string,elementId?:string) => {
    if(elementId && document.querySelector(`#${elementId}`)){
        let styleElement = document.querySelector(`#${elementId}`);
        if(styleElement){
            styleElement.innerHTML = cssText
        }
    }else{
        let style = document.createElement("style");
        style.type = "text/css";
        if(elementId){
            style.id = elementId
        }
        try{
            style.appendChild(document.createTextNode(cssText));
        }catch(ex) {

        }
        el.appendChild(style);
    }

}

/**
 * 获取iframe 容器
 * @param srcStr
 */
export const getIframeContainEle = (srcStr?:string) => {
    let el:any = null;
    const iframeArrEl = document.querySelectorAll('.iframePortalContainer')
    if(iframeArrEl){
        el = iframeArrEl
        if(srcStr){
            for(let i = 0;i< iframeArrEl?.length;i++){
                const iframeSrc = iframeArrEl[i].getAttribute('src');
                if(iframeSrc === srcStr){
                    el = iframeArrEl[i];
                    break;
                }
            }
        }
    }
    return el
}
