/*
 * @Author: jld
 * @Date: 2023-02-20 16:13:36
 * @LastEditTime: 2023-03-11 20:37:30
 * @LastEditors: jld
 * @Description: 登录顶部
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\UserLogin\components\LoginHeader\index.tsx
 */
import IconFont from 'src/components/IconFont';
import React from 'react';
import styles from './index.module.less';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { LANG_TYPE, IItem, LANG_OBJECT } from 'src/constant';
import InternationalizationStore from 'src/stores/components/InternationalizationStore';
import { FormattedMessage } from 'react-intl';

const LoginHeader: React.FC<{
  isForget?: boolean;
}> = (props) => {
  const { isForget } = props;
  const navigate = useNavigate();
  const returnLogin = () => {
    navigate('/login');
  };
  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    InternationalizationStore.setLang(value.value);
  };

  const option = LANG_TYPE.map((item: IItem) => {
    return {
      value: item.CODE,
      label: item.NAME,
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.portalLoginLeftLogo}>
        <span className={styles.portalLoginLogoIcon} />
        <span className={styles.portalLoginLogoLabel}>ERP</span>
        {isForget && (
          <span className={styles.forgetPassWorld}>
            {' '}
            <FormattedMessage id="forgetPassword" />
          </span>
        )}
      </div>
      <div className={styles.portalLoginRightLanguage}>
        {isForget && (
          <div className={styles.login} onClick={returnLogin}>
            <FormattedMessage id="login" />
          </div>
        )}
        <IconFont type="icon-duoyuyan" className={styles.languageIcon} />
        <span className={styles.portalLanguageLabel}>
          <Select
            labelInValue
            defaultValue={{ value: InternationalizationStore.lang, label: LANG_OBJECT[InternationalizationStore.lang] }}
            style={{ width: 120 }}
            onChange={handleChange}
            bordered={false}
            options={option}
          />
        </span>
      </div>
    </div>
  );
};
export default LoginHeader;
