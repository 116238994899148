import React from 'react';
import { Layout } from 'antd';
import routeList from 'src/routes/layoutRouteMap';

const { Content } = Layout;

const TagContent: React.FC<{ tag: any }> = (props) => {
  const { tag } = props;
  return (
    <Content
      style={{
        height: '100%',
        position: 'relative'
      }}
    >
      {routeList.map((route, index) => {
        return tag.path.indexOf(route.tabPath) > -1 ? <route.component key={index} menuItem={tag} /> : null;
      })}
    </Content>
  );
};

export default TagContent;
