/*
 * @Author: jld
 * @Date: 2023-03-09 18:05:37
 * @LastEditTime: 2023-03-10 09:04:33
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\locale.ts
 */
import zhCN from 'src/locale/zhCN';
import enUS from 'src/locale/enUS';
import zhHK from 'src/locale/zhHK';
import zhTW from 'src/locale/zhTW';
import zhCNAntd from 'antd/locale/zh_CN';
import enUSAntd from 'antd/locale/en_US';
import zhHKAntd from 'antd/locale/zh_HK';
import zhTWAntd from 'antd/locale/zh_TW';

export const getMessages = (lang: string) => {
  let messages;
  switch (lang) {
    case 'zh_CN':
      messages = zhCN;
      break;
    case 'zh_HK':
      messages = zhHK;
      break;
    case 'zh_TW':
      messages = zhTW;
      break;
    case 'en_US':
      messages = enUS;
      break;
    default:
      messages = zhCN;
  }
  return messages;
};

export const getLang = (lang: string) => {
  if (!lang) {
    return 'zh';
  } else {
    return lang.slice(0, 2);
  }
};

export const getAntdLocale = (lang: string) => {
  let antdLocale;
  switch (lang) {
    case 'zh_CN':
      antdLocale = zhCNAntd;
      break;
    case 'en_US':
      antdLocale = enUSAntd;
      break;
    case ' zh_HK':
      antdLocale = zhHKAntd;
      break;
    case ' zh_TW':
      antdLocale = zhTWAntd;
      break;

    default:
      antdLocale = zhCNAntd;
  }
  return antdLocale;
};
