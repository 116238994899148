/*
 * @Author: yar
 * @Date: 2023-02-07 19:46:43
 * @LastEditTime: 2023-02-13 11:19:21
 * @LastEditors: yar
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\Header\components\GlobalSearch\components\SearchPanel\utils\constant.ts
 */
const indexedDBkey = {
  // 历史数据
  hisId: 'erp-indexed-db-history-keyword-key-',
  // 访问功能数据id
  funcId: 'erp-indexed-db-history-function-key-',
};

export default indexedDBkey;
interface model {
  bill: string;
  fuc: string;
  help: string;
  message: string;
  undefined: string;
}
export const classifyModel = {
  bill: '单据',
  fuc: '功能',
  help: '帮助文档',
  message: '消息',
  undefined: 'undefined',
};
export enum enumType {
  // @ts-ignore
  bill = 'bill',
  // @ts-ignore
  fuc = 'fuc',
  // @ts-ignore
  help = 'help',
  // @ts-ignore
  message = 'message',
}
export const ClassifyTitle = [
  { name: '单据', code: 'bill' },
  { name: '功能', code: 'fuc' },
  { name: '帮助文档', code: 'help' },
  { name: '消息', code: 'message' },
];
