/*
 * @Author: LMY
 * @Date: 2023-07-27 15:41:06
 * @LastEditors: lmy
 * @LastEditTime: 2023-08-02 10:05:48
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\routerContainer\IFrame\postMessage.ts
 */
import { MESSAGE_KEY } from 'src/constant/messageKey';
import { OpenParams, appCommunication } from 'src/utils/appCommunication';
import stores from 'src/stores';

export enum IFrameMessageType {
  PROPS = 'PROPS',
}

export interface IFrameMessage {
  type: IFrameMessageType;
  data?: Record<string, any>;
  [key: string]: any;
}

export const addEventListener = (intentionMethodCallback?: (key: MESSAGE_KEY) => void) => {
  window.addEventListener('message', (event: MessageEvent<OpenParams>) => {
    // console.log('iframe收到的event信息为：', event);
    // 判断一下来源
    const { origin } = event;
    if (origin.indexOf('pansoft.com') === -1) {
      return;
    }
    const { intentionMethod } = event.data;
    // 进行相应页签跳转处理
    if (intentionMethod) {
      appCommunication({ ...event.data });
      if (intentionMethodCallback) {
        intentionMethodCallback(intentionMethod);
      }
    }
  });
};

export const sendMessageUtil = (iframe:HTMLElement | HTMLIFrameElement | null,data:{
  [key:string]:any
}) => {
  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input !== null && input.tagName === 'IFRAME';
  if (isIFrame(iframe) && iframe?.contentWindow) {
    iframe.contentWindow!.postMessage(data, '*')
  }
};

export const sendMessage = (iframe: HTMLIFrameElement | null) => {
  iframe?.contentWindow?.postMessage(
    {
      type: IFrameMessageType.PROPS,
      data: {
        token: stores.UserStore.token,
        userName: stores.UserStore.userName,
        userCaption: stores.UserStore.userCaption,
      },
    },
    '*',
  );
};
