/*
 * @Author: WGF
 * @Date: 2023-02-10 10:08:03
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @LastEditTime: 2024-03-20 16:24:26
 * @FilePath: /erp-finance-biz-portal-react-start/src/stores/components/DrawerStore.tsx
 * @Description: 文件描述
 */
import { action, computed, observable } from 'mobx';

type DrawerOpenStatus = 'close' | 'opening' | 'open';
class DrawerStore {
  // 一级抽屉是否展开
  @observable public drawerOpen = false;

  // 一级抽屉打开状态close；关闭，opening：正在打开或关闭，open.已经打开
  @observable public drawerOpenStatus: DrawerOpenStatus = 'close';

  // 是否直接展示所有功能抽屉
  @observable public showFunDrawer = false;

  // 是否直接展示所有功能抽屉标识
  @observable public showFunDrawerFlag = false;

  // 是否收藏菜单是否钉住
  @observable public collectMenuFixed = false;

  @action.bound
  public setDrawerOpen(state: boolean) {
    this.drawerOpen = state;
  }

  @action.bound
  public setDrawerOpenStatus(state: DrawerOpenStatus) {
    this.drawerOpenStatus = state;
  }

  @action.bound
  public setShowFunDrawer(state: boolean) {
    this.showFunDrawer = state;
  }

  @action.bound
  public setShowFunDrawerFlag(state: boolean) {
    this.showFunDrawerFlag = state;
  }

  @action.bound
  public setCollectMenuFixed(state: boolean) {
    this.collectMenuFixed = state;
  }
}

const drawerStore = new DrawerStore();
export default drawerStore;
