/*
 * @Author: jld
 * @Date: 2023-07-05 09:44:08
 * @LastEditTime: 2023-07-05 09:44:09
 * @LastEditors: jld
 * @Description: 小部件注册
 * @FilePath: \erp-finance-biz-portal-react-start\src\routes\functionRegister.tsx
 */

export const customDashboardList = [
  {
    componentType: 'HelloCard',
    name: '早上好',
    path: 'components/gridItemGroup/HelloCard',
  },
  {
    componentType: 'WeatherCard',
    name: '天气',
    path: 'components/gridItemGroup/WeatherCard',
  },
  {
    componentType: 'BreakingNews',
    name: '大事件',
    path: 'components/gridItemGroup/BreakingNews',
  },
  {
    componentType: 'TodoTasks',
    name: '待办任务',
    path: 'components/gridItemGroup/TodoTasks',
  },
  {
    componentType: 'HelpDocument',
    name: '帮助文档',
    path: 'components/gridItemGroup/HelpDocument',
  },
  {
    componentType: 'Notice',
    name: '通知',
    path: 'components/gridItemGroup/Notice',
  },
  {
    componentType: 'QuickApplication',
    name: '快捷应用',
    path: 'components/gridItemGroup/QuickApplication',
  },
  {
    componentType: 'RecentUse',
    name: '最近使用',
    path: 'components/gridItemGroup/RecentUse',
  },
  // {
  //   componentType: 'Cockpit',
  //   name: '驾驶舱',
  //   path: 'components/gridItemGroup/Cockpit',
  // },
  // {
  //   componentType: 'Carousel',
  //   name: '轮播图',
  //   path: 'components/gridItemGroup/Carousel',
  // },
  // {
  //   componentType: 'ProcessList',
  //   name: '待办流程',
  //   path: 'components/gridItemGroup/ProcessList',
  // },
  {
    componentType: 'NoticeList',
    name: '通知公告',
    path: 'components/gridItemGroup/NoticeList',
  },
  {
    componentType: 'knowledgeBase',
    name: '知识库',
    path: 'components/gridItemGroup/knowledgeBase',
  },
];
