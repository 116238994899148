/*
 * @Author: ZY
 * @Date: 2023-02-15 19:51:45
 * @LastEditors: ZY
 * @LastEditTime: 2023-04-01 16:49:54
 * @FilePath: /erp-finance-biz-portal-react-start/src/components/routerContainer/Wujie/lifecycle.ts
 * @Description: wujie生命周期
 */
const lifecycles = {
  beforeLoad: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} beforeLoad 生命周期`),
  beforeMount: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} beforeMount 生命周期`),
  afterMount: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} afterMount 生命周期`),
  beforeUnmount: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} beforeUnmount 生命周期`),
  afterUnmount: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} afterUnmount 生命周期`),
  activated: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} activated 生命周期`),
  deactivated: (appWindow: Window) => console.log(`${appWindow.__WUJIE.id} deactivated 生命周期`),
  loadError: (url: string, e: Error) => console.log(`${url} 加载失败`, e),
};

export default lifecycles;
