/*
 * @Author: yar
 * @Date: 2023-02-08 15:14:36
 * @LastEditTime: 2023-03-04 10:39:55
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\GlobalSearch\components\SearchPanel\utils\viewFormat.ts
 */
import { classifyModel, enumType } from './constant';
// export const getClassifyType = (type: string) => {
//   if (type === undefined) {
//     return classifyModel.undefined;
//   } else {
//     return enumType[type];
//   }
// };
export const getClassifyType = (type: string) => {
  let result = '';
  switch (type) {
    case enumType.fuc: {
      result = classifyModel[type];
      break;
    }
    case enumType.bill: {
      result = classifyModel[type];
      break;
    }
    case enumType.help: {
      result = classifyModel[type];
      break;
    }
    case enumType.message: {
      result = classifyModel[type];
      break;
    }
  }
  return result;
};
