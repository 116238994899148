/*
 * @Author: jld
 * @Date: 2023-08-15 21:44:49
 * @LastEditTime: 2023-08-17 17:27:25
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\GlobalSearch\components\SearchPanel\request.tsx
 */
import { idbGetItem } from 'src/utils/storage';
import { STORAGE_KEY } from 'src/constant';
import { MenuItem as IMenuItem } from 'src/types';
import indexedDBkey from './utils/constant';
import { getCookies } from 'src/utils/storage';
import { Cookie_KEY } from 'src/constant/storageKey';
export const columns = [
  { title: '编号', dataIndex: 'code', key: 'code', width: 150 },
  { title: '名称', dataIndex: 'name', key: 'name' },
];
export interface ListModel {
  key: string;
  code: string;
  name: string;
}

// 猜你想搜是优先从最近使用，最近搜索中提取，最后再从功能中找
export const getGuessData = async (menuFuncData: IMenuItem[], searchValue: string) => {
  let historyUse = (await idbGetItem(`${STORAGE_KEY.RECENT_USE}-${getCookies(Cookie_KEY.USER_NAME)}`)) ?? [];
  let historySearch = (await idbGetItem(indexedDBkey.hisId + '-recommend-data')) ?? [];
  let returnData: string[] = [];
  historyUse?.forEach((item: IMenuItem) => {
    if (item?.name?.includes(searchValue)) {
      returnData.push(item?.name);
    }
  });
  historySearch?.forEach((item: string) => {
    if (item?.includes(searchValue)) {
      returnData.push(item);
    }
  });
  if (returnData.length < 8) {
    menuFuncData.forEach((item) => {
      const regex = /[\u4e00-\u9fa5]/g;
      let name = String(item.name.match(regex)).replace(/,/g, '');
      if (name?.includes(searchValue)) {
        returnData.push(name);
      }
    });
  }
  const uniqueArray = returnData.filter((item, index) => returnData.indexOf(item) === index);
  return uniqueArray.slice(0, 8);
};
