/*
 * @Author: ZY
 * @Date: 2023-02-14 11:07:27
 * @LastEditors: lmy
 * @LastEditTime: 2023-09-15 14:00:19
 * @FilePath: \erp-finance-biz-portal-react-start\src\constant\appSettings.ts
 * @Description: app 全局配置功能
 */
import Chrome from 'src/asset/Chrome.svg';
import Edge from 'src/asset/Edge.svg';
import Opera from 'src/asset/Opera.svg';
import Safari from 'src/asset/Safari.svg';
import browser360 from 'src/asset/360.png';
import qq from 'src/asset/qq.png';

export const APP_SETTINGS = {
  APP_NAME: '普联ERP门户中心',
  BROWSER_LIST: [
    {
      browserId: 'Chrome',
      browserName: '谷歌',
      version: '108',
      icon: Chrome,
    },
    {
      browserId: 'Edge',
      browserName: 'Edge',
      version: '108',
      icon: Edge,
    },
    {
      browserId: '360',
      browserName: '360',
      version: '108',
      icon: browser360,
    },
    // {
    //   browserId: 'Opera',
    //   browserName: '欧朋',
    //   version: '80',
    //   icon: Opera,
    // },
    {
      browserId: 'qq',
      browserName: 'qq',
      version: '11',
      icon: qq,
    },
    {
      browserId: 'Safari',
      browserName: 'Safari',
      version: '16',
      icon: Safari,
    },
  ],
};
