/*
 * @Author: ZLL
 * @Date: 2023-02-15 17:41:06
 * @LastEditors: ZLL
 * @LastEditTime: 2023-02-16 14:49:24
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\Loading\index.tsx
 * @Description: Loading组件
 */
import React from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd';
import styles from './index.module.less';

const Loading: React.FC<SpinProps> = (props) => {
  return (
    <div className={styles.spinBox}>
      <Spin
        size="large"
        {...props}
        indicator={
          <div className={styles.loading}>
            <div className={styles.dot} />
            <div className={styles.dot} />
            <div className={styles.dot} />
            <div className={styles.dot} />
          </div>
        }
      />
    </div>
  );
};
export default Loading;
