/*
 * @Author: ZY
 * @Date: 2023-02-28 14:14:22
 * @LastEditors: ZY
 * @LastEditTime: 2023-04-25 17:21:27
 * @FilePath: /erp-finance-biz-portal-react-start/src/hooks/useNavigate.ts
 * @Description: 封装跳转
 */
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

/**
 * @description:  导航跳转需要刷新，参数要放到url上
 * tagTitle: 标签栏名称，有时候需要动态从页面获取
 * reloadTag: 打开相同的标签栏是否需要刷新
 */
interface NavigateQuery {
  tagTitle?: string;
  reloadTag?: boolean;
  [key: string]: any;
}

export default function useErpNavigate() {
  let navigate = useNavigate();
  function erpNavigateFunc(to: To, options?: NavigateOptions, queryParams?: NavigateQuery) {
    if (queryParams) {
      return navigate(`${to}?${stringify(queryParams)}`, options);
    }
    return navigate(to, options);
  }
  return erpNavigateFunc;
}
