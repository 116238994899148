/*
 * @Author: ZLL
 * @Date: 2023-02-08 20:14:50
 * @LastEditors: yar
 * @LastEditTime: 2023-02-15 17:30:19
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\BoxEllipsis\index.tsx
 * @Description: 超出显示...ToolTip
 */

import React, { useLayoutEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';
import type { TooltipProps } from 'antd/es/tooltip';
import styles from './index.module.less';
import PuiIconFont from '../PuiIconFont';

interface BoxEllipsisType {
  width?: string;
  height?: string;
  title?: string | React.ReactNode;
  text: string | React.ReactNode;
  tooltipProps?: TooltipProps;
  multiline?: boolean; // 是否是多行
  icon?: string;
  isLeaf?: boolean;
}
const BoxEllipsisTooltip: React.FC<BoxEllipsisType> = (props) => {
  const { width = '100%', height = '100%', text, tooltipProps, multiline, icon, isLeaf } = props;
  const boxRef = useRef<HTMLDivElement>(null);
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (boxRef.current) {
      if (multiline) {
        // 多行判断高度
        setIsShowTooltip(boxRef.current?.offsetHeight < boxRef.current?.scrollHeight);
      } else {
        setIsShowTooltip(boxRef.current?.offsetWidth < boxRef.current?.scrollWidth);
      }
    }
  }, []);

  return (
    <Tooltip {...tooltipProps} title={isShowTooltip ? text : ''}>
      <div
        className={`${multiline ? styles.doubleRowBox : styles.boxContainer} ${styles.boxContainerLayout}`}
        ref={boxRef}
        style={{ width, height }}
      >
        {icon ? (
          isLeaf ? (
            <img src={`/portal/images/isLeafMenuIcon/${icon}.svg`} className={styles.iconSize} />
          ) : (
            <PuiIconFont type={icon ?? ' '} className={styles.iconSize} />
          )
        ) : (
          ''
        )}
        {text}
      </div>
    </Tooltip>
  );
};
export default BoxEllipsisTooltip;
