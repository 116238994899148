/*
 * @Author: yar
 * @Date: 2023-02-17 11:01:08
 * @LastEditTime: 2023-03-21 08:56:41
 * @LastEditors: jld
 * @Description: 登录页
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\UserLogin\index.tsx
 */
import React from 'react';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';
import IconFont from 'src/components/IconFont';
import LoginPanel from './components/LoginPanel';
import LoginHeader from './components/LoginHeader';

import { FormattedMessage } from 'react-intl';
import { FOOTER_CONTENT } from 'src/constant/footerContent';

const IndexPage: React.FC<any> = inject('UserStore')(
  observer((props) => {
    return (
      <div className={styles.portalLoginContainer}>
        {/* top */}
        <div className={styles.portalLoginTopBox}>
          <LoginHeader />
        </div>

        <div className={styles.portalLoginMiddle}>
          {/* middle left */}
          <div className={styles.middleBox}>
            <div className={styles.portalLoginMiddleLeftBox}>
              <div className={styles.portalLoginBannerContent}>
                <div className={styles.portalLoginBannerTopTitle}>
                  <FormattedMessage id="target" />
                </div>
                <div className={styles.portalLoginBannerTopLabel}>
                  <FormattedMessage id="learnMore" />
                </div>
                <div className={styles.portalLoginBannerTopDetail}>
                  <span>
                    <FormattedMessage id="viewDetails" />
                  </span>
                  <IconFont type="icon-xiayiji" className={styles.detailIcon} />
                </div>
              </div>
            </div>
            {/* middle right login */}
            <div className={styles.portalLoginMiddleRightBox}>
              <div className={styles.portalLoginMiddleLoginPanel}>
                <LoginPanel />
              </div>
            </div>
          </div>
        </div>
        {/* footer */}
        <div className={styles.portalLoginFooterBox}>
          <div className={styles.portalLoginFooterLinkContent}>
            {FOOTER_CONTENT.map((item, index: number) => {
              return (
                <span key={index}>
                  <a>
                    <FormattedMessage id={item.code} />
                  </a>
                  {index !== FOOTER_CONTENT.length - 1 && '|'}
                </span>
              );
            })}
          </div>
          <div className={styles.portalLoginFooterCopyRight}>
            <FormattedMessage id="allRightsReserved" />
          </div>
        </div>
      </div>
    );
  }),
);

export default IndexPage;
