/*
 * @Author: yar
 * @Date: 2023-02-07 09:20:54
 * @LastEditTime: 2023-11-24 17:12:28
 * @LastEditors: lmy
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\GlobalSearch\components\SearchComponents\index.tsx
 */
import React, { ReactNode, useImperativeHandle, useEffect, useMemo, useRef, useState } from 'react';
import { Select, Spin, Button } from 'antd';
import type { SelectProps } from 'antd';
import { debounce } from 'lodash-es';
import styles from './index.module.less';
import classnames from 'classnames';
import IconFont from 'src/components/IconFont';
import { inject, observer } from 'mobx-react';
import { BaseSelectRef } from 'rc-select';
import { useIntl } from 'react-intl';
import EventBus from 'src/utils/events';
import { EVENTtBUS_KEY } from 'src/constant/eventsKey';
// import Nominate from '@/components/Nominate';
export interface DebounceSelectProps<ValueType = any> extends Omit<SelectProps, 'options' | 'children' | 'onSelect'> {
  fetchOptions: (keyWord: string) => any; // (search: string) => Promise<ValueType[]>;
  searchPanel: ReactNode; // 搜索后展示面板
  setSourceData: Function; // 重置搜索后的面板数据
  setGuessData: Function; // 猜你想搜
  onRef: any;
  onSelect?: (select: string[], selectItem?: any) => void; // 应用组件的回调
  selectValue: string[]; // 提交选中的值
  setSelectValue: Function; // 提交选中值的方法
  onClear: () => void;
  [key: string]: any;
}

const IndexPage: React.FC<DebounceSelectProps> = inject(
  'AppState',
  'TagsViewStore',
)(
  observer((props) => {
    const {
      AppState: { themStyle },
      searchPanel,
      setSourceData,
      fetchOptions,
      onRef,
      onSelect,
      selectValue,
      setSelectValue,
      onClear,
      setGuessData,
    } = props;
    const [data, setData] = useState<any[]>([]); // 设置option的数据
    const [searchValue, setSearchValue] = useState<string>(''); // 搜索的关键词(字符串格式)
    const [lastSearchValue, setLastSearchValue] = useState<string>('');
    const [fetching, setFetching] = useState(false); // 当前是否为搜索状态
    const [open, setOpen] = useState(false); // 当前下拉菜单的状态
    const fetchRef = useRef(0); // 搜索防抖用的
    const selectRef = useRef<BaseSelectRef>(null); // 获取Select选择框
    const [selectKey, setSelectKey] = useState<string>();
    const intl = useIntl();
    useImperativeHandle(onRef, () => {
      return {
        searchValue,
        lastSearchValue,
        setData,
        handleSelect,
        handleChange,
      };
    });

    /**
     * 防抖搜索
     */
    const loadOptions = (value: string) => {
      setSearchValue(value);
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setData([]);
      setFetching(true);
      fetchOptions(value).then((res: any) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setData(res.dataSource);
        setSourceData(res.dataSource);
        setGuessData(res.guessSearchData);
        setFetching(false);
      });
      setLastSearchValue(value);
    };
    const debounceFetcher = useMemo(() => {
      return debounce(loadOptions, 500);
    }, [fetchOptions]);

    useEffect(() => {
      if (searchValue) {
        loadOptions(searchValue);
      } else {
        onClear();
      }
    }, [searchValue]);

    /**
     * 多选情况下取消选中某一行时去除之前选中的结果
     * @param changed
     */
    const handleChange = (changed: any) => {
      selectRef?.current?.blur();
      let name: string | undefined = changed?.name;
      if (changed?.functionCode) {
        let nameTemp = String(changed.name);
        const regex = /[\u4e00-\u9fa5]/g;
        name = String(nameTemp.match(regex)).replace(/,/g, '');
      }
      setSelectValue([]);
      setOpen(false);
    };

    /**
     *点击每一行选项执行的逻辑
     * @param newValue
     */
    const handleSelect = (newValue: string[], newValueItem: any) => {
      setSelectKey(newValueItem);
      selectRef?.current?.blur();
      setSearchValue(newValue[0]);
      setSelectValue(newValue[0]);
      if (newValue !== selectValue) {
        onSelect?.(newValue, newValueItem);
      }
    };

    /**
     * 根据不同选择模式渲染页面
     */
    const renderSearchPanel: ReactNode = useMemo(() => {
      return (
        <div className={styles.searchPanel}>
          <div className={styles.searchPanelContent}>{searchPanel}</div>
        </div>
      );
    }, [searchPanel, open]);
    /**
     * 公共下拉内容渲染
     * @param originNode
     * @returns
     */
    const dropdownRender = (originNode?: ReactNode) => {
      return (
        <div className={styles.dropdownPanel}>
          {fetching ? (
            <div className={styles.loadingBox}>
              <Spin size="small" className={styles.loadingIcon} />
            </div>
          ) : (
            renderSearchPanel
          )}
        </div>
      );
    };
    /**
     * 不同选择模式下需要传的参数
     */
    const checkboxParam: SelectProps = useMemo(() => {
      return {
        allowClear: true,
        onChange: (newValue, newValueItems) => {
          setSelectValue(newValue);
          setSearchValue(newValue?.length ? newValue[0] : '');
          // onSelect?.(newValue, newValueItems);
        },
      };
    }, [selectValue]);

    useEffect(() => {
      EventBus.on(EVENTtBUS_KEY.OPEN_PAGE, () => {
        setOpen(false);
        setSelectValue([]);
      });
    }, []);

    return (
      <div
        className={
          themStyle === 'overallStyleBlackTab'
            ? styles.globalSearchContainer
            : `${styles.globalSearchContainer} ${styles.globalSearchContainerOtherThem}`
        }
      >
        <Select
          {...checkboxParam}
          allowClear={true}
          open={open}
          onDropdownVisibleChange={(visible) => setOpen(visible)}
          ref={selectRef}
          value={selectValue}
          style={{ width: '100%' }}
          placeholder={intl.formatMessage({ id: 'searchTips' })}
          showSearch={true}
          showArrow={true}
          filterOption={false}
          onSearch={debounceFetcher}
          dropdownRender={dropdownRender}
          onClear={onClear}
          suffixIcon={<IconFont type="icon-yuyin" className={styles.voiceIcon} />}
          popupClassName={classnames([styles.selectorDropdown])}
          key={selectKey}
          options={(data || []).map((d) => ({
            key: d.key,
            value: d.key,
            label: d.name,
          }))}
        />
      </div>
    );
  }),
);
export default IndexPage;
