/*
 * @Author: ZLL
 * @Date: 2023-02-09 17:39:19
 * @LastEditors: ZLL
 * @LastEditTime: 2023-02-09 17:48:57
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\date.ts
 * @Description: 日期转换
 */
import dayjs from 'dayjs';

/**
 * @description: 转换显示日期
 * @param {number} date 时间戳
 * @return {*}
 */
export const conversionDate = (date: number) => {
  let dateStr = '';
  // 今天 hh:mm
  if (dayjs(date).format('YYYY/MM/DD') === dayjs().format('YYYY/MM/DD')) {
    dateStr = dayjs(date).format('HH:mm');
  } else if (dayjs(date).format('YYYY/MM/DD') === dayjs().subtract(1, 'days').format('YYYY/MM/DD')) {
    // 昨天 昨天 HH:mm
    dateStr = `昨天 ${dayjs(date).format('HH:mm')}`;
  } else if (dayjs(date).format('YYYY') === dayjs().format('YYYY')) {
    // 当年 MM/DD
    dateStr = dayjs(date).format('MM/DD');
  } else {
    // 跨年 YYYY/MM/DD
    dateStr = dayjs(date).format('YYYY/MM/DD');
  }
  return dateStr;
};
