/*
 * @Author: jld
 * @Date: 2023-02-20 13:42:03
 * @LastEditTime: 2023-03-10 17:10:32
 * @LastEditors: jld
 * @Description: 忘记密码
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\UserLogin\components\ForgetPassWorld\index.tsx
 */
import Countdown from 'src/components/Countdown';
import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import LoginHeader from '../LoginHeader';
import styles from './index.module.less';
import IconFont from 'src/components/IconFont';
import { useNavigate } from 'react-router-dom';
import { isPhoneNumber } from 'src/utils/regularCheck';
import { isEmpty } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';
interface ITopItemListType {
  name: string;
  code: string;
  number: number;
}

const ForgetPassWorld: React.FC = (props) => {
  const [currentStepNumber, setCurrentStepNumber] = useState<number>(1);
  const [isStartCountdown, setIsStartCountdown] = useState<boolean>(false); // 是否开始倒计时
  const [nextStepButtonColor, setNextStepButtonColor] = useState<string>('rgba(223, 0, 36, 0.20)'); // 下一步按钮颜色
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [error, setError] = useState<string>(); // 密码不一致
  const intl = useIntl();
  const topItemList: ITopItemListType[] = [
    {
      name: intl.formatMessage({ id: 'enterAccountNumber' }),
      code: 'enterAccountNumber',
      number: 1,
    },
    {
      name: intl.formatMessage({ id: 'resetPassword' }),
      code: 'resetPassWorld',
      number: 2,
    },
    {
      name: intl.formatMessage({ id: 'finish' }),
      code: 'finished',
      number: 3,
    },
  ];
  const topItem = (topItemList: ITopItemListType[], currentStep: Number) => {
    return (
      <>
        {topItemList.map((item: ITopItemListType, index: number) => {
          return (
            <div className={styles.topItem} key={index}>
              {currentStep === item.number && <div className={styles.selectIconNumber} />}

              <div
                className={styles.iconNumber}
                style={{ background: currentStep === item.number ? 'rgba(0,0,0,0.85)' : 'rgba(38, 51, 63, 0.2)' }}
              >
                {item.number}
              </div>
              <div className={styles.name}>{item.name}</div>
              {index !== 2 && <div className={styles.line} />}
            </div>
          );
        })}
      </>
    );
  };
  // 第三步直接倒计时
  useEffect(() => {
    if (currentStepNumber === 3) setIsStartCountdown(true);
  }, [currentStepNumber]);

  const onFinish = () => {
    let formValue = form.getFieldsValue();

    if (currentStepNumber === 2 && formValue.number !== formValue.verificationCode) {
      // 第二步需要比对两个密码
      setError(intl.formatMessage({ id: 'passwordsDifferent' }));
      return;
    }
    form.resetFields();
    setCurrentStepNumber(currentStepNumber + 1);
    setNextStepButtonColor('rgba(223, 0, 36, 0.20)');
  };
  const getCode = () => {
    let formValue = form.getFieldsValue();
    let isPhoneNo = isPhoneNumber(formValue.number);
    if (formValue.number && isPhoneNo) {
      setIsStartCountdown(true);
    }
  };
  const countdownEnd = () => {
    setIsStartCountdown(false);
  };
  // input失去焦点
  const inputOnBlur = () => {
    let formValue = form.getFieldsValue();
    setError('');
    if (formValue.number && formValue.verificationCode) {
      setNextStepButtonColor('rgba(223, 0, 36, 0.85)');
    } else {
      setNextStepButtonColor('rgba(223, 0, 36, 0.20)');
    }
  };
  // 返回登录页
  const resetPassCountdownEnd = () => {
    navigate('/login');
  };
  // 底部文本框
  const bottomItem = (currentStepNumber: number) => {
    return currentStepNumber !== 3 ? (
      <div className={styles.bottom}>
        <Form
          name="forget"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          className={styles.loginFormItem}
          form={form}
        >
          <Form.Item
            name="number"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                pattern:
                  currentStepNumber === 1
                    ? /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                    : /^[A-Za-z0-9_]{1,}$/,
                message:
                  currentStepNumber === 1
                    ? intl.formatMessage({ id: 'quickLoginUserNameTips' })
                    : intl.formatMessage({ id: 'passWordTips' }),
              },
            ]}
            className={styles.phoneNumber}
          >
            <Input
              placeholder={
                currentStepNumber === 1
                  ? intl.formatMessage({ id: 'quickLoginLoginInfoTips' })
                  : intl.formatMessage({ id: 'passWordTips' })
              }
              style={{ width: '400px', height: '40px' }}
              onBlur={inputOnBlur}
              type={currentStepNumber === 2 ? 'password' : 'text'}
            />
          </Form.Item>
          <Form.Item className={styles.verificationCode}>
            <Form.Item
              name="verificationCode"
              rules={[
                {
                  required: true,
                  message:
                    currentStepNumber === 1
                      ? intl.formatMessage({ id: 'enterVerification' })
                      : intl.formatMessage({ id: 'confirmPassword' }),
                },
              ]}
            >
              <Input
                name="code"
                placeholder={
                  currentStepNumber === 1
                    ? intl.formatMessage({ id: 'enterSMSCode' })
                    : intl.formatMessage({ id: 'confirmPassword' })
                }
                style={{ width: currentStepNumber === 1 ? '280px' : '400px', height: '40px' }}
                onBlur={inputOnBlur}
                type={currentStepNumber === 2 ? 'password' : 'text'}
              />
            </Form.Item>

            {currentStepNumber === 1 && (
              <>
                <Button
                  type="primary"
                  onClick={getCode}
                  style={{ color: isStartCountdown ? 'rgba(0, 0, 0, 0.35)' : 'rgba(0, 0, 0, 0.85)' }}
                >
                  <FormattedMessage id="getVerificationCode" />
                </Button>
                {isStartCountdown && (
                  <Button type="primary">
                    <span className={styles.countDownButtonText}>
                      <Countdown startSecond={60000} isStart={isStartCountdown} countdownEnd={countdownEnd} />
                      s后重新获取
                    </span>
                  </Button>
                )}
              </>
            )}
          </Form.Item>
          <Form.Item name="nextStep" className={styles.nextStep}>
            {!isEmpty(error) && <div className={styles.loginError}>{error}</div>}
            <Button type="primary" htmlType="submit" style={{ background: nextStepButtonColor }}>
              <FormattedMessage id="nextStep" />
            </Button>
          </Form.Item>
        </Form>
      </div>
    ) : (
      <div className={styles.resetFinished}>
        <IconFont type="icon-xuanze" className={styles.iconStyle} />
        <div className={styles.tip}>
          <FormattedMessage id="resetSuccessfully" />
        </div>
        <div className={styles.countDownTip}>
          <Countdown startSecond={3000} isStart={isStartCountdown} countdownEnd={resetPassCountdownEnd} />
          <FormattedMessage id="returnLogin" />
        </div>
      </div>
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <LoginHeader isForget={true} />
      </div>
      <div className={styles.containerBox}>
        <div className={styles.top}>{topItem(topItemList, currentStepNumber)}</div>
        <div className={styles.bottom}>{bottomItem(currentStepNumber)}</div>
      </div>
    </div>
  );
};
export default ForgetPassWorld;
