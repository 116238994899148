/*
 * @Author: CZJ
 * @Date: 2023-02-15 20:25:08
 * @LastEditors: jld
 * @LastEditTime: 2023-03-27 11:32:55
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\microApp.ts
 * @Description: 驾驶舱
 */
import request from '../utils/request';

// 获取驾驶舱地址接口
export const getBiPages = (data: { date: string; type: string }) => {
  return request<any[]>({
    url: `/ys-core/ReportBpcBiPageController/bpcSelectBiPages?type=${data.type}`,
    method: 'post',
  });
};
