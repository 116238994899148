/*
 * @Author: 石化-王威 wangwei5953@pansoft.com
 * @Date: 2024-03-04 17:44:28
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @LastEditTime: 2024-03-12 15:53:43
 * @FilePath: \portal-react-start\src\pages\SystemSetting\model.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';

export interface changeThemeProps {
  value: string | number;
  onChange: (value: string | number | null) => void;
}

export const SYSTEM_SETTING: any = {
  GLOBAL_THEME_LIMIT: '10001',
  BROWSER_LOW_ALERT: '10002',
  BROWSER_DOWN_PATH: '10003',
};

export const BROWSER_LIST: any = ['Chrome', 'Edge', '360', 'Opera', 'QQ', 'Safari'];

export interface themeTokenModel {
  key: string;
  title: string;
  CSSVariable: string;
  changeThemeControl?: React.FC<changeThemeProps>;
}

export const themeModelList: themeTokenModel[] = [
  {
    key: 'colorPrimary',
    title: '自定义主题颜色1',
    CSSVariable: '--ant-primary-color',
  },
  {
    key: 'colorPrimaryHover',
    title: '自定义主题颜色2',
    CSSVariable: '--ant-primary-color-hover',
  },
  {
    key: 'colorPrimaryActive',
    title: '自定义主题颜色3',
    CSSVariable: '--ant-primary-color-active',
  },
];

// 兼容OSP 部分主题
export const compatibleOspThemeModeList: themeTokenModel[] = [
  {
    key: 'colorPrimary',
    title: '主题颜色',
    CSSVariable: '--primary-color',
  },
  {
    key: 'colorPrimaryHover',
    title: '悬浮时的颜色',
    CSSVariable: '--primary-hover-color',
  },
  {
    key: 'colorPrimaryActive',
    title: '激活时的颜色',
    CSSVariable: '--primary-active-color',
  },
];
