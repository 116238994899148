/*
 * @Author: LMY
 * @Date: 2023-08-02 09:23:29
 * @LastEditors: lmy
 * @LastEditTime: 2023-08-02 10:54:16
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\appCommunication.ts
 * @Description: 门户与子应用通信公共方法
 */
import { message } from 'antd';
import stores from 'src/stores';
import { MenuItem } from 'src/types';
import router from 'src/routes/router';
import { compress } from 'src/utils/uuid';
import { MESSAGE_KEY } from 'src/constant';
import { getMenuItemInMenuListByProperty } from 'src/utils/menu';
import WujieReact from 'wujie-react';
import {NotifyChildApplicationsType} from "../layout/components/TagsView/components/Taglist/type";

// 打开或关闭页签需要传递的参数
export interface OpenParams {
  title?: string;
  url?: string;
  menuId?: string;
  openType?: 'openTab' | 'browserTab' | 'openWindow' | 'fullScreen'; // 默认openTab
  loadType?: 'component' | 'iframe' | 'microApp'; // 默认microApp
  tabParams?: any; // 页签之前需要传递的参数
  intentionMethod: MESSAGE_KEY; // 对应的行为：打开页签/关闭页签/关闭当前页签
}

/**
 * 关闭无界tag
 * @param menu
 */
export const handleCloseTag = (menu: any) => {
  if (menu.config.loadType === 'microApp' && !menu['isEditing']) {
    const currentTabKey = menu.config.url.split('/')[0];
    let path = menu.config.url.slice(currentTabKey.length).replace('#/', '');
    if(path && path.search(/caption/gi) === -1 && menu?.name && currentTabKey === 'web'){
      if(path.indexOf('?') !== -1){
        path += `&caption=${menu?.name}`
      }else{
        path += `?caption=${menu?.name}`
      }
    }

    console.log(
        `WUJIE-ACTION-给==${currentTabKey.toUpperCase()}_TAB_KEY==应用通信，action是close，path是----${path}`,
    );
    WujieReact.bus.$emit(`${currentTabKey.toUpperCase()}_TAB_KEY`, {
      action: 'close',
      path,
      isScriptCode:true,
    } satisfies NotifyChildApplicationsType);
  }
};

/**
 * @description:  通知门户打开/关闭/关闭当前页签
 * @param {*} MESSAGE_KEY   通知接收key
 * @param {OpenParams} data   接收到的参数
 * @return {*}
 **/
export const appCommunication = (data: OpenParams) => {
  console.log('门户与子应用通信，接收的信息是：', data);
  const { intentionMethod, menuId, title, url, loadType, openType, tabParams } = data;
  switch (intentionMethod) {
    case MESSAGE_KEY.OPEN_PAGE: {
      // 打开相应页签
      if (menuId) {
        let menu = getMenuItemInMenuListByProperty(stores.AppState.leftMenuList, 'id', menuId);
        if (!menu) {
          return;
        }
        router.navigate(menu.path);
      } else {
        if (title) {
          let menuId = compress(url ?? '');
          console.log(`子应用打开动态菜单，菜单的url是--${url}。。通过url生成的id是${menuId}`);
          let menu: MenuItem = {
            id: menuId,
            name: title,
            path: `/${loadType ?? 'microApp'}/${menuId}`,
            config: {
              openType: openType ?? 'openTab',
              loadType: loadType ?? 'microApp',
              url: url ?? '',
            },
            tabParams: tabParams,
          };
          stores.TagsViewStore.addTag(menu);
          router.navigate(`${menu.path}`);
        } else {
          message.warning('打开页签未传递相应标签名称！');
        }
      }
      break;
    }
    case MESSAGE_KEY.CLOSE_PAGE: {
      // 关闭相应页签
      if (!url) {
        message.warning('关闭页签未传递对应路径！');
        return;
      }
      stores.TagsViewStore.deleteTagByUrl(url);
      let nextTab = stores.TagsViewStore.tagList[stores.TagsViewStore.tagList.length - 1];
      router.navigate(`${nextTab.path}`);
      break;
    }
    case MESSAGE_KEY.CLOSE_CURRENT_PAGE: {
      // 关闭当前页签
      const currentClosePath = stores.TagsViewStore.activePath;
      const currentTagList = stores.TagsViewStore.tagList;
      const currentTagItem = currentTagList.find((tag: any) => tag.path === currentClosePath);
      console.log('关闭的tag标签',currentTagItem)
      if(currentTagItem){
        handleCloseTag(currentTagItem);
      }
      stores.TagsViewStore.deleteTag(stores.TagsViewStore.activePath);
      let nextTab = stores.TagsViewStore.tagList[stores.TagsViewStore.tagList.length - 1];
      router.navigate(`${nextTab.path}`);
      break;
    }
    default:
      message.warning('未识别的消息内容，请检查！');
      break;
  }
};
