import Chrome from 'src/asset/Chrome.svg';
import Edge from 'src/asset/Edge.svg';
import Opera from 'src/asset/Opera.svg';
import Safari from 'src/asset/Safari.svg';
import browser360 from 'src/asset/360.png';
import qq from 'src/asset/qq.png';

export const APP_SETTINGS_LOW_VERSION_BROWSER = {
  APP_NAME: '普联ERP门户中心',
  LOW_VERSION_BROWSER_LIST: [
    {
      browserId: 'Chrome',
      browserName: '谷歌',
      version: '108',
      icon: Chrome,
    },
    {
      browserId: 'Edge',
      browserName: 'Edge',
      version: '108',
      icon: Edge,
    },
    {
      browserId: '360',
      browserName: '360',
      version: '108',
      icon: browser360,
    },
    {
      browserId: 'QQ',
      browserName: 'QQ',
      version: '11',
      icon: qq,
    },
    {
      browserId: 'Safari',
      browserName: 'Safari',
      version: '16',
      icon: Safari,
    },
  ],
};

export const SYSTEM_SETTING: any = {
  GLOBAL_THEME_LIMIT: '10001',
  BROWSER_LOW_ALERT: '10002',
  BROWSER_DOWN_PATH: '10003',
};
