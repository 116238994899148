/*
 * @Author: jld
 * @Date: 2023-08-16 16:14:34
 * @LastEditTime: 2023-08-16 16:16:47
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\constant\eventsKey.ts
 */
export enum EVENTtBUS_KEY {
  OPEN_PAGE = 'OPEN_PAGE',
}
