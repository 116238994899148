/*
 * @Author: jld
 * @Date: 2023-02-15 17:49:54
 * @LastEditTime: 2023-02-15 19:45:06
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\formlocalebase\index.tsx
 */
import { ConfigProvider } from 'antd';

function FormLocaleBase(props: any) {
  const { children } = props;
  return <ConfigProvider>{children}</ConfigProvider>;
}

export default FormLocaleBase;
