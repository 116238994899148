/*
 * @Author: 石化-王威 wangwei5953@pansoft.com
 * @Date: 2024-03-15 09:23:17
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @LastEditTime: 2024-03-18 15:28:19
 * @FilePath: \portal-react-start\src\stores\components\ThemeStore.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { action, observable } from 'mobx';

export interface ITopMenuItemProps {
  name: string;
  icon: string;
  key: string;
  [key: string]: any;
}

class ThemeStore {
  // 默认主题id
  @observable public defaultThemeId: any[] = [];

  // 主题菜单列表
  @observable public usableThemeListState: ITopMenuItemProps[] = [];

  // 灰度主题
  @observable public grayTheme = '';

  // 全局主题id
  @observable public globalThemeId = '';

  // 全局主题id
  @observable public globalThemeEndTime = '';

  // 修改默认主题id
  @action.bound
  public setDefaultThemeId(themeId: any[]) {
    this.defaultThemeId = themeId;
  }

  // 修改主题菜单列表
  @action.bound
  public setUsableThemeListState(list: any[]) {
    this.usableThemeListState = list;
  }

  // 修改全局主题id
  @action.bound
  public setGlobalThemeId(state: string) {
    this.globalThemeId = state;
  }

  // 修改全局主题结束时间
  @action.bound
  public setGlobalThemeEndTime(state: string) {
    this.globalThemeEndTime = state;
  }

  // 修改灰度主题
  @action.bound
  public setGrayTheme(state: string) {
    this.grayTheme = state;
  }
}

const themeStore = new ThemeStore();
export default themeStore;
