/*
 * @Author: jld
 * @Date: 2023-02-06 20:25:56
 * @LastEditTime: 2023-08-04 21:46:23
 * @LastEditors: jld
 * @Description:自定义模板首页
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\CustomDashboard\index.tsx
 */
import IconFont from 'src/components/IconFont';
import { Layout } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import DragDropMenuList from './components/DragDropMenu';
import styles from './index.module.less';
import { HeadButtonGroup } from './components/HeadButtonGroup';
import { GridLayoutContent } from '../../components/GridLayoutContent';
import { DataNode } from 'antd/es/tree';
import { inject, observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteDashboard, getDashboard, sortDashboard } from 'src/api';

import { IDashboard } from 'src/api/types/dashboard';
import { FormattedMessage } from 'react-intl';
import { log } from 'console';

const { Header, Content, Sider } = Layout;
const Custom: React.FC<{ [key: string]: any }> = inject('DashboardStore')(
  observer((props) => {
    const {
      DashboardStore: { dashboardList, setDashboardList },
    } = props;
    let menuInfo: DataNode[] = [];
    const navigate = useNavigate();
    const location = useLocation();

    let id = location.state?.id ?? dashboardList.length > 0 ? dashboardList[0]?.id ?? '' : '';

    const [activeMenuKey, setActiveMenuKey] = useState<string | number>(id); // 选中菜单key
    const activeMenuKeyRef = useRef<string | number>(id ?? '');
    const [menuInfoNow, setMenuInfoNow] = useState<DataNode[]>(); // 左侧菜单

    useEffect(() => {
      if (dashboardList.length === 0) {
        getDashboard().then((res) => {
          setDashboardList(res);
        });
      }
      let backgroundUrl: string = window.location.origin;
      document.documentElement.style.setProperty('--background-url', `url(${backgroundUrl}/portal/images/background.jpg)`);
    }, []);

    useEffect(() => {
      menuInfo = dashboardList?.map((obj: any) => {
        return {
          title: obj.name,
          key: obj.id,
          icon: obj.icon,
        };
      });
      setMenuInfoNow(menuInfo);
      // 删除时指定下一个activeMenuKey
      if (!activeMenuKey && menuInfo?.length > 0) {
          setActiveMenuKey(menuInfo[0].key as string);
          activeMenuKeyRef.current = menuInfo[0].key as string;
      }
    }, [dashboardList]);

    // 左侧菜单点击
    const menuClick = (key: any) => {
      setActiveMenuKey(key[0] ?? activeMenuKey);
      activeMenuKeyRef.current = key[0] ?? activeMenuKey;
    };

    // 删除选中的左侧菜单
    const deleteItem = () => {
      if (dashboardList.length > 1 && activeMenuKey) {
        deleteDashboard({ id: activeMenuKey }).then(() => {
          // 判断删除的是第几个
          let indexNum: number = dashboardList?.findIndex((item: IDashboard) => item.id === activeMenuKey);
          let nextActiveKey =
            indexNum === dashboardList.length - 1 ? dashboardList[0].id : dashboardList[indexNum + 1].id;

          getDashboard().then((res) => {
            setDashboardList(res);
            setActiveMenuKey(nextActiveKey);
            activeMenuKeyRef.current = nextActiveKey;
          });
        });
      }
    };
    // 左侧菜单顺序调整后保存用
    const finished = () => {
      const params = menuInfoNow?.map((item: DataNode) => {
        return item.key as string;
      });
      if (params) {
        sortDashboard({ dashboardIds: params }).then((res) => {
          if (res)
            getDashboard().then((res) => {
              setDashboardList(res);
            });
        });
      }
      navigate('/dashboard');
    };
    // 左侧菜单顺序调整后数据
    const onDropFn = (menuInfoParam: DataNode[]) => {
      setMenuInfoNow(menuInfoParam);
    };

    return (
      <Layout className={styles.customDashboard}>
        <Header className={styles.customDashboardHeader}>
          <div className={styles.headerLeft}>
            <IconFont type="icon-yeqianlan-shouye" className={styles.iconFont} />
            <div className={styles.title}>
              <FormattedMessage id="customizeHomePageKanBan" />
            </div>
          </div>
          <div className={styles.headerRight}>
              <HeadButtonGroup
                deleteItem={deleteItem}
                activeMenuKey={activeMenuKeyRef.current}
                finished={finished}
                menuInfoNow={menuInfoNow}
              />
          </div>
        </Header>
        <Layout>
          <Sider width={240} className={styles.customDashboardSider}>
            {menuInfoNow && activeMenuKey && (
              <DragDropMenuList
                menuInfo={menuInfoNow}
                onClick={menuClick}
                onDropFn={onDropFn}
                selectedKeys={activeMenuKey}
              />
            )}
          </Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content className={styles.customDashboardContent}>
              {activeMenuKey && dashboardList && (
                <GridLayoutContent activeMenuKey={activeMenuKeyRef.current} pageInfo={dashboardList} />
              )}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }),
);
export default Custom;
