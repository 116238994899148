/*
 * @Author: yar
 * @Date: 2023-02-08 11:05:39
 * @LastEditTime: 2023-08-17 18:07:37
 * @Description: 功能分类搜索结果列表
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\Header\components\GlobalSearch\components\SearchcCassify\index.tsx
 */
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import styles from './index.module.less';
import { Tooltip, message } from 'antd';
import { getClassifyType } from '../SearchPanel/utils/viewFormat';
import IconFont from 'src/components/IconFont';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import FunctionSearchTitle from 'src/components/FunctionDrawer/components/FunctionSearchTitle';
import { SEARCH_TYPE } from 'src/constant/searchType';
import { OPEN_TYPE_ARRAY } from 'src/constant/menu';
import { MenuItem } from 'src/types';
import { openMenuItem } from 'src/utils/menu';
import EventBus from 'src/utils/events';
import { EVENTtBUS_KEY } from 'src/constant/eventsKey';
import { helpDocument } from 'src/utils/helpDocument';
import { idbGetItem } from 'src/utils/storage';
import { STORAGE_KEY } from 'src/constant';
import { idbSetItem, getCookies } from 'src/utils/storage';
import { Cookie_KEY } from 'src/constant/storageKey';
interface IAllData {
  [key: string]: IAllDataItem[];
}
interface IAllDataItem {
  key: string;
  code: string;
  name: string;
  other: string;
}
let allData: IAllData = {
  fuc: [],
  help: [],
  bill: [],
  message: [],
};

const IndexPage: React.FC<{
  classifyData: any[]; // 监听到的分类结果数据
  selectValue: string[]; // 当前选中的数据（key值);
  onChange: Function; // 点击行时触发的方法(获取到当前点击的行数据)
  onSelected?: Function;
  setOptionsData?: Function;
  onClick: (key: string, allInfo: any) => void;
  visiblePanel: string; // 根据输入栏的变化实时显示的面板
  style?: any; // 自定义样式
  historyFuncData: any[]; // 历史访问功能数据
}> = (props) => {
  const { classifyData, selectValue, onChange, style, onClick, visiblePanel, historyFuncData } = props;

  const itemHeight = 30; // 行高
  const [resultList, setResultList] = useState<IAllData>(allData); // 当前渲染列表数据
  const [highWords, setHighWords] = useState<string>(selectValue[0]); // 需要高亮的关键词
  const [shadowItemKey, setShadowItemKey] = useState<string>(selectValue[0] || classifyData[0]?.key); // 记录当前阴影行
  const visibleCount = useMemo(() => Math.ceil(200 / itemHeight) + 2, [200]); // 可视行数
  const [list, setList] = useState(classifyData.slice(0, visibleCount)); // 当前渲染列表数据
  const renderListRef = useRef<any>(classifyData.slice(0, visibleCount));
  const listRef = useRef<any>();
  const [visitContentList, setVisitContentList] = useState<any[]>();
  let navigate = useNavigate();
  const getData = (data: any) => {
    let fucData: IAllDataItem[] = [];
    let helpData: IAllDataItem[] = [];
    let billData: IAllDataItem[] = [];
    let messageData: IAllDataItem[] = [];
    data?.length &&
      data.forEach((item: any) => {
        if (Object.keys(item?.children)?.length) {
          let classifyData = item?.children;
          Object.keys(classifyData).forEach((key: string) => {
            let listTemp = classifyData[key];
            if (key === 'fuc') {
              listTemp.forEach((temp: any) => {
                fucData.push(temp);
              });
            } else if (key === 'help') {
              listTemp.forEach((temp: any) => {
                helpData.push(temp);
              });
            } else if (key === 'bill') {
              listTemp.forEach((temp: any) => {
                billData.push(temp);
              });
            } else {
              listTemp.forEach((temp: any) => {
                messageData.push(temp);
              });
            }
          });
        }
      });
    allData = { fuc: fucData, help: helpData, bill: billData, message: messageData };
    setResultList(allData);
  };
  useEffect(() => {
    if (classifyData?.length) {
      getData(classifyData);
    }
  }, [classifyData]);

  useEffect(() => {
    setHighWords(selectValue[0]);
  }, [selectValue, historyFuncData]);

  const scrollEvent = useCallback(
    (e: any) => {
      const startIdx = Math.floor(e.target.scrollTop / itemHeight);
      const endIdx = startIdx + visibleCount;
      renderListRef.current = classifyData.slice(startIdx, endIdx);
      setList(classifyData.slice(startIdx, endIdx));
      const offset = startIdx * itemHeight;
      listRef.current.style.top = offset + 'px';
    },
    [classifyData],
  );

  /**
   * 行点击事件
   * @param selected
   */
  const selectRow = (selected: any, typeKey?: string) => {
    setShadowItemKey(selected?.key ?? selected?.id);
    onChange(selected, typeKey);
  };
  /**
   * 筛选出高亮词
   */
  const findOutHighWord = (label: string, searchStr: string) => {
    let dom: any = [];
    const reg = new RegExp(searchStr, 'gi');
    let start = 0;
    let end = 0;
    let newStr = '';
    let arr;
    while ((arr = reg.exec(label)) !== null) {
      end = arr.index;
      newStr = newStr + label.slice(start, end);
      start = end;
      end = reg.lastIndex;
      newStr = newStr + `${label.slice(start, end)}`;
      start = end;
    }
    dom.push(
      <div key={`hedear-search-hight-${dom['length']}`} className={styles.innerText}>
        <Tooltip title={newStr + label.slice(end)}>
          <span style={{ color: '#fc9620' }}>{newStr}</span>
          <span>{label.slice(end)}</span>
        </Tooltip>
      </div>,
    );
    return dom;
  };

  const onFuncItemClick = (item: MenuItem | any) => {
    let isOpenMenu = OPEN_TYPE_ARRAY.find((value) => value === item.config.openType);
    if (isOpenMenu) {
      openMenuItem(item);
    } else {
      if (item.isUesStatus) {
        navigate(item.path);
      } else {
        message.warning('该功能不可用');
      }
    }
  };
  const clickFun = (leftKey: string, item: any) => {
    switch (leftKey) {
      case SEARCH_TYPE.HELP: // 帮助
        // window.open(item.fileSrc);
        window.open(`${helpDocument()}/portal/pdf/${item.name}`);
        break;
      case SEARCH_TYPE.FUC: // 功能
        onFuncItemClick(item);
        break;
    }
    EventBus.emit(EVENTtBUS_KEY.OPEN_PAGE);
  };
  useEffect(() => {
    const getDataTemp = async () => {
      let historyUse = (await idbGetItem(`${STORAGE_KEY.RECENT_USE}-${getCookies(Cookie_KEY.USER_NAME)}`)) ?? [];
      setVisitContentList(historyUse);
    };
    getDataTemp();
  }, [visiblePanel]);
  return (
    <div className={styles.SearchClassifyBox} style={style}>
      {visiblePanel === 'recommend' ? (
        <>
          <div className={styles.middlePanel} style={{ height: selectValue[0].length > 4 ? '90%' : '93%' }}>
            <div className={styles.leftPart}>
              {Object.keys(resultList) &&
                Object.keys(resultList)
                  .slice(0, 2)
                  .map((leftKey: string, index: number) => {
                    return (
                      <div className={styles.classifyItem} key={leftKey}>
                        <div className={styles.titleRow} onClick={() => onClick(leftKey, resultList)}>
                          <div className={styles.classifyName}>
                            {getClassifyType(leftKey)}
                            {` (${resultList[leftKey]?.length})`}
                          </div>
                          <IconFont type="icon-yeqianlan-xiangyou" className={styles.arrowIcon} />
                        </div>
                        <div className={styles.classifyContent}>
                          {resultList[leftKey].length ? (
                            resultList[leftKey].slice(0, 5).map((item: any, ii: number) => {
                              return (
                                <div
                                  key={item.key}
                                  style={{
                                    height: itemHeight + 'px',
                                    lineHeight: itemHeight + 'px',
                                  }}
                                  className={`${styles.tableBodyRow} ${
                                    shadowItemKey === item.name ? styles.rowSelect : ''
                                  } `}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    selectRow(item, leftKey);
                                  }}
                                >
                                  <div
                                    className={styles.searchName}
                                    onClick={() => {
                                      clickFun(leftKey, item);
                                    }}
                                  >
                                    {highWords && <FunctionSearchTitle title={item.name} keyWords={highWords} />}
                                    {/* {highWords && findOutHighWord(item.name, highWords)} */}
                                  </div>
                                  {/* <div className={styles.subName}>{item.other}</div> */}
                                </div>
                              );
                            })
                          ) : (
                            <div className={styles.notFoundBlock}>
                              <IconFont type="icon-wuneirong1" className={styles.notIcon} />
                              <div className={styles.notFoundLabel}>
                                <FormattedMessage id="notFound" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
            </div>
            <div className={styles.rightPart}>
              {Object.keys(resultList) &&
                Object.keys(resultList)
                  .slice(2, 4)
                  .map((rightKey: any, index: number) => {
                    return (
                      <div className={styles.classifyItem} key={rightKey}>
                        <div className={styles.titleRow} onClick={() => onClick(rightKey, resultList)}>
                          <div className={styles.classifyName}>
                            {getClassifyType(rightKey)}
                            {` (${resultList[rightKey]?.length})`}
                          </div>
                          <IconFont type="icon-yeqianlan-xiangyou" className={styles.arrowIcon} />
                        </div>
                        <div className={styles.classifyContent}>
                          <div className={styles.stayTuned}>
                            {' '}
                            <FormattedMessage id="stayTuned" />
                          </div>
                          {/* {resultList[rightKey]?.length ? (
                            (resultList[rightKey]?.length >= 5
                              ? resultList[rightKey].slice(0, 5)
                              : resultList[rightKey]
                            ).map((item: any, ii: number) => {
                              return (
                                <div
                                  key={item.key}
                                  style={{
                                    height: itemHeight + 'px',
                                    lineHeight: itemHeight + 'px',
                                  }}
                                  className={`${styles.tableBodyRow} ${
                                    shadowItemKey === item.key ? styles.rowSelect : ''
                                  } `}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    selectRow(item, rightKey);
                                  }}
                                >
                                  <div className={styles.searchName}>
                                    {highWords && findOutHighWord(item.key, highWords)}
                                  </div>
                                  <div className={styles.subName}>{item.other}</div>
                                </div>
                              );
                            })
                          ) : (
                            <div className={styles.notFoundBlock}>
                              <IconFont type="icon-wuneirong1" className={styles.notIcon} />
                              <div className={styles.notFoundLabel}>
                                <FormattedMessage id="notFound" />
                              </div>
                            </div>
                          )} */}
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
          {/*    <div className={styles.footerPanel}>
            {Object.keys(resultList) ? (
              <div className={styles.footerRow} onClick={() => onClick('none', resultList)}>
                <div className={styles.detailText}>
                  <FormattedMessage id="detailedSearchResults" />
                </div>
                <IconFont type="icon-yeqianlan-xiangyou" className={styles.arrowIcon} />
              </div>
            ) : (
              ''
            )}
          </div> */}
        </>
      ) : (
        <div className={styles.visitPart}>
          <div className={styles.visitName}>
            <FormattedMessage id="recentAccess" />
          </div>
          <div className={styles.visitContent}>
            {visitContentList?.length ? (
              visitContentList.slice(0, 5).map((item: any, index: number) => {
                return (
                  <div
                    key={item.key}
                    style={{
                      height: itemHeight + 'px',
                      lineHeight: itemHeight + 'px',
                    }}
                    className={`${styles.tableBodyRow} ${shadowItemKey === item.key ? styles.rowSelect : ''} `}
                    onClick={(e) => {
                      e.stopPropagation();
                      let isOpenMenu = OPEN_TYPE_ARRAY.find((value) => value === item.config.openType);
                      if (isOpenMenu) {
                        openMenuItem(item);
                      } else {
                        if (item.isUesStatus) {
                          navigate(item.path);
                        } else {
                          message.warning('该功能不可用');
                        }
                      }
                    }}
                  >
                    <div className={styles.searchName}>{item.name}</div>
                  </div>
                );
              })
            ) : (
              <div className={styles.notFoundBlock}>
                <IconFont type="icon-wuneirong1" className={styles.notIcon} />
                <div className={styles.notFoundLabel}>
                  <FormattedMessage id="notFound" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className={styles.searchDetail}
        onClick={() => {
          navigate('/searchDetails', { state: { search: highWords } });
          EventBus.emit(EVENTtBUS_KEY.OPEN_PAGE);
        }}
      >
        <div className={styles.detailText}>
          <FormattedMessage id="detailedSearch" />
        </div>
        <IconFont type="icon-yeqianlan-xiangyou" className={styles.arrowIcon} />
      </div>
    </div>
  );
};

export default IndexPage;
