/*
 * @Author: ZLL
 * @Date: 2023-02-09 11:15:14
 * @LastEditors: lmy
 * @LastEditTime: 2023-11-22 17:55:35
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\components\FunctionSwitchingDrawer\index.tsx
 * @Description: 点击所有功能弹出的弹窗
 */

import React, { useState } from 'react';
import FunctionalGrouping from '../FunctionalGrouping';
import FunctionalTile from '../FunctionalTile';
import styles from './index.module.less';
import IconFont from 'src/components/IconFont';
import { Drawer } from 'antd';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

const FunctionSwitchingDrawer: React.FC<{
  afterOpenChange?: (open: boolean) => void;
  [key: string]: any;
}> = inject('DrawerStore')(
  observer((props) => {
    const {
      afterOpenChange,
      DrawerStore: { showFunDrawer },
      toggleActive
    } = props;

    return (
      <Drawer
        open={showFunDrawer}
        maskClosable={false}
        mask={false}
        placement="left"
        width={1241}
        rootClassName={styles.functionSwitchingDrawer}
        getContainer={false}
        afterOpenChange={afterOpenChange}
        destroyOnClose={true}
      >
        {toggleActive === 'classification' && <FunctionalGrouping />}
        {toggleActive === 'tile' && <FunctionalTile />}
      </Drawer>
    );
  }),
);

export default FunctionSwitchingDrawer;
