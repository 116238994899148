/*
 * @Author: ZY
 * @Date: 2023-02-28 21:35:32
 * @LastEditors: jld
 * @LastEditTime: 2023-08-16 16:13:02
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\eventBus.ts
 * @Description: 微前端门户通信中心
 */
import { MESSAGE_KEY } from 'src/constant';
import WujieReact from 'wujie-react';
const { bus } = WujieReact;
import { appCommunication, OpenParams } from './appCommunication';

/**
 * @description:  通知门户打开页签，支持打开系统内和外部页签
 * @param {*} MESSAGE_KEY   通知接收key
 * @param {OpenParams} args   接收到的参数
 * @return {*}
 */
bus.$on(MESSAGE_KEY.OPEN_PAGE, (args: OpenParams) => {
  appCommunication({ ...args, intentionMethod: MESSAGE_KEY.OPEN_PAGE });
});

/**
 * @description: 通知门户关闭tab页签
 * @param {*} MESSAGE_KEY   通知接收key,
 * @param {OpenParams} args   接收到的参数
 * @return {*}
 */
bus.$on(MESSAGE_KEY.CLOSE_PAGE, (args: Record<string, any>) => {
  appCommunication({ ...args, intentionMethod: MESSAGE_KEY.CLOSE_PAGE });
});

/**
 * @description: 通知门户关闭当前tab页签
 * @param {*} MESSAGE_KEY   通知接收key,
 * @return {*}
 */
bus.$on(MESSAGE_KEY.CLOSE_CURRENT_PAGE, () => {
  appCommunication({ intentionMethod: MESSAGE_KEY.CLOSE_CURRENT_PAGE });
});
