/*
 * @Author: ZY
 * @Date: 2023-02-27 17:46:56
 * @LastEditors: YL
 * @LastEditTime: 2023-12-11 10:15:10
 * @FilePath: /portal-react-start/src/constant/messageKey.ts
 * @Description: eventBus
 */
export enum MESSAGE_KEY {
  OPEN_PAGE = 'OPEN_PAGE',
  PAGE_LOADED = 'PAGE_LOADED',
  CLOSE_PAGE = 'CLOSE_PAGE',
  CLOSE_CURRENT_PAGE = 'CLOSE_CURRENT_PAGE',
  CHANGE_ORGINFO = 'CHANGE_ORGINFO',
  FIXED_CHANGE = 'FIXED_CHANGE',
  RETRACT_CHANGE = 'RETRACT_CHANGE',
  THEME_CHANGE = 'THEME_CHANGE',
  LANG_CHANGE = 'LANG_CHANGE'
}
