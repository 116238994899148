/*
 * @Author: yar
 * @Date: 2023-02-08 20:24:57
 * @LastEditTime: 2023-02-08 20:38:21
 * @LastEditors: yar
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\Header\index.tsx
 */
import React, { useCallback, useEffect, useMemo, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import { IGlobalSearchProps } from './type';
import styles from './index.module.less';
import SearchPanel from './components/SearchPanel';

const IndexPage: React.FC<IGlobalSearchProps> = (props) => {
  return (
    <div className={styles.portalHeaderWrapGlobalSearch}>
      <SearchPanel />
    </div>
  );
};
export default IndexPage;
