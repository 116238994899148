/*
 * @Author: CZJ
 * @Date: 2023-02-15 20:25:08
 * @LastEditors: jld
 * @LastEditTime: 2023-03-27 11:32:55
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\microApp.ts
 * @Description: 代办流程
 */
import qs from 'qs';
import request from '../utils/request';

/**
 * 获取任务代办列表
 * @param {objId} params
 */
export function selectProcessInstanceTodoList(params: any) {
  const urlParams = qs.stringify(params);
  return request<any>({
    url: `/ys-core/ReportBpcTaskController/selectProcessInstanceTodoList?${encodeURI(urlParams)}`,
    method: 'get',
  });
}

// 获取系统配置
export function getSysConf(params: any) {
  return request<any>({
    url: `/ys-core/parent//${params.seriesId}/config`,
  });
}

export function loadTaskGroupList(params: any) {
  return request<any>({
    url: `/ys-core/ReportBpcTaskController/loadTaskGroupList`,
    method: 'post',
    data: params.body,
  });
}

export function SelectBacklog(params: any) {
  return request<any>({
    url: `/ys-core/ReportBpcBacklogController/SelectBacklog?type=${params.type}`,
    method: 'get',
  });
}

export function selectNewGlobalFlowActivityInfo(params: any) {
  const urlParams = qs.stringify(params);
  return request<any>({
    url: `/ys-core/ReportBpcTaskController/selectNewGlobalFlowActivityInfo?${encodeURI(urlParams)}`,
    method: 'get',
  });
}

export function selectActivityInstsList(params: any) {
  const urlParams = qs.stringify(params);
  return request<any>({
    url: `/ys-core/ReportBpcTaskController/selectActivityInstsList?${encodeURI(urlParams)}`,
    method: 'get',
  });
}

// 计划列表
export function selectPlanList(params: any) {
  return request<any>({
    url: `/ys-core/ReportBpcQueryGroupController/selectPlanList`,
    method: 'get',
  });
}

// 待办列表
export function selectQueryGroupListMenu(params: any) {
  return request<any>({
    url: `/ys-core/ReportBpcQueryGroupController/selectQueryGroupListMenu`,
    method: 'get',
  });
}
