/*
 * @Author: jld
 * @Date: 2023-03-09 18:03:16
 * @LastEditTime: 2024-03-08 14:44:19
 * @LastEditors: 石化-王威 wangwei5953@pansoft.com
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\locale\enUS.ts
 */
export default {
  projectName: 'panSoft ERP Portal Center',
  // 登录
  scanCodeLogin: 'Scan code login',
  OpenCodeLogIn: 'Open the APP and scan the QR code to log in',
  DownloadPanSoftAPP: 'Download the PanSoft APP',
  target: 'Provides in-depth specific solutions for a wide range of areas',
  learnMore: 'Click to learn more about our ERP products',
  viewDetails: 'View details',

  accountLogin: 'Account login',
  quickLogin: 'Quick login',
  forgetPassword: 'forget Password',
  login: 'login',
  loginPrompt:
    'Login is deemed to have agreed to the third-party account binding Agreement, terms of Service, and Privacy policy',

  completeSecurityVerification: 'complete the verification',
  changeOne: 'Change',
  completePuzzle: 'Drag the slider',
  UnderLoad: 'Under load...',
  getVerificationCode: 'Get verification code',
  afterSecond: 'resend',
  loginInfoTips: 'User name/employee ID/mobile phone number/Email address',
  userNameTips: 'Please enter the account name',
  passWordTips: 'Please enter password',
  quickLoginLoginInfoTips: 'Please enter your mobile phone number',
  quickLoginUserNameTips: 'Please enter the correct mobile phone number',
  quickLoginPassWordTips: 'Please enter the mobile phone verification code',
  // 抽屉菜单相关
  myCollection: 'My collection',
  allFunctions: 'All functions',
  recentVisit: 'Recent visit',
  recentDocument: 'Recent document',
  return: 'return',
  // 设置
  accountSetting: 'Account setting',
  systemSetting: 'System setting',
  exitAccount: 'Exit account',
  overallStyleSetting: 'Overall style',
  ThemeColor: 'Theme color',
  systemLanguageSetting: 'System language',
  defaultMenuSetting: 'Default menu',
  customizeHomePageKanBan: 'Customize the home page Kanban',
  selectSave: 'A custom Kanban contains at least one component. Select and save it',

  // 账号设置
  baseInformation: 'Base information',
  userGroupInformation: 'User group information',
  role: 'Role',
  securitySet: 'Security setting',

  // 清楚缓存
  clearCache: 'Clear Cache',
  clearingCacheSucceeded: 'Clearing cache succeeded',
  sureClearingCache: 'Sure about clearing the cache?',
  clearTip: 'Clearing the erp front-end cache does not affect the system',
  determine: 'confirm',
  cancel: 'cancel',
  // 自定义模板按钮
  newAdd: 'add',
  edit: 'edit',
  delete: 'delete',
  finish: 'finish',
  save: 'save',
  selectTemplate: 'Select the preset template layout',
  dashboardTips: 'You can drag widgets to add, move, and other custom actions',
  enterKanBanName: 'Please enter a Kanban name',
  selectTemplateTips: 'You can choose a preset template layout',
  dragWidget: 'Or drag the widget to add, move and other custom operations',
  searchTips: 'Enter search content here',
  notFound: 'The relevant content was not found',
  recentSearch: 'Recent search',
  guessSearch: 'Guess you want to search',
  clearHistory: 'Whether to clear history?',
  close: 'close',
  historyCleared: 'History has been cleared',
  recentAccess: 'Recent access function',
  enterKeywords: 'Please enter keywords',
  detailedSearchResults: 'Detailed search results',
  detailedSearch: 'Detailed search',
  closeLeft: 'Close left',
  closeRight: 'close right',
  closeOther: 'close other',
  closeAll: 'close All',
  pinTab:'Pin Tab',
  unPinTab:'Unpin Tab',
  homePage: 'Home page',
  enterAccountNumber: 'Enter account number',
  resetPassword: 'Reset password',
  passwordsDifferent: 'The two passwords are different. Please re-enter them',
  enterSMSCode: 'Enter the SMS verification code',
  enterVerification: 'Please enter the verification code',
  confirmPassword: 'Please confirm the new password',
  resetSuccessfully: 'Reset successfully!',
  nextStep: 'next Step',
  returnLogin: 'Seconds automatically return to login',
  // 登录
  aboutUs: 'About us',
  contactUs: 'Contact us ',
  newsUpdate: 'News update',
  softwareProduct: 'Software product',
  cloudService: 'Cloud service',
  domainSolution: 'Domain solution',
  typicalCase: 'Typical Case',
  serviceSupport: 'Service Support',
  allRightsReserved: 'Copyright © 2001-2023. All rights reserved',
  // 个人设置
  personageTitle: 'Essential information',
  personageName: 'Name',
  headSculpture: 'Head sculpture',
  userName: 'User name',
  employeeNumber: 'Employee number',
  personageCertificateStatus: 'Status',
  personageSbmit: 'Submit',
  personageAccountID: 'ID',
  personageReimbursementLevel: 'Rank',
  personageHRNumber: 'HR Number',
  personageRegistrationDate: 'Regdate',
  personageAddressCompany: 'corporation',
  personageAddressCompanyOther: 'corporation2',
  personageDepartment: 'Department',
  personageMail: 'Mailbox',
  personageMailRules1: 'Invalid mailbox format!',
  personageMailRules2: 'Please enter your email address!',
  personagePhone: 'phone',
  personagePhoneRules1: 'The format of the mobile phone number is incorrect',
  personagePhoneRules2: 'Please enter your contact number!',
  personageFax: 'Fax number',
  personageFaxRules1: 'Fax format error',
  personageFaxRules2: 'Please enter your fax number!',
  personageSave: 'Save',
  personageSaveSuccess: 'Successful preservation',
  theOldPasswordIsTheSameAsTheNewPassword: 'The old password is the same as the new password',
  theConfirmationIsDifferentFromTheNewPassword: 'The confirmation is different from the new password',
  personageUploadingFormat: 'You can only upload JPG/PNG file!',
  personageUploadingSize: 'Image must smaller than 2MB!',
  // 扩展信息
  extendedInformation: 'Extended information',
  sex: 'Sex',
  birthday: 'Birthday',
  idType: 'Id type',
  idNumber: 'Id number',
  nativePlace: 'Native place',
  address: 'address',

  personageSettings: 'Account settings',
  personagePasswordSetting: 'Password Setting',
  personageMessageSetting: 'Message Settings',
  personageOriginalPassword: 'Old Password',
  personageNewsPassword: 'New Password',
  personagePleaseEnter: 'Please enter',
  personageAffirm: 'Confirm Password',
  personagePrompt: 'Do you want to jump to the login page',
  // 顶部导航更多
  TopMore: 'MORE',
  // 顶部导航全部
  TopAll: 'ALL',
  // 没有找到相关内容
  NoFound: 'No relevant content found',
  // 大事件组件
  MajorEventsTitle: 'Major events',
  MajorEventsChange: 'Change a batch',
  // 待办任务
  ToDoTasks: 'To-do tasks',
  // 最近使用
  RecentlyUsed: 'Recently used',
  // 快捷应用
  QuickApplication: 'Quick application',
  QuickApplicationTitle: 'Add Quick App',
  SearchAppName: 'Search app name',
  DragAndDropToSort: 'Drag and drop to sort',
  functions: 'functions',
  noFunction: 'No function currently available',
  // 通知提醒
  Notification: 'Notifier',
  // 全部已读
  AllRead: 'All read',
  allChoose: 'All choose',
  noChoose: 'No choose',
  noRead: 'No read',
  read: 'Read',
  mark: 'Mark',
  markUnread: 'Mark unread',
  markRead: 'Mark read',
  // 帮助文档
  bills: 'Bills',
  message: 'Message',
  searchKeywords: 'Search keywords',
  HelpDocuments: 'Help Documents',
  HelpNovices: 'Help for novices',
  HelpPractices: 'Best Practices',

  // 待办任务表格标题
  TableNo: 'Number',
  TableState: 'Status',
  TableTaskNo: 'Task No',
  TableTaskName: 'Task name',
  TableLastTime: 'Latest completion time',
  TablePerson: 'Sponsor',
  TableOperate: 'Operate',

  mechanismSwitching: 'mechanismSwitching',

  messageCenter: 'messageCenter',
  workNotice: 'Work notice',

  // 安全管理
  modalWarning: 'Warn',
  changePassword: 'Change password',
  modalCancel: 'Cancel',
  safetyManagement: 'Safety management',
  passwordSetting: 'Password setting',
  passwordExpired: 'Password expired',
  lockedAndInvalid: 'The user was locked and invalid',
  forceChange: 'After the administrator resets the user password, the user is forced to change the password',
  cannotSame: 'The old and new passwords cannot be the same',
  specialCharacter: 'The password must contain special characters',
  inclusionDigit: 'The password must contain numbers',
  includeCapitalLetters: 'The password must contain uppercase letters',
  includeLowercaseLetters: 'The password must contain lowercase letters',
  mixedCase: 'The password must contain mixed case',
  includeUserName: 'The password cannot contain the user name',
  repeatingCharacter: 'The password cannot contain duplicate characters',
  minimumLength: 'Minimum password length is',
  maximumLength: 'Maximum password length is',
  expirationTime: 'Password expiration time',
  promptChange: 'Before the expiration date, users are prompted to change their passwords during the following days',
  expiredForcedChange: 'Force users to change their passwords after the password expires',
  userLock: 'Users who do not log in within a limited time are locked',
  userFailure: 'Users who have not logged in for a limited period of time are invalid',
  reachNumberLocking: 'The number of login failures is limited. The number of login failures is locked',
  lockTime: 'Login failure lock time',
  passwordHaveExpired: 'Your password has expired and needs to be changed before use',
  remainingDaysDue: `Your password will expire in {restDays} days, please change your password in time.`,
  containIllegalChar: 'Password cannot contain ',
  exceptionTips: 'Exception tips',
  lastLoginInfo: 'Last login info',
  time: 'Time',
  IP: 'IP address',
  loginAddress: 'Address',

  // 主题设置
  customThemeSettings: 'Customize theme Settings',
  themeNameRequire:'The theme name cannot be empty',

  query:'Query',
  resetting:'Resetting',
  processSubmission:'ProcessSubmission',
  processReturn:'ProcessReturn',
  processReset:'ProcessReset',
  processRetrieval:'ProcessRetrieval',
  myDocuments:'MyDocuments',
  schedule:'Schedule',
  handled:'Handled',
  completed:'Completed',
};
