/*
 * @Author: ZLL
 * @Date: 2023-02-06 13:37:13
 * @LastEditors: yxp
 * @LastEditTime: 2023-07-19 11:03:39
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\components\FunctionNavigation\index.tsx
 * @Description: 功能导航-首页
 */
import React from 'react';
import styles from './index.module.less';
import IconFont from 'src/components/IconFont';
import { RecentDocumentsDataModel, recentDocumentsData } from './mockData';
import FunctionMenuNailing from '../FunctionMenuNailing';
import ExhibitionItem, { ItemType } from 'src/components/ExhibitionItem';
import { inject, observer } from 'mobx-react';
import { MenuItem } from 'src/types';
import { FormattedMessage } from 'react-intl';
const FunctionNavigation: React.FC<{
  [key: string]: any;
  allFunctionsBtnClick: () => void;
  toTop: boolean;
  setToTop: Function;
}> = inject('RecentUseStore')(
  observer((props) => {
    const {
      RecentUseStore: { recentUseList },
      allFunctionsBtnClick,
    } = props;
    const noContent = () => {
      return (
        <div className={styles.noContent}>
          <IconFont type="icon-wuneirong1" className={styles.notIcon} />
          <div className={styles.notFoundLabel}>
            <FormattedMessage id="notFound" />
          </div>
        </div>
      );
    };
    return (
      <div className={styles.functionalNavigationBox}>
        <div className={styles.leftMenu}>
          <FunctionMenuNailing {...props} />
        </div>
        <div className={styles.contextBox}>
          <div className={styles.recentVisits}>
            <div className={styles.recentVisitsTitle}>
              <IconFont type="icon-zuijinshiyong2" className={styles.recentVisitsIcon} />
              <span>
                <FormattedMessage id="recentVisit" />
              </span>
            </div>
            {recentUseList?.length > 0 ? (
              <div className={styles.recentVisitsContent}>
                {recentUseList?.map((item: MenuItem) => {
                  return <ExhibitionItem type={ItemType.recentVisits} item={item} key={item.id} />;
                })}
              </div>
            ) : (
              noContent()
            )}
          </div>
          <div className={styles.recentVisits}>
            <div className={styles.recentVisitsTitle}>
              <IconFont type="icon-zuijindanju1" className={styles.recentVisitsIcon} />
              <span>
                <FormattedMessage id="recentDocument" />
              </span>
            </div>
            <div className={styles.recentDocumentsContent}>
              {/* {recentDocumentsData?.map((item: RecentDocumentsDataModel) => {
                return <ExhibitionItem type={ItemType.bill} item={item} key={item.key} />;
              })} */}
              <div className={styles.documentsItem}>
                <span>
                  <FormattedMessage id="stayTuned" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }),
);
export default FunctionNavigation;
