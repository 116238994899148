/*
 * @Author: LMY
 * @Date: 2023-09-12 15:14:50
 * @LastEditors: lmy
 * @LastEditTime: 2023-09-13 17:26:50
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\BrowserTip\index.tsx
 * @Description: 浏览器版本过低提示
 */

import React, { useState } from 'react';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import styles from './index.module.less';
import { APP_SETTINGS } from '../../constant';
import { idbSetItem } from 'src/utils/storage';

export interface IBrowserProps {
  browserId: string;
  browserName: string;
  version: string;
  icon: any;
}

const BrowserTip: React.FC<{}> = (props) => {
  const [showBrowserTip, setShowBrowserTip] = useState<boolean>(true);

  // 勾选不再提醒
  const changeWarning = (e: CheckboxChangeEvent) => {
    // localStorage.setItem('showBrowserTip', e.target.checked ? '0' : '1');
    idbSetItem('showBrowserTip',e.target.checked ? '0' : '1')
  };

  // 点击x关闭
  const closeBrowserTip = () => {
    setShowBrowserTip(false);
  };

  return (
    <div className={showBrowserTip ? styles.browserTipContainer : styles.noBrowserTipContainer}>
      <div className={styles.text}>为了获得最佳体验请使用推荐浏览器！</div>
      <div className={styles.browsers}>
        {APP_SETTINGS.BROWSER_LIST?.map((item: IBrowserProps) => {
          return (
            <div className={styles.item} key={item.browserId}>
              <img src={item.icon} className={styles.icon} />
              <div className={styles.versionText}>{`${item.browserId} > ${item.version}`}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.warn}>
        <Checkbox onChange={changeWarning}>不再提醒</Checkbox>
      </div>
      <div className={styles.close} onClick={closeBrowserTip}>
        ×
      </div>
    </div>
  );
};

export default BrowserTip;
