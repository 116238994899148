/*
 * @Author: ZLL
 * @Date: 2023-02-06 13:37:13
 * @LastEditors: lmy
 * @LastEditTime: 2023-08-15 17:10:11
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\components\FunctionSearchPage\index.tsx
 * @Description: 功能导航-首页
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './index.module.less';
import { RecentDocumentsDataModel, recentDocumentsData } from './mockData';
import FunctionMenu from '../FunctionMenu';
import type { MenuProps } from 'antd/es/menu';
import IconFont from 'src/components/IconFont';
import { inject, observer } from 'mobx-react';
import ExhibitionItem, { ItemType } from 'src/components/ExhibitionItem';
import { useNavigate } from 'react-router-dom';
import { Empty, Spin } from 'antd';
import { MenuItem as IMenuItem } from 'src/types';
import empty_img from 'src/asset/dashboard/dashboard_empty.png';
import { getSearchMenu } from 'src/api';
// 国际化
import { FormattedMessage, useIntl } from 'react-intl';

const FunctionSearchPage: React.FC<{ keyWords?: string; [key: string]: any }> = inject(
  'AppState',
  'TagsViewStore',
  'DrawerStore',
)(
  observer((props) => {
    const {
      keyWords,
      AppState: { leftMenuList },
    } = props;
    let navigate = useNavigate();

    const [menuItems, setMenuItems] = useState<MenuItem[]>();
    type MenuItem = Required<MenuProps>['items'][number];
    // 国际化
    const intl = useIntl();

    useEffect(() => {
      getMenuItemsData();
    }, [keyWords]);

    const getMenuLeafFunction = async (keyword: string) => {
      let result = await getSearchMenu({ keyword: keyword, highlightFontColor: 'var(--ant-primary-color)' });
      return result;
    };
    const menuLeafAllFunction = useRef<IMenuItem[]>();

    const [spinning, setSpinning] = useState(false);

    /**
     * @description: 设置左侧菜单数据、默认选中的菜单
     * @return {*}
     */
    const getMenuItemsData = async () => {
      setSpinning(true);
      const menuItems: MenuItem[] = [];
      if (keyWords) {
        menuLeafAllFunction.current = await getMenuLeafFunction(keyWords);
        menuLeafAllFunction.current?.forEach((item: IMenuItem) => {
          menuItems.push({
            label: <ExhibitionItem type={ItemType.searchFuc} item={item} keyWords={keyWords} key={item.id} />,
            key: item.id,
            // icon: <IconFont type={item.icon ?? ' '} className={styles.menuIcon} />,
          });
        });
      }
      setMenuItems(menuItems);
      setSpinning(false);
    };
    /**
     * @description: 左侧菜单点击事件
     * @param {*} e
     * @return {*}
     */
    const onMenuClick: MenuProps['onClick'] = (e) => {
      const item = menuLeafAllFunction.current?.find((i: IMenuItem) => i.id === e.key);
      if (item) {
        navigate(item.path);
      }
    };

    return (
      <div className={styles.functionSearchPageBox}>
        <div className={styles.leftMenu}>
          <div className={styles.titleBox}>
            <IconFont type="icon-gongneng1" className={styles.functionIcon} />
            <span>{intl.formatMessage({ id: 'functions' })}</span>
          </div>
          <Spin spinning={spinning}>
            <div className={styles.menuOuterBox}>
              {menuItems?.length === 0 ? (
                <Empty description={intl.formatMessage({ id: 'noFunction' })} image={empty_img} />
              ) : (
                <FunctionMenu
                  items={menuItems}
                  style={{ height: '100%' }}
                  mode="inline"
                  onClick={(menuItems) => onMenuClick(menuItems)}
                />
              )}
            </div>
          </Spin>
        </div>
        <div className={styles.contextBox}>
          <div className={styles.documents}>
            <div className={styles.documentsTitle}>
              <IconFont type="icon-zuijindanju1" className={styles.documentsIcon} />
              <span>单据</span>
            </div>
            <div className={styles.documentsContent}>
              {/* {recentDocumentsData?.map((item: RecentDocumentsDataModel) => {
                return <ExhibitionItem type={ItemType.bill} item={item} key={item.key} keyWords={keyWords} />;
              })} */}
              <div className={styles.documentsItem}>
                <span>
                  <FormattedMessage id="stayTuned" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }),
);
export default FunctionSearchPage;
