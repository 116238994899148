import zhCN from "@/locale/zhCN";
import zhHK from "@/locale/zhHK";
import zhTW from "@/locale/zhTW";
import enUS from "@/locale/enUS";

export default function setGlobalWindow() {
  window.__WUJIE_REFERER__ = 'PAN_SOFT_ERP';
}
/**
 * 判断是绝对路径还是相对路径
 * @param url
 */
export const isAbsoluteURL = (url: string) => {
  if (!url) return false;
  // 添加地址前缀为blob的情况(pdf文件：file.createObjectURL()可获得类似地址),base64格式是以data:开头
  return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('blob:') || url.startsWith('data:');
}
/**
 * 判断是否是同源
 * @param url1
 * @param url2
 */
export const isSameOriginFun = (url1:string,url2:string) => {
  if(!isAbsoluteURL(url1) || !isAbsoluteURL(url2)){
    return false
  }
  const parsedUrl1 = new URL(url1);
  const parsedUrl2 = new URL(url2);

  return (
      parsedUrl1.protocol === parsedUrl2.protocol &&
      parsedUrl1.host === parsedUrl2.host
  );

};


