/*
 * @Author: jld
 * @Date: 2023-02-27 15:18:24
 * @LastEditTime: 2023-12-15 16:24:52
 * @LastEditors: lmy
 * @Description:
 * @FilePath: \portal-react-start\src\api\customDashboard.ts
 */
import request from '../utils/request';
import { IDashboard, IDeleteDashboardParams, ISaveUpdateDashboardParams, IUpdateQuickAppItem } from './types/dashboard';

// 获取看板详情
export const getDashboard = () => {
  return request<IDashboard[]>({
    url: '/portal/board/list/dashboard',
    method: 'post',
  });
};
// 删除看板
export const deleteDashboard = (data: IDeleteDashboardParams) => {
  return request({
    url: '/portal/board/delete',
    method: 'post',
    data,
  });
};
// 保存自定义看板
export const saveDashboard = (data: ISaveUpdateDashboardParams) => {
  return request({
    url: '/portal/board/save',
    method: 'post',
    data,
  });
};
// 编辑看板
export const updateDashboard = (data: ISaveUpdateDashboardParams) => {
  return request({
    url: '/portal/board/update',
    method: 'post',
    data,
  });
};

// 获取组件列表
export const getGroupList = () => {
  return request({
    url: '/portal/portlet/list/group',
    method: 'post',
  });
};
// 获取预置模板
export const getTemplateList = () => {
  return request({
    url: '/portal/board/list/template',
    method: 'post',
  });
};
// 模板排序
export const sortDashboard = (data: { dashboardIds: string[] }) => {
  return request({
    url: '/portal/board/sort',
    method: 'post',
    data,
  });
};

// 快捷应用获取
export const getQuickApplication = () => {
  return request<string[]>({
    url: '/portal/menu/quick/list',
    method: 'post',
  });
};

// 快捷应用更新
export const updateQuickApplication = (updateList: {updateList: IUpdateQuickAppItem[]}) => {
  return request({
    url: '/portal/menu/quick/update',
    method: 'post',
    data: updateList,
  });
};