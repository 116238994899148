/*
 * @Author: atwLee
 * @Date: 2023-04-21 10:16:31
 * @LastEditors: 王端慧 wangduanhui@pansoft.com
 * @LastEditTime: 2023-08-15 15:57:00
 * @Description:
 * @FilePath: /erp-finance-biz-portal-react-start/src/api/taskTable.ts
 */
import request from '../utils/request';
import { ITaskTable, PrecessResultType } from './types/taskTable';
import { TodoListKey } from 'src/components/gridItemGroup/TodoTasks';
import { Dayjs } from 'dayjs';

// 获取tasktable数据（ /erp-finance-biz-portal-react-start/src/components/gridItemGroup/TodoTasks/index.tsx）
export const getTaskTableList = (data: {
  taskType: TodoListKey;
  pageNo?: number;
  pageSize?: number;
  bizNo?: string;
  startTime: Dayjs | null;
  endTime: Dayjs | null;
  loadAllMyBill?: string;
  loadStartTask?:string
}) => {
  return request<ITaskTable>({
    url: '/erp-flow/rest/workFlow/v1/loadFlowTask',
    method: 'post',
    data: {
      bizParam: {
        isPaging: 1,
        ...data,
        filterLastTask: '1',
        startTime: data.startTime?.format('YYYY-MM-DD HH:mm:ss'),
        /**
         * 为什么要endOf。因为默认时间（src/components/gridItemGroup/TodoTasks/components/Operate/index.tsx，defaultRangeDate）
         * 把打开浏览器的时分秒存在了dayjs对象里。所以endTime的时分秒固定了，导致了重置查找啥的找不到。
         * 举个例子：
         * 我11点01分打开了浏览器，11点03分做了个单子。那么在待办里是找不到的，因为结束时间的参数一直还是11点01分，因此加个endOf（其实刷新浏览器即可解决，但是使用不友好）
         * 但是会有这么一种情况，23点58分打开了浏览器，凌晨1点1分做了个单子，因此还是找不到，不过这种情况发生近乎为0，并且刷新浏览器即可解决，就没有处理该情况。
         */
        endTime: data.endTime?.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
      },
    },
  });
};
//  我的单据当天数据和当年数据
export const getMyDocumentsNum = (data: {
  taskType: TodoListKey;
  pageNo?: number;
  pageSize?: number;
  startTime: string;
  endTime: string;
  loadAllMyBill?: string;
}) => {
  return request<ITaskTable>({
    url: '/erp-flow/rest/workFlow/v1/loadFlowTask',
    method: 'post',
    data: {
      bizParam: {
        isPaging: 1,
        ...data,
        filterLastTask: '1',
        startTime: data.startTime,

        endTime: data.endTime,
      },
    },
  });
};
// 流程操作
interface DataParams {
  taskId: string;
  bizId: string;
  bizNo: string;
  mainFlowId: string;
  mainNodeId: string;
  subFlowId?: string;
  subNodeId?: string;
  opinion?: string;
  sysId: string;
  bizOrgId: string;
  bizDate?: string;
  modelId?: string;
  taskType: string;
}
// 提交
export const taskSubmit = (
  data: DataParams & {
    // 这三个是接口文档上的参数，但是门户上不知道咋用，列表返回的数据上也没有
    appointNodeApprover?: string;
    submitToFlowId?: string;
    submitToNodeId?: string;
  },
) => {
  return request<PrecessResultType>({
    url: '/erp-flow/rest/workFlow/v1/submitTask',
    method: 'post',
    data: {
      bizParam: [
        {
          ...data,
        },
      ],
    },
  });
};
// 取回
export const taskRetake = (data: Omit<DataParams, 'taskType'>) => {
  return request<PrecessResultType>({
    url: '/erp-flow/rest/workFlow/v1/retakeTask',
    method: 'post',
    data: {
      bizParam: [
        {
          ...data,
        },
      ],
    },
  });
};
// 退回
export const taskRollback = (
  data: Omit<DataParams, 'taskType'> & {
    // 这三个是接口文档上的参数，但是门户上不知道咋用，列表返回的数据上也没有
    rollbackToFlowId?: string;
    rollbackToNodeId?: string;
    rollbackToNodeType?: string;
  },
) => {
  return request<PrecessResultType>({
    url: '/erp-flow/rest/workFlow/v1/rollbackTask',
    method: 'post',
    data: {
      bizParam: [
        {
          ...data,
        },
      ],
    },
  });
};
// 重置
export const taskReset = (
  data: Omit<DataParams, 'taskType'> & {
    bizUserId: string;
    bizUserName: string;
  },
) => {
  return request<PrecessResultType>({
    url: '/erp-flow/rest/workFlow/v1/rollbackTask',
    method: 'post',
    data: {
      bizParam: [
        {
          ...data,
        },
      ],
    },
  });
};
