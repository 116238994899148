/*
 * @Author: jld
 * @Date: 2023-08-16 16:12:45
 * @LastEditTime: 2023-08-16 16:12:51
 * @LastEditors: jld
 * @Description: 不是无界得eventsBus
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\events.ts
 */
import { EventEmitter } from 'events';
export default new EventEmitter();
