/*
 * @Author: jld
 * @Date: 2023-02-22 17:20:25
 * @LastEditTime: 2023-02-22 18:33:12
 * @LastEditors: jld
 * @Description: 倒计时组件
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\Countdown\index.tsx
 */
import React, { useEffect, useState } from 'react';

import { useCountDown } from 'ahooks';

const Countdown: React.FC<{
  startSecond: number; // 从多少秒开始倒计时 1s = 1000
  isStart: boolean; // 是否开始倒计时
  countDownFinishedText?: string; // 倒计时结束显示文字
  countdownEnd?: () => void;
}> = (props) => {
  const { startSecond, isStart, countDownFinishedText, countdownEnd } = props;
  const [targetDate, setTargetDate] = useState<number>();
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
      if (countdownEnd) countdownEnd();
    },
  });
  useEffect(() => {
    if (isStart) {
      setTargetDate(Date.now() + startSecond);
    }
  }, [isStart]);

  return <div>{countdown === 0 ? countDownFinishedText : `${Math.round(countdown / 1000)}`}</div>;
};
export default Countdown;
