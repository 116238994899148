/*
 * @Author: ww
 * @Date: 2024-02-27 14:44:02
 * @LastEditTime: 2024-02-27 09:57:43
 * @LastEditors: ww
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\theme.ts
 */
import request from '../utils/request';

// 查询可用得主题列表
export const getAllThemeSettingInfo = () => {
  return request<any[]>({
    url: '/portal/theme/manage/list ',
    method: 'post',
  });
};

// 查询可用得主题列表
export const getUsableThemeSettingInfo = () => {
  return request<any[]>({
    url: '/portal/theme/list',
    method: 'post',
  });
};

// 删除自定义主题
export const deleteCustomTheme = (themeId: string) => {
  return request<any[]>({
    url: `/portal/theme/delete?themeId=${themeId}`,
    method: 'post',
  });
};

// 查询主题详情
export const searchThemeById = (themeId: string) => {
  return request<any[]>({
    url: '/portal/theme/getById',
    method: 'post',
    data: themeId,
  });
};

// 查询主题详情
export const updateTheme = (data: any) => {
  return request<any[]>({
    url: '/portal/theme/update',
    method: 'post',
    data: data,
  });
};

// 保存自定义主题
export const saveCustomTheme = (data: any) => {
  return request<any[]>({
    url: '/portal/theme/save',
    method: 'post',
    data: data,
  });
};

// 主题生效失效
export const ableUpdateCustomTheme = (data: any) => {
  return request<any[]>({
    url: '/portal/theme/ableUpdate',
    method: 'post',
    data: data,
  });
};
