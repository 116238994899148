/*
 * @Author: ZLL
 * @Date: 2023-04-04 14:53:09
 * @LastEditors: ZLL
 * @LastEditTime: 2023-04-04 15:36:29
 * @FilePath: \erp-finance-biz-portal-react-start\src\pages\LoginError\index.tsx
 * @Description: 文件描述
 */
import styles from './index.module.less';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'antd';
import { parse } from 'qs';

const LoginError: React.FC<any> = inject()(
  observer((props) => {
    return (
      <div className={styles.errorBox}>
        <div className={styles.errorContent}>
          <div className={styles.errorLeftImg} />
          <div className={styles.errorRightText}>
            <div className={styles.titleText}>登录异常</div>
            <div className={styles.detailText}>您所访问的地址出现异常，请联系管理员。</div>
            <Button
              className={styles.returnButton}
              onClick={() => {
                const url = location.href;
                if (url.indexOf('?') > -1) {
                  const query = url.split('?')[1];
                  const decodeQuery = decodeURIComponent(query);
                  const decodeQueryObj = parse(decodeQuery);
                  if (decodeQueryObj?.url) {
                    location.href = decodeQueryObj.url as string;
                  }
                }
              }}
            >
              返回首页
            </Button>
          </div>
        </div>
      </div>
    );
  }),
);

export default LoginError;
