/*
 * @Author: ZLL
 * @Date: 2023-02-25 15:44:36
 * @LastEditors: ZLL
 * @LastEditTime: 2023-03-31 15:31:24
 * @FilePath: \erp-finance-biz-portal-react-start\src\layout\components\RequireAuth\index.tsx
 * @Description: 文件描述
 */
import { isEmpty } from 'lodash-es';
import React, { ReactNode } from 'react';

const IndexPage: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <div style={{ height: '100%' }}>{children}</div>;
};
export default IndexPage;
