/*
 * @Author: ZLL
 * @Date: 2023-02-06 13:37:13
 * @LastEditors: lmy
 * @LastEditTime: 2023-11-22 15:10:51
 * @FilePath: \erp-finance-biz-portal-react-start\src\components\FunctionDrawer\index.tsx
 * @Description: 所有功能-分级
 */
import React, { useEffect, useRef, useState } from 'react';
import { Drawer } from 'antd';
import styles from './index.module.less';
import FunctionSearch from './components/FunctionSearch';
import FunctionNavigation from './components/FunctionNavigation';
import FunctionSwitchingDrawer from './components/FunctionSwitchingDrawer';
import { inject, observer } from 'mobx-react';
import FunctionSearchPage from './components/FunctionSearchPage';
import IconFont from 'src/components/IconFont';
import debounce from 'lodash/debounce';
import { InputRef } from 'antd';
import { FormattedMessage } from 'react-intl';

interface ToggleButtonModel {
  type: string;
  active: string;
  key: string;
}
const toggleButton: ToggleButtonModel[] = [
  {
    type: 'icon-fenjimoshi',
    active: 'icon-fenji-xuanzhong',
    key: 'classification',
  },
  {
    type: 'icon-pingpumoshi',
    active: 'icon-pingpu-xuanzhong',
    key: 'tile',
  },
];

const FunctionDrawer: React.FC<{
  [key: string]: any;
}> = inject('DrawerStore')(
  observer((props) => {
    const {
      DrawerStore: {
        drawerOpen,
        showFunDrawer,
        setDrawerOpen,
        showFunDrawerFlag,
        setDrawerOpenStatus,
        setShowFunDrawer,
        setShowFunDrawerFlag,
        collectMenuFixed,
        setCollectMenuFixed,
      },
    } = props;
    const [keyWords, setKeyWords] = useState<string>(); // 搜索关键词
    const searchRef = useRef<InputRef>();
    const [toggleActive, setToggleActive] = useState(toggleButton[0].key); // 平铺、分级按钮选中项key

    useEffect(() => {
      if (drawerOpen || showFunDrawer) {
        // 抽屉动画结束后再设置聚焦
        setTimeout(() => {
          searchRef.current?.focus();
        }, 400);
      }
      return () => {
        setKeyWords(undefined);
      };
    }, [drawerOpen, showFunDrawer]);

    const searchOnChange = debounce((e: any) => {
      // if ( !e.target.value) {
      //   setShowFunDrawer(true);
      // } else {
      //   setShowFunDrawer(false);
      // }
      setKeyWords(e.target.value);
    }, 500);

    return (
      <Drawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        afterOpenChange={(open: boolean) => {
          if (open) {
            setDrawerOpenStatus('open');
          } else {
            setDrawerOpenStatus('close');
          }
        }}
        placement="left"
        width={1241}
        push={false}
        rootClassName={styles.abilityDrawerBox}
        getContainer={false}
        autoFocus={true}
        destroyOnClose={true}
      >
        <div className={styles.headerBox}>
          {(showFunDrawer && !keyWords) ? (
            <div
              className={styles.goBack}
              onClick={() => {
                setShowFunDrawer(false);
                setShowFunDrawerFlag(false);
              }}
            >
              <IconFont type="icon-fanhui" className={styles.goBackIcon} />
              <span className={styles.goBackText}>
                <FormattedMessage id="return" />
              </span>
            </div>
          ) : (
            ''
          )}
          <FunctionSearch
            inputRef={searchRef}
            stylesProps={{
              width: showFunDrawer ? 'calc(100% - 132px)' : '100%',
              zIndex: 1,
              padding: showFunDrawer ? '0 30px' : 0,
            }}
            onChange={(e) => {
              // setShowFunDrawer(false);
              searchOnChange(e);
            }}
          />
          {(showFunDrawer && !keyWords) ? (
            <div className={styles.toggleButton}>
              {toggleButton.map((item: ToggleButtonModel) => {
                return (
                  <IconFont
                    onClick={() => {
                      setToggleActive(item.key);
                    }}
                    type={toggleActive === item.key ? item.active : item.type}
                    className={`${styles.toggleButtonIcon} ${
                      toggleActive === item.key ? styles.activeToggleButtonIcon : ''
                    }`}
                    key={item.key}
                  />
                );
              })}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={styles.totalContentBox}>
          {
            !keyWords ? <FunctionSwitchingDrawer toggleActive={toggleActive} /> : ''
          }
          {keyWords && (
            <div className={styles.searchContent}>
              <FunctionSearchPage keyWords={keyWords} />
            </div>
          )}
          {
            (!showFunDrawer && !keyWords) ? <FunctionNavigation
            allFunctionsBtnClick={() => {
              setShowFunDrawer(true);
              setShowFunDrawerFlag(true);
            }}
            toTop={collectMenuFixed}
            setToTop={setCollectMenuFixed}
          /> : ''
          }
        </div>
      </Drawer>
    );
  }),
);
export default FunctionDrawer;
