import { menuItem } from './../components/routerContainer/modal/WindowModal/mockData';
/*
 * @Author: ZY
 * @Date: 2023-03-13 20:53:55
 * @LastEditors: lmy
 * @LastEditTime: 2023-12-01 16:49:03
 * @FilePath: \erp-finance-biz-portal-react-start\src\utils\menu.ts
 * @Description: 菜单配置类
 */
import { isEmpty } from 'lodash-es';
import { OPEN_TYPE } from '../constant/menu';
import { MenuItem } from '../types';
import {isAbsoluteURL} from 'src/utils/window'
import WindowModal from '../components/routerContainer/modal/WindowModal';
import FullScreenModal from '../components/routerContainer/modal/FullScreenModal';
import stores from 'src/stores';
// import { testMock } from 'src/mock/menu';
import { getFavoritesList, getMenuList } from 'src/api';
let { TagsViewStore, AppState } = stores;

/**
 * @description: 打开菜单功能
 * @param {MenuItem} menuItem  标准内置菜单格式
 * @return {*}
 */
export const openMenuItem = (menuItem: MenuItem) => {
  const { protocol, host, search } = window.location;
  let openType = menuItem?.config?.openType;
  if (openType && openType.toLowerCase() === OPEN_TYPE.OPEN_WINDOW.toLowerCase()) {
    WindowModal.open(menuItem, {});
    return;
  } else if (openType && openType.toLowerCase() === OPEN_TYPE.OPEN_FULL_SCREEN.toLowerCase()) {
    FullScreenModal.open(menuItem, {});
    return;
  } else if (openType && openType.toLowerCase() === OPEN_TYPE.OPEN_BROWSER_TAB.toLowerCase()) {
    // 没有打开标识的时候再去打开浏览器标签
    if(search.indexOf('openType') === -1) {
      let webUrl = '';
      if(isAbsoluteURL(menuItem?.config?.url)){
        webUrl = menuItem?.config?.url
      }else{
        webUrl = `${protocol}//${host}/portal/${menuItem?.config?.loadType}/${menuItem?.id}?openType=${openType}`
      }
      if (webUrl) {
        window.open(webUrl, '_blank');
      } else {
        console.error('没有配置跳转的url地址‘webURL’');
      }
    }else{
      TagsViewStore.addTag(menuItem);
    }
    return;
  } else {
    // 加载菜单时，会在后台组织好对应的路由信息
    TagsViewStore.addTag(menuItem);
  }
};

// 根据某个属性值从MenuList查找拥有该属性值的menuItem
export function getMenuItemInMenuListByProperty(menuList: MenuItem[], key: string, value: string): MenuItem | null {
  let stack: Array<MenuItem> = [];
  stack = stack.concat(...menuList);
  let res: MenuItem | null = null;
  while (stack.length) {
    let cur: any = stack.shift();
    if (!cur) {
      return res;
    }
    if (cur.children && cur.children.length > 0) {
      stack = cur.children.concat(stack);
    }
    cur = { ...cur, ...cur.config };
    if (value === cur[key]) {
      res = cur;
    }
  }
  return res;
}

/**
 * @description: 加载全部菜单数据以及收藏菜单数据 （现在菜单的收藏是单独返回，没有在树上）
 */
// 过滤isDisplay为显示的
const getFilterTree: any = (tree?: MenuItem[]) => {
  return tree
    ?.map((node: MenuItem) => {
      const { children, ...rest } = node;
      if (node.isDisplay === true) {
        return {
          ...rest,
          children: getFilterTree(children),
        };
      } /*  else if (children) {
        const filteredChildren: MenuItem[] = getFilterTree(children);
        if (filteredChildren.length > 0) {
          return {
            ...rest,
            children: filteredChildren,
          };
        }
      } */

      return null;
    })
    .filter(Boolean);
};
export const getMenuListAndCollectionList = async () => {
  // 获取菜单
  let menuList = await getMenuList();
  // 菜单过滤
  let menuListFilter = await getFilterTree(menuList);
  let collectMenuList: MenuItem[] = [];
  // 收藏菜单id
  const collectIdList = await getFavoritesList();
  if (!isEmpty(collectIdList) || collectIdList?.length === 0) {
    collectIdList.forEach((collectId: string) => {
      getCollectionMenuList(menuListFilter, collectId, collectMenuList);
    });
    AppState.setCollectionMenuList(collectMenuList);
  }
  if (!isEmpty(menuListFilter)) {
    // menuList = menuList.concat(testMock);
    AppState.setLeftMenuList(menuListFilter);
  }
};

const getCollectionMenuList = (list: MenuItem[], collectId: string, collectMenuList: MenuItem[]) => {
  list.forEach((item: MenuItem) => {
    if (item.children && item.children.length > 0) {
      getCollectionMenuList(item.children, collectId, collectMenuList);
    } else {
      if (item.id === collectId) {
        item.isCollect = true;
        collectMenuList.push(item);
      }
    }
  });
};
interface IReturnMenuInfoType {
  item: MenuItem;
  upperLevel: MenuItem;
}
/**
 *
 * @param list 全部得菜单
 * @param currentPath 当前path
 * @returns item：当前选中菜单，upperLevel：上一级菜单，parentItem：最高级父元素
 */
export const getCurrentMenuList = (list: MenuItem[], currentPath: string) => {
  let returnParams: IReturnMenuInfoType[] = [];
  let currentMenu;
  for (let item of list) {
    if (item.children && item.children.length > 0) {
      getCurrentMenuListTemp({ menu: item, menuItem: item.children }, currentPath, returnParams);
      if (returnParams.length > 0) {
        return { item: returnParams[0].item, upperLevel: returnParams[0].upperLevel, parentItem: item };
      }
    } else {
      if (item.id === currentPath) {
        return { item: item, upperLevel: item, parentItem: item };
      }
    }
  }
  return currentMenu;
};
interface IMenuInfoType {
  menu: MenuItem;
  menuItem: MenuItem[];
}
const getCurrentMenuListTemp = (
  menuInfo: IMenuInfoType,
  menuItemPath: string,
  returnMenuItem: IReturnMenuInfoType[],
) => {
  for (let item of menuInfo.menuItem) {
    if (item.children && item.children.length > 0) {
      getCurrentMenuListTemp({ menu: item, menuItem: item.children }, menuItemPath, returnMenuItem);
    } else if (item.path === menuItemPath) {
      returnMenuItem.push({
        item: item,
        upperLevel: menuInfo.menu,
      });
    }
  }
};
