/*
 * @Author: jld
 * @Date: 2023-02-21 16:31:31
 * @LastEditTime: 2023-08-10 17:37:14
 * @LastEditors: jld
 * @Description:
 * @FilePath: \erp-finance-biz-portal-react-start\src\stores\components\UserStore.tsx
 */
import { Cookie_KEY, STORAGE_KEY } from 'src/constant/storageKey';
import { action, observable } from 'mobx';
import { getCookies, getItem } from 'src/utils/storage';
import { IOrganizations } from '@/api/types/settings';

class UserStore {
  @observable public isLogin = false;
  @observable public token = getCookies(Cookie_KEY.ERP_TOKEN);
  @observable public userCaption = getCookies(Cookie_KEY.USER_CAPTION);
  @observable public userName = getCookies(Cookie_KEY.USER_NAME);
  @observable public orgInfo: IOrganizations = JSON.parse(getItem(STORAGE_KEY.ORG_INFO) ?? '{}');

  // 登录状态
  @action.bound
  public setIsLogin(state: boolean) {
    this.isLogin = state;
  }

  // 用户名
  @action.bound
  public setUserCaption(state: string) {
    this.userCaption = state;
  }

  // 用户id
  @action.bound
  public setUserName(state: string) {
    this.userName = state;
  }

  // 用户所属组织机构
  @action.bound
  public setOrgInfo(state: IOrganizations) {
    this.orgInfo = state;
  }

  // token
  @action.bound
  public setToken(state: string) {
    this.token = state;
  }
}

const userStore = new UserStore();
export default userStore;
