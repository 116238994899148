/*
 * @Author: jld
 * @Date: 2023-02-15 17:58:47
 * @LastEditTime: 2023-07-07 12:34:43
 * @LastEditors: LHY
 * @Description:获取菜单接口
 * @FilePath: \erp-finance-biz-portal-react-start\src\api\menu.ts
 */
import { MenuItem } from 'src/types';
import request from '../utils/request';

// 获取菜单接口
export const getMenuList = () => {
  return request<MenuItem[]>({
    url: '/portal/menu/v1/list',
    method: 'post',
  });
};
// 获取收藏菜单
export const getFavoritesList = () => {
  return request<string[]>({
    url: '/portal/menu/favorites/list',
    method: 'post',
  });
};

// 添加收藏菜单
export const getFavoritesAdd = (data: { menuId: string }) => {
  return request({
    url: '/portal/menu/favorites/add',
    method: 'post',
    data,
  });
};
// 删除收藏菜单
export const getFavoritesDelete = (data: { menuId: string }) => {
  return request({
    url: '/portal/menu/favorites/delete',
    method: 'post',
    data,
  });
};
// 搜索
export const getSearchMenu = (data: { keyword: string; highlightFontColor: string }) => {
  return request<MenuItem[]>({
    url: '/portal/menu/searchUserMenuFunctions',
    method: 'post',
    data,
  });
};
